import { Form, FormInstance, Input, InputProps } from "antd";
import React, { memo, FC, FocusEvent } from "react";
import classNames from "classnames";
import { ReactComponent as EyeInvisible } from "../../Icons/eye-invisible.svg";
import { ReactComponent as EyeVisible } from "../../Icons/eye-visible.svg";

interface Props extends InputProps {
  formgroup?: FormInstance<Object>;
  label?: string;
  rules?: object[];
  validatetrigger?: string[];
  extra?: any;
}

const AppPassword: FC<Props> = memo(({ ...props }) => {
  const {
    formgroup,
    name,
    onBlur,
    label,
    rules,
    className,
    validatetrigger,
    extra,
  } = props;

  const renderIcon = (visible: boolean) =>
    visible ? <EyeVisible /> : <EyeInvisible />;

  function onBlurInput(e: FocusEvent<HTMLInputElement>) {
    formgroup?.setFields([
      {
        name: name || "",
        value: e.target.value.trim(),
      },
    ]);
    formgroup?.validateFields([name || ""]);
    if (onBlur) {
      onBlur(e);
    }
  }

  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      validateTrigger={validatetrigger}
      extra={extra}
    >
      <Input.Password
        className={classNames("app-input-password", className)}
        {...props}
        onBlur={(e) => onBlurInput(e)}
        autoComplete="new-password"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck={false}
        iconRender={(visible) => renderIcon(visible)}
      />
    </Form.Item>
  );
});

export default AppPassword;

AppPassword.defaultProps = {
  rules: [],
  validatetrigger: ["onBlur"],
};
