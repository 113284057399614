import React, { forwardRef } from "react";

type TwitterProps = {
    text?: string;
    url?: string;
    hashtags?: string[];
    via?: string;
    related?: string[];
    in_reply_to?: string;
} & Omit<React.ComponentProps<"a">, "herf" | "target" | "rel">;

export const TwitterItem = forwardRef<HTMLAnchorElement, TwitterProps>(
    (
        { text, url, hashtags, via, related, in_reply_to, ...intrinsicProps },
        forwardedRef,
    ) => {
        const twitterUrl = new URL("https://twitter.com/intent/tweet");
        if (text !== undefined) twitterUrl.searchParams.set("text", text);
        if (url !== undefined) twitterUrl.searchParams.set("url", url);
        if (hashtags !== undefined) twitterUrl.searchParams.set("hashtags", hashtags.join(","));
        if (via !== undefined) twitterUrl.searchParams.set("via", via);
        if (related !== undefined) twitterUrl.searchParams.set("related", related.join(","));
        if (in_reply_to !== undefined) twitterUrl.searchParams.set("in_reply_to", in_reply_to);

        return (
            <a
              ref={forwardedRef}
              href={twitterUrl.toString()}
              target="_blank"
              rel="noopener noreferrer"
              {...intrinsicProps}
            ><img alt="" src="/assets/images/sns_twitter.png" className="sns-img" /></a>
          );
    },
);
export default TwitterItem;