/* eslint-disable jsx-a11y/media-has-caption */
import React, { FC } from "react";
import Slider from "react-slick";
import "./index.scss";
import { ReactComponent as Close } from "@components/Icons/close-2.svg";
import { ReactComponent as ArrowLeft } from "@components/Icons/arrow-left-2.svg";
import { ReactComponent as ArrowRight } from "@components/Icons/arrow-right-2.svg";
import { ReactComponent as Download } from "@components/Icons/download.svg";
import { handleDownloadFromUrl } from 'src/helper';
import { useSelector } from "react-redux";
import { RootState } from "src/store/configureStore";

const fileSaver = require("file-saver");

interface Props {
  initialSlide: number;
  medias: Array<{ id: string; url: string; type: string }>;
  onClose: (value: boolean) => void;
  visible: boolean;
}

const ChatShower: FC<Props> = ({ ...props }) => {
  const { initialSlide, medias, onClose, visible } = props;

  const sasCode = useSelector(
    (state: RootState) => state.GlobalReducer.sasCode
  );
  
  const NextArrow = (props: any) => {
    const { className, onClick } = props;
    return (
      // <div style={{ display: "none" }} className={className} onClick={onClick}>
      <div className={className} onClick={onClick}>
        <ArrowRight />
      </div>
    );
  };

  const PrevArrow = (props: any) => {
    const { className, onClick } = props;
    return (
      // <div style={{ display: "none" }} className={className} onClick={onClick}>
      <div className={className} onClick={onClick}>
        <ArrowLeft />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    swipe: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const handleDownload = (href: string) => {
    const name = href.split("?")[0].split("/").pop();

    if ((window as any)?.ReactNativeWebView) {
      try {
        const message = {
          type: "DOWNLOAD",
          data: { name: name, url: href },
        };
        (window as any).ReactNativeWebView.postMessage(JSON.stringify(message));
      } catch (e) {
        console.log(e);
      }
    } else {
      handleDownloadFromUrl(href);
    }
  };

  return visible ? (
    <div className="ui-chat-shower">
      <div className="ui-chat-shower__closer">
        <Close onClick={() => onClose(false)} />
      </div>
      <div>
        <Slider {...settings} className="ui-chat-shower__slider">
          {medias &&
            medias.length &&
            medias.map((media, index) => (
              <div className="ui-chat-shower__item" key={index}>
                {media.url.includes("images") ? (

                  <div className="ui-chat-shower__item-img">
                    <div onClick={() => handleDownload(media.url.concat(sasCode as string))}>
                      <Download />
                    </div>
                    <img src={media.url.concat(sasCode as string)} alt="media-show-item" />
                  </div>
                ) : (
                  <div className="ui-chat-shower__item-video">
                    <div onClick={() => handleDownload(media.url.concat(sasCode as string))}>
                      <Download />
                    </div>
                    <video
                      controls
                      disablePictureInPicture
                      controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
                      src={`${media.url.concat(sasCode as string)}#t=0.1`}
                      autoPlay
                    />
                  </div>
                )}
              </div>
            ))}
        </Slider>
      </div>
    </div>
  ) : null;
};

export default ChatShower;
