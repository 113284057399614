export interface ColorModel {
    key: string | ColorKeyValueEnum;
    light: string;
    dark: string;
}

export enum ColorKeyValueEnum {
    PRIMARY = '--root-primary-color',
    PRIMARY_2 = '--root-primary-color-2',
    PRIMARY_3 = '--root-primary-color-3',
    PRIMARY_4 = '--root-primary-color-4',
    PRIMARY_5 = '--root-primary-color-5',
    PRIMARY_6 = '--root-primary-color-6',

    SECONDARY = '--root-secondary-color',
    SECONDARY_2 = '--root-secondary-color-2',
    SECONDARY_3 = '--root-secondary-color-3',
    SECONDARY_4 = '--root-secondary-color-4',
    SECONDARY_5 = '--root-secondary-color-5',

    ERROR = '--root-error-color',
    SUCCESS = '--root-success-color',
    TITLE = '--root-title-color',
    BODY = '--root-body-color',
    BACKGROUND = '--root-background-color',
    LINE = '--root-line-color',
    LABEL = '--root-label-color',
    DISABLE = '--root-disable-color',
}

export enum ThemeMode {
    LIGHT = 'light',
    DARK = 'dark'
}


export const ColorPalette: ColorModel[] = [
    {
        key: ColorKeyValueEnum.PRIMARY,
        light: '#FF671E',
        dark: '#FF3747'
    },
    {
        key: ColorKeyValueEnum.PRIMARY_2,
        light: '#FF8A52',
        dark: '#FF3747'
    },
    {
        key: ColorKeyValueEnum.PRIMARY_3,
        light: '#FF8A52',
        dark: '#FF3747'
    },
    {
        key: ColorKeyValueEnum.PRIMARY_4,
        light: '#64B5F6',
        dark: '#FF3747'
    },

    {
        key: ColorKeyValueEnum.PRIMARY_5,
        light: '#BBDEFB',
        dark: '#FF3747'
    },

    {
        key: ColorKeyValueEnum.PRIMARY_6,
        light: '#E9F5FF',
        dark: '#FF3747'
    },


    {
        key: ColorKeyValueEnum.SECONDARY,
        light: '#FF671E',
        dark: '#FF3747'
    },
    {
        key: ColorKeyValueEnum.SECONDARY_2,
        light: '#F57C00',
        dark: '#FF3747'
    },

    {
        key: ColorKeyValueEnum.SECONDARY_3,
        light: '#F57C00',
        dark: '#FF3747'
    },
    {
        key: ColorKeyValueEnum.SECONDARY_4,
        light: '#FFB74D',
        dark: '#FF3747'
    },
    {
        key: ColorKeyValueEnum.SECONDARY_5,
        light: '#FFE0B2',
        dark: '#FF3747'
    },

    {
        key: ColorKeyValueEnum.ERROR,
        light: '#F04438',
        dark: '#FF3747'
    },

    {
        key: ColorKeyValueEnum.SUCCESS,
        light: '#12B76A',
        dark: '#FF3747'
    },
    {
        key: ColorKeyValueEnum.TITLE,
        light: '#333333',
        dark: '#FF3747'
    },

    {
        key: ColorKeyValueEnum.BODY,
        light: '#666666',
        dark: '#FF3747'
    },
    {
        key: ColorKeyValueEnum.BACKGROUND,
        light: '#FCFCFC',
        dark: '#FF3747'
    },

    {
        key: ColorKeyValueEnum.LINE,
        light: '#D9DBE9',
        dark: '#FF3747'
    },

    {
        key: ColorKeyValueEnum.LABEL,
        light: '#CACACA',
        dark: '#CACACA'
    },

    {
        key: ColorKeyValueEnum.DISABLE,
        light: '#EFF0F1',
        dark: '#FF3747'
    },



];


