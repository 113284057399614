import "./styles.scss";
import React, { FC, useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "antd";
import AppButton from "src/components/AppButton";
import AppRadio from "src/components/Form/AppRadio";
import AppCheckbox from "src/components/Form/AppCheckbox";
import moment from "moment";
import { isEmpty } from "lodash";
import AppInputNumber from "src/components/Form/AppInputNumber";
import { useTranslation } from "react-i18next";
import AppDatePicker from "src/components/AppDatePicker";
import CommonService from "src/services/Common";
import { CHARACTER_SPECIALS, MIN_PRICE_PRODUCT } from "src/configs";
import AppTextField from "../../../components/Form/AppTextField/index";

interface Props {
  onConfirm: any;
}

const AddConditionScreen: FC<Props> = ({ ...props }) => {
  const { t: translate } = useTranslation();
  const [form] = Form.useForm();
  const [enableEstimate, setEnableEstimate] = useState<boolean | undefined>(
    undefined
  );
  const estimateForm = useRef();

  const onFinish = () => {
    props.onConfirm(form.getFieldsValue());
  };

  useEffect(() => {
    form.setFieldsValue({ isAllowOnlineTours: [1] });
  }, []);

  useEffect(() => {
    if (!enableEstimate) {
      if (estimateForm.current) {
        (estimateForm?.current as any).clearDataForm();
        form.validateFields(["estimationDate"]);
      }
    }
  }, [enableEstimate]);

  const checkPrice = (_:any, value: number) => {
    if (value < MIN_PRICE_PRODUCT) return Promise.reject('希望価格は2000円以上を入力してください。');
    if (value === 0) return Promise.reject('価格は必須です。');
    return Promise.resolve();
  };

  return (
    <div className="add-screen-container">
      <div className="screen-title">
        <div>{translate("public.product.condition.text1")}</div>
        <div>
          <span className="color-primary">*</span>
          {translate("public.product.condition.text2")}
        </div>
      </div>

      <Form form={form} onFinish={onFinish}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <AppInputNumber
              prefix="円"
              maxLength={11}
              rules={[
                { required: true, message: translate("error.price.required") },
                { validator: checkPrice }
              ]}
              label={translate("editProduct.form.askingPrice")}
              name="price"
              // formatter={(value: string) => {
              //   console.log(67, value);
              //   return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              // }}
            ></AppInputNumber>
          </Col>
        </Row>

        {/* <Row gutter={[16, 16]}>
          <Col xs={24}>
            <AppInputNumber
              prefix="日"
              maxLength={11}
              type="number"
              pattern="[0-9]*"
              rules={[
                {
                  required: true,
                  message: translate("error.enddate.required"),
                },
                {
                  validator(_: any, value: any) {
                    const realValue = Number(value);
                    if (realValue >= 1 && realValue <= 90) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "掲載終了日は1日以上90日以下を指定してください。"
                    );
                  },
                },
              ]}
              label={translate("editProduct.form.postingEndDate")}
              name="settingDaysEndDate"
              onKeyPress={(event) => {
                if (CHARACTER_SPECIALS.includes(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={(e) => {
                e.preventDefault();
                if (
                  CHARACTER_SPECIALS.some((char) =>
                    e.clipboardData.getData("text").includes(char)
                  )
                ) {
                  return false;
                }
                form.setFieldsValue({
                  zipCode: e.clipboardData.getData("text"),
                });
              }}
              onWheel={(e) => (e.target as any).blur()}
              // formatter={(value: string) =>
              //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              // }
            ></AppInputNumber>
          </Col>
        </Row> */}

        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <AppTextField
              ispostingenddate='true'
              extra="商品の公開から"
              prefix="日"
              maxLength={11}
              type="number"
              pattern="[0-9]*"
              formgroup={form}
              onlyInputNumber
              className="number-custom-app-text-field"
              rules={[
                {
                  required: true,
                  message: translate("error.enddate.required"),
                },
                {
                  validator(_: any, value: any) {
                    const realValue = Number(value);
                    if (realValue >= 1 && realValue <= 120) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "掲載終了日は1日以上120日以下を指定して下さい。"
                    );
                  },
                },
              ]}
              label={translate("editProduct.form.postingEndDate")}
              name="settingDaysEndDate"
              onKeyPress={(event) => {
                if (CHARACTER_SPECIALS.includes(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={(e) => {
                e.preventDefault();
                if (
                  CHARACTER_SPECIALS.some((char) =>
                    e.clipboardData.getData("text").includes(char)
                  )
                ) {
                  return false;
                }
                form.setFieldsValue({
                  zipCode: e.clipboardData.getData("text"),
                });
              }}
              onWheel={(e) => (e.target as any).blur()}
              // formatter={(value: string) =>
              //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              // }
            ></AppTextField>
          </Col>
        </Row>

        {/* <Row gutter={[16, 16]}>
          <Col xs={24}>
            <AppDatePicker
              type="default"
              form={form}
              startyear={moment().year() + 5}
              endyear={moment().year()}
              values={form.getFieldValue("endDate")}
              label={translate("editProduct.form.postingEndDate")}
              name="endDate"
              rules={[
                {
                  required: true,
                  message: translate("error.enddate.required"),
                },
                {
                  validator(_: any, value: any) {
                    if (value > moment().set("hour", 0).set("minute", 0)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "掲載終了日は現在の日付以降を指定してください。"
                    );
                  },
                },
              ]}
            ></AppDatePicker>
          </Col>
        </Row> */}

        <Row gutter={[16, 16]}>
          <Col xs={24} className="col-checkbox">
            <AppCheckbox
              type="reverse"
              name="isAllowOnlineTours"
              label=""
              options={[
                {
                  label: translate("public.product.condition.online"),
                  value: 1,
                },
              ]}
            ></AppCheckbox>
          </Col>
        </Row>

        <div className="line-break-d">&nbsp;</div>

        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <AppRadio
              defaultValue={2}
              label={translate("editProduct.form.carryingCost")}
              options={[
                { label: translate("editProduct.form.buyer"), value: 2 },
                { label: translate("editProduct.form.seller"), value: 1 },
              ]}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
              name="caryCost"
            ></AppRadio>
          </Col>
          <Col className="col-note color-body">
            {translate("editProduct.form.note1")}
          </Col>
        </Row>

        {/* <div className="line-break-d">&nbsp;</div> */}
        
        {/* <Row gutter={[16, 16]}> */}
        {/*  <Col xs={24} className="col-checkbox"> */}
        {/*    <AppCheckbox */}
        {/*      type="reverse" */}
        {/*      name="isNeedTakeBackQuote" */}
        {/*      label="" */}
        {/*      options={[ */}
        {/*        { label: translate("editProduct.form.checkbox"), value: 1 }, */}
        {/*      ]} */}
        {/*      onChange={(e) => { */}
        {/*        const enableEstimate = !isEmpty( */}
        {/*          form.getFieldValue("isNeedTakeBackQuote") */}
        {/*        ); */}
        {/*        if (!enableEstimate) { */}
        {/*          form.setFieldsValue({ estimationDate: undefined }); */}
        {/*        } */}
        {/*        setEnableEstimate(enableEstimate); */}
        {/*      }} */}
        {/*    ></AppCheckbox> */}
        {/*  </Col> */}
        {/* </Row> */}
        
        {/* <Row gutter={[16, 16]}> */}
        {/*  <Col xs={24}> */}
        {/*    <AppDatePicker */}
        {/*      ref={estimateForm} */}
        {/*      rules={ */}
        {/*        enableEstimate */}
        {/*          ? [ */}
        {/*              { */}
        {/*                required: true, */}
        {/*                message: translate("error.estimate.required"), */}
        {/*              }, */}
        {/*              { */}
        {/*                validator(_: any, value: any) { */}
        {/*                  if ( */}
        {/*                    value > moment().set("hour", 0).set("minute", 0) */}
        {/*                  ) { */}
        {/*                    return Promise.resolve(); */}
        {/*                  } */}
        {/*                  return Promise.reject( */}
        {/*                    "引取見積希望日は現在の日付以降を指定してください。" */}
        {/*                  ); */}
        {/*                }, */}
        {/*              }, */}
        {/*            ] */}
        {/*          : [] */}
        {/*      } */}
        {/*      values={form.getFieldValue("estimationDate")} */}
        {/*      disabled={!enableEstimate} */}
        {/*      label={translate("public.product.condition.est")} */}
        {/*      name="estimationDate" */}
        {/*      type="default" */}
        {/*      form={form} */}
        {/*      startyear={moment().year() + 5} */}
        {/*      endyear={moment().year()} */}
        {/*    ></AppDatePicker> */}
        {/*  </Col> */}
        {/*  <Col className="col-note color-body"> */}
        {/*    {translate("editProduct.form.note2")} */}
        {/*  </Col> */}
        {/* </Row> */}

        <div className="button-container">
          <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
            {() => (
              <AppButton
                buttontype="primary"
                htmlType="submit"
                onClick={() => {
                  CommonService.scrollToError();
                }}
              >
                {translate("signup.confirm.next")}
              </AppButton>
            )}
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default AddConditionScreen;
