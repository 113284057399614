import React, { FC } from 'react';
import './index.scss';
import { ReactComponent as Close } from '@components/Icons/close-3.svg';
import { ReactComponent as Document } from '@components/Icons/document-download.svg';
import { File } from '@models/chat.model';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/configureStore';

interface Props {
  deleteFile: (payload: { type: "documents" | "medias", id: string }) => void;
  file: File;
}

const ChatPreviewer: FC<Props> = (({ ...props }) => {
  const { file, deleteFile } = props;

  const handleDelete = (collection: "documents" | "medias", id: string) => {
    deleteFile({ type: collection, id });
  };

  const sasCode = useSelector(
    (state: RootState) => state.GlobalReducer.sasCode
  );

  return (
    <div className="ui-chat-previewer">
      <div className="ui-chat-previewer__content">
        <div className="ui-chat-previewer__medias">
          {
            !!(file.medias && file.medias.length) && file.medias.map(media => (
              <div key={ media.id } className="ui-chat-previewer__media">
                {
                  media.url.includes('images') ? (
                    <img src={ media.url.concat(sasCode as string) } alt="uploaded-media"/>
                  ) : (
                    /* eslint-disable jsx-a11y/media-has-caption */
                    <video
                      disablePictureInPicture
                      controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
                      src={ media.url.concat(sasCode as string) }
                    />
                  )
                }
                <div className="ui-chat-previewer__media-closer" onClick={() => handleDelete("medias", media.id)}>
                  <Close/>
                </div>
              </div>
            ))
          }
        </div>
        <div className="ui-chat-previewer__documents">
          {
            !!(file.documents && file.documents.length) && file.documents.map(document => (
              <div key={ document.id } className="ui-chat-previewer__document">
                <div className="ui-chat-previewer__document-icon"><Document /></div>
                <div className="ui-chat-previewer__document-url">
                  <a href={document.url.concat(sasCode as string)}>{ document.name }</a>
                </div>
                <div className="ui-chat-previewer__document-closer" onClick={() => handleDelete("documents", document.id)}>
                  <Close/>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
});

export default ChatPreviewer;
