import React, { FC, memo, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ResetPasswordFormValue, ResetPasswordStatus } from "./model";
import EmailForm from "./components/EmailForm";
import PasswordForm from "./components/PasswordForm";
import ResetPasswordResult from "./components/Result";
import Success from "./components/Success";
import "./index.scss";

const ResetPassword: FC = memo(({ ...props }) => {
  const [status, setStatus] = useState<ResetPasswordStatus>(
    ResetPasswordStatus.INPUT_EMAIL
  );
  const [searchParams, _] = useSearchParams();

  const type = searchParams.get("type");
  const [value, setValue] = useState<ResetPasswordFormValue>({});

  const setResetPasswordValue = (value: ResetPasswordFormValue) =>
    setValue(value);

  useEffect(() => {
    if (type) {
      setStatus(Number(type));
    }
  }, [type]);

  const setResetPasswordStatus = (status: ResetPasswordStatus) => {
    setStatus(status);
  };

  const render = (resetStatus: ResetPasswordStatus) => {
    switch (resetStatus) {
      case ResetPasswordStatus.INPUT_EMAIL:
        return (
          <EmailForm
            setStatus={setResetPasswordStatus}
            setValue={setResetPasswordValue}
          />
        );
      case ResetPasswordStatus.SENT_EMAIL:
        return <Success value={value} />;
      case ResetPasswordStatus.INPUT_PASSWORD:
        return <PasswordForm setStatus={setResetPasswordStatus} />;
      case ResetPasswordStatus.SUCCESS:
      case ResetPasswordStatus.FAIL:
        return (
          <ResetPasswordResult
            status={status}
            setStatus={setResetPasswordStatus}
          />
        );
      default:
        return <EmailForm />;
    }
  };

  return <div className="ui-reset-password">{render(status)}</div>;
});

export default ResetPassword;

ResetPassword.defaultProps = {};
