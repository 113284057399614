import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import "./styles.scss";
import { ReactComponent as Sort } from "@components/Icons/sort.svg";
import { Category, ProductItem } from "src/models/product.model";
import VerticalItem, {
  ItemViewType,
} from "@components/utils/vertical-list-item";
import { cloneDeep, get } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import AppBottomSheet from "@components/AppBottomSheet";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/configureStore";
import ProductService from "@service/API/Product/index";
import AppSortForm from "src/components/core/sort-form";
import AppVSkeleton from "src/components/skeleton/AppVSkeleton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATH_NAME } from "src/configs";
import { useMediaQuery } from "react-responsive";
import { BREAK_POINT } from "src/models/common.model";
import AppEmptyList from "src/components/AppEmpty";
import classNames from "classnames";
import AppButton from "src/components/AppButton";
import { clearFilterByType, setStatePosition } from "src/store/Product-Filter";
import { CATEGORY_ALL } from "../dashboard-tab";


interface SearchProp {
  category?: Category;
  results: ProductItem[];
  categoryId: any[];
  onFilterCategory?: (categoryIds: number[]) => void;
}
const SearchResult: FC<SearchProp> = ({ ...props }) => {
  const isMobile = useMediaQuery({ maxWidth: BREAK_POINT.lg - 1 });

  const scrollView = useRef(null);
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const numberOfPage = 20;
  const page = useRef(0);

  const [totalCount, setTotalCount] = useState(0);

  const [showSort, setShowSort] = useState(false);

  const [listProduct, setListProduct] = useState(props.results);

  const productFilter = useSelector(
    (state: RootState) => state.ProductFilterReducer.filter
  );

  const currentStateMobile = useSelector(
    (state: RootState) => state.ProductFilterReducer.currentScreenMobile
  );

  const productSort = useSelector(
    (state: RootState) => state.ProductFilterReducer.sort
  );

  const keyword = useSelector(
    (state: RootState) => state.ProductFilterReducer.keyword
  );

  const listCategory = useSelector(
    (state: RootState) => state.ProductReducer.listCategory
  );

  const currentCategory = useRef<Category>();

  const [loading, setLoading] = useState(false);

  const listChildId = useCallback(() => {
    if (!props.category) {
      return [];
    }
    const listChild = ProductService.getListChildren(
      props.category,
      listCategory
    );
    return listChild.map((x) => x.id) || [];
  }, [props.category]);

  function getListFilter(isLoading: boolean) {
    const cloneFilter = cloneDeep(productFilter) || {};

    const listID = listChildId().filter((element) =>
      productFilter?.categories?.includes(element)
    );

    const ROOT_CATEGORIES = [2, 4];
    const isSearchRoot = ROOT_CATEGORIES.some(rootCategory => productFilter?.categories?.includes(rootCategory));

    cloneFilter.categories = cloneFilter.isFilterByType
      ? []
      : props.categoryId.includes(Number(CATEGORY_ALL))
      ? productFilter?.categories
      : isSearchRoot
      ? props.categoryId
      : !productFilter?.categories?.length ||
        productFilter?.categories?.includes(props.category?.id || -1)
      ? [...listID, props.category?.id || -1]
      : listID;

    setLoading(isLoading);
    ProductService.getListProductByFilter(
      cloneFilter,
      productSort,
      isMobile && currentStateMobile?.count ? 0 : page.current * numberOfPage,
      isMobile && currentStateMobile?.count ? currentStateMobile?.count : numberOfPage,
      keyword
    )
      .then((res) => {
        setListProduct(
          isLoading
            ? res.data.result.items
            : listProduct.concat(res.data.result.items)
        );
        if (currentStateMobile && isMobile) {
          setTimeout(() => {
            (scrollView.current as any).scrollTop =
              currentStateMobile?.position;
          }, 100);
        }

        dispatch(setStatePosition(null));


        setLoading(false);

        page.current = isMobile && currentStateMobile?.page
          ? currentStateMobile?.page
          : page.current + 1;
        if (
          props.onFilterCategory &&
          props.categoryId.includes(Number(CATEGORY_ALL))
        ) {
          props.onFilterCategory(res.data.result.categoryIds);
        }
        setTotalCount(res.data.result.totalCount);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function checkHasImport() {
    const ROOT_CATEGORIES = [2, 4];
    if (ROOT_CATEGORIES.some(rootCategory => productFilter?.categories?.includes(rootCategory))) return true;

    for (let index = 0; index < props.categoryId.length; index++) {
      const element = props.categoryId[index];
      if (productFilter?.categories?.includes(element)) {
        return true;
      }
    }

    const listID = listChildId().filter((element) =>
      productFilter?.categories?.includes(element)
    );
    if (listID.length) {
      return true;
    }
    return false;
  }

  // useEffect(() => {
  //   if (currentStateMobile) {

  //   }
  // }, []);

  useEffect(() => {
    if (
      !get(productFilter, "categories.length") ||
      checkHasImport() ||
      props.categoryId.includes(Number(CATEGORY_ALL))
    ) {
      page.current = 0;
      setListProduct([]);

      const listFilterCategory = listCategory.filter(
        (f) => (productFilter?.categories || []).indexOf(f.id) > -1
      );
      const listSecondCategory =
        ProductService.getSecondCategoryFromLastCategory(
          listCategory,
          listFilterCategory
        );

      if (props.category) {
        currentCategory.current = listSecondCategory.find(
          (item) => item.id === props.category?.id
        );
      }

      if (!loading) {
        getListFilter(true);
      }
    }
  }, [productFilter, productSort, keyword]);

  function getTitle() {
    if (productFilter?.isFilterByType) {
      return productFilter.isPickup ? "ピックアップ商品" : "最近登録された商品";
    }

    return translate("home.dashboard.search.title");
  }

  function filterAll() {
    dispatch(clearFilterByType());
  }

  const handleScroll = useCallback(() => {
    const searchHeader = document.querySelector(".search-header");
    const searchBody = document.querySelector(".search-body");
    const searchHeaderWrap = document.querySelector(".search-header-wrap");
    if ((scrollView.current as any)?.scrollTop > 80 && productFilter?.isFilterByType) {
      searchHeader?.classList.add("header-fixed");
      searchBody?.classList.add("pt-32");
      searchHeaderWrap?.classList.add("position-absolute");
    } else if ((scrollView.current as any)?.scrollTop <= 80 && productFilter?.isFilterByType)  {
      searchHeader?.classList.remove("header-fixed");
      searchBody?.classList.remove("pt-32");
      searchHeaderWrap?.classList.remove("position-absolute");
    } 
  }, []);

  useEffect(() => {
    const elementRoot = document.getElementById("root");
    elementRoot?.classList.add("scroll-hidden");
    return () => elementRoot?.classList.remove("scroll-hidden");
  }, []);

  return (
    <div
      className="search-result-container"
      id={`scrollableDiv${props?.category?.id}`}
      ref={scrollView}
      onScroll={handleScroll}
    >
      {productFilter?.isFilterByType && (
        <AppButton buttontype="secondary" onClick={filterAll}>
          全商品を見る
        </AppButton>
      )}
      <div className={classNames("search-header-wrap", !productFilter?.isFilterByType && "position-absolute-search-type")}>
        <div className={classNames("search-header", !productFilter?.isFilterByType && 'header-fixed-search-type')}>
          <div className="search-header__title text-body-2 bold">
            {getTitle()}
          </div>
          <div
            className={classNames("search-header__icon", !productFilter?.isFilterByType && "mr-32", {
              disable: !listProduct || listProduct.length <= 0,
            })}
            onClick={() => {
              setShowSort(true);
            }}
          >
            <Sort />
            <span>{translate("search.sort")}</span>
          </div>
        </div>
      </div>
      <div className={classNames("search-body", !productFilter?.isFilterByType && "pt-20")}>
        {loading ? (
          <>
            <AppVSkeleton />
            <AppVSkeleton />
            <AppVSkeleton />
          </>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {listProduct && listProduct.length > 0 ? (
              <InfiniteScroll
                dataLength={listProduct.length}
                next={() => {
                  console.log(247, 'next');
                  getListFilter(false);
                }}
                hasMore={listProduct.length < totalCount}
                loader={<h4>&nbsp;</h4>}
                scrollableTarget={`scrollableDiv${props?.category?.id}`}
              >
                {listProduct.map((value: ProductItem, index: number) => (
                  <VerticalItem
                    key={index}
                    item={value}
                    type={ItemViewType.SEARCH}
                    onClick={(item) => {
                      dispatch(
                        setStatePosition({
                          page: page.current,
                          count: listProduct.length,
                          position: (scrollView.current as any)?.scrollTop,
                        })
                      );
                      navigate(`/product-detail/${item.id}`);
                    }}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              <AppEmptyList />
            )}
          </>
        )}
      </div>

      <AppBottomSheet
        title="並び替え"
        visible={showSort}
        onClose={() => {
          setShowSort(false);
        }}
        body={
          <AppSortForm
            onSortChange={() => {
              setShowSort(false);
            }}
          />
        }
        height="410"
      ></AppBottomSheet>
    </div>
  );
};

export default SearchResult;
