import React, { FC, memo, ReactNode, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store/configureStore";
import { ReactComponent as FaqIcon } from "@components/Icons/faq-app-setting.svg";
import { ReactComponent as OperatingCompanyIcon } from "@components/Icons/operating-company-app-setting.svg";
import { ReactComponent as PrivacyPolicyIcon } from "@components/Icons/privacy-policy-app-setting.svg";
import { ReactComponent as TermsOfUseIcon } from "@components/Icons/terms-of-use-app-setting.svg";
import { ReactComponent as TransactionLawIcon } from "@components/Icons/transaction-law-app-setting.svg";
import { ReactComponent as UserManualIcon } from "@components/Icons/user-manual-app-setting.svg";
import { ReactComponent as ArrowRight } from "@components/Icons/arrow-right.svg";
import "./index.scss";
import { Col, Row } from "antd";
import AppNavigation from "src/components/AppNavigation";

interface MenuItem {
  icon: ReactNode;
  label: string;
  keyPathname?: string;
  iconRight: ReactNode;
  url?: string;
}

const WebMobileSettingMenu: FC = () => {
  const systemConfig = useSelector(
    (state: RootState) => state.GlobalReducer.systemConfig
  );
  const getUrlPolicy = (key: string): string => {
    const item = systemConfig.find((element) => element.key === key);
    if (item) {
      return item.value as unknown as string;
    }
    return "";
  };
  const items: MenuItem[] = useMemo(
    () => [
      {
        icon: <TermsOfUseIcon />,
        label: "アプリ利用規約",
        keyPathname: "terms-of-use",
        url: getUrlPolicy("TermOfService"),
        iconRight: <ArrowRight />,
      },
      {
        icon: <PrivacyPolicyIcon />,
        label: "プライバシーポリシー",
        keyPathname: "privacy-policy",
        url: getUrlPolicy("PrivacyPolicy"),
        iconRight: <ArrowRight />,
      },
      {
        icon: <FaqIcon />,
        label: "よくある質問",
        keyPathname: "faq",
        url: getUrlPolicy("FAQ"),
        iconRight: <ArrowRight />,
      },
      {
        icon: <OperatingCompanyIcon />,
        label: "運営会社",
        keyPathname: "operating-company",
        url: getUrlPolicy("CompanyInformation"),
        iconRight: <ArrowRight />,
      },
      {
        icon: <TransactionLawIcon />,
        label: "特定商取引法",
        keyPathname: "transaction-law",
        url: getUrlPolicy("CompanyLaw"),
        iconRight: <ArrowRight />,
      },
      {
        icon: <UserManualIcon />,
        label: "ご利用ガイド",
        keyPathname: "user-manual",
        url: getUrlPolicy("UserGuide"),
        iconRight: <ArrowRight />,
      },
    ],
    [systemConfig]
  );

  return (
    <div className="ui-app-setting-menu">
      <Row gutter={[32, 32]} justify="center">
        <Col xs={24}>
          <AppNavigation title="設定" />
        </Col>
      </Row>
      <div className="ui-app-setting-menu__content">
        <Row gutter={[32, 32]} justify="center">
          <Col xs={24} md={24} xl={24}>
            {items &&
              items.length &&
              items.map(({ label, icon, iconRight, url }, index) => (
                <div
                  className="ui-app-setting-menu__content-item"
                  key={index}
                  style={{
                    borderBottom: `solid 0 #D9DBE9`,
                  }}
                  onClick={() => {
                    if (url) {
                      window.open(url, "_blank", "noopener,noreferrer");
                    }
                  }}
                >
                  <div className="ui-app-setting-menu__content-left">
                    <div className="ui-app-setting-menu__content-icon">
                      {icon}
                    </div>
                    <div className="ui-app-setting-menu__content-label">
                      {label}
                    </div>
                  </div>
                  <div className="ui-my-page-menu__menu-arrow">{iconRight}</div>
                </div>
              ))}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default WebMobileSettingMenu;

WebMobileSettingMenu.defaultProps = {};
