import React, { FC, useEffect } from "react";
import { Col, Row } from "antd";
import { Desktop, Mobile } from "src/layouts/break-point";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AppDispatch, RootState } from "src/store/configureStore";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setAppLoading } from "src/store/State";
import { getUserInfo } from "@store/User";
import AppFieldset from "../../../../components/AppFieldset";
import AppRow from "../../../../components/AppRow";
import AppButton from "../../../../components/AppButton";
import AppPullToRefresh from "../../../../layouts/pull-refresh/AppPullToRefresh";

interface Props {
  data?: Array<{ label: string; value: string }>;
}

const ProfileView: FC<Props> = ({ ...props }) => {
  const { data } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userInfo = useSelector(
    (state: RootState) => state.UserReducer.userInfo
  );

  const [searchParams, setSearchParams] = useSearchParams();

  async function getData() {
    dispatch(setAppLoading(true));
    const response = await dispatch(getUserInfo());
    if (getUserInfo.fulfilled.match(response)) {
      dispatch(setAppLoading(false));
    }

    if (getUserInfo.rejected.match(response)) {
      dispatch(setAppLoading(false));
    }
  }

  useEffect(() => {
    getData();
  }, [searchParams]);

  const { t } = useTranslation();

  return (
    <AppPullToRefresh
      onRefresh={() => {
        getData();
      }}
    >
      <div className="ui-my-page-profile-view">
        <Row gutter={[32, 32]} justify="center">
          <Col xs={24} md={18} xl={24}>
            <Mobile>
              <div className="ui-my-page-profile-view__id">
                会員ID: {userInfo?.id}
              </div>
              <div className="ui-my-page-profile-view__overview">
                <AppFieldset title={t("my.page.profile.view.title")}>
                  {data &&
                    data.length &&
                    data.map(({ label, value }, index) => (
                      <AppRow
                        key={index}
                        label={label}
                        value={value}
                        hasBorderBottom={index !== data.length - 1}
                        valueColor={index === 0 ? "#FF671E" : "#333333"}
                      />
                    ))}
                </AppFieldset>
              </div>
            </Mobile>

            <Desktop>
              <Row gutter={[32, 32]}>
                {data &&
                  data.length &&
                  data.map(({ label, value }, index) => (
                    <Col key={index} xs={12}>
                      <AppRow
                        key={index}
                        label={label}
                        value={value}
                        hasBorderBottom={true}
                        valueColor={index === 0 ? "#FF671E" : "#333333"}
                        displayBlock
                      />
                    </Col>
                  ))}
              </Row>
            </Desktop>
            <div className="ui-my-page-profile-view__action">
              <Row gutter={[32, 32]} justify="center">
                <Col xs={24} md={12} xl={10}>
                  <AppButton
                    buttontype="primary"
                    onClick={() => navigate(`edit`)}
                  >
                    {t("my.page.profile.view.btn.edit")}{" "}
                  </AppButton>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </AppPullToRefresh>
  );
};

export default ProfileView;

ProfileView.defaultProps = { data: [] };
