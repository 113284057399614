import React, {
  FC,
  memo,
  useEffect,
  useState,
} from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { RootState } from "src/store/configureStore";

interface Props {
  content?: string;
  maxLength?: number;
}

const AppHelmet: FC<Props> = memo(({ ...props }) => {
  const {
    content = "マシプラは中古工作機械の検索・購入サービスです。必要な工作機械の検索、不要な機械の販売が簡単に行えます。使わない工作機械が工場にあり処分したい。機械入れ替えのために処分したい。高品質な工作機械を低価格で調達したい。一時的な量産のために機械を追加したい。小ロット試作のために一時的に機械を導入したい等、製造業が抱える悩みを解決します。",
    maxLength,
  } = props;

  const metaTitleStore = useSelector(
    (state: RootState) => state.GlobalReducer.metaTitle
  );
  const [metaTitle, setMetaTitle] = useState<string>();

  useEffect(() => {
    setMetaTitle(metaTitleStore);
  }, [metaTitleStore]);

  return (
    <Helmet>
      <title>{metaTitle || "マシプラ"}</title>
      <meta
        name="description"
        content={maxLength ? content?.slice(0, maxLength) : content}
      />
    </Helmet>
  );
});

export default AppHelmet;
