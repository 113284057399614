import { Skeleton } from "antd";
import "./styles.scss";
import React from "react";

const AppHSkeleton = () => (
  <div className="h-skeleton-container">
    <Skeleton.Avatar active shape="square" className="h-image-skeleton"
    />
    <Skeleton  active paragraph={{ rows: 1 }} />
  </div>
);

export default AppHSkeleton;
