import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ProductService from "@service/API/Product";
import { ProductItem, Category } from "@models/product.model";
import { orderBy } from "lodash";
import { FavoriteItems } from "src/pages/my-page/components/Favorite/Favorite.model";

interface ProductLike {
  status: boolean;
  count: number;
  id: number | string;
}
interface ProductState {
  listCategory: Category[];
  productDetail: ProductItem;
  likeStatus?: ProductLike;
  listCategoryOnDashboard: Category[];
}

export const getAllCategoryOnDashboard = createAsyncThunk(
  "product/categoryOnDashboard",
  async () => {
    try {
      const response = await ProductService.getAllCategoryOnDashboard();
      if (response) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const getListCategory = createAsyncThunk(
  "product/category",
  // eslint-disable-next-line consistent-return
  async () => {
    const response = await ProductService.getListCategory();
    if (response) {
      return response.data;
    }
  }
);

export const getProduct = createAsyncThunk(
  "product/detail",
  // eslint-disable-next-line consistent-return
  async (id: string) => {
    const response = await ProductService.getProduct(id);
    if (response) {
      return response.data;
    }
  }
);

export const updateLike = createAsyncThunk(
  "product/like",
  // eslint-disable-next-line consistent-return
  async (product: ProductItem | FavoriteItems) => {
    const response = await ProductService.updateLike({ id: Number(product.id), favorite: !product.canRemoveFromFavorite });
    if (response) {
      return {
        id: product.id,
        status: !product.canRemoveFromFavorite,
        count: product.canRemoveFromFavorite
          ? product.favoriteCount - 1
          : product.favoriteCount + 1,
      };
    }
  }
);

const initialState: ProductState = {
  listCategory: [],
  productDetail: {} as ProductItem,
  listCategoryOnDashboard: [],
};
const slice = createSlice({
  name: "Product",
  initialState,
  reducers: {
    setFavoriteCount: (
      state,
      {
        payload: { count, canRemoveFromFavorite },
      }: { payload: { count: number; canRemoveFromFavorite: boolean } }
    ) => {
      if (state.productDetail) {
        state.productDetail.favoriteCount = count;
        state.productDetail.canRemoveFromFavorite = canRemoveFromFavorite;
      }
    },
    clearProduct: (state) => {
      state.productDetail = {} as ProductItem;
      state.likeStatus = undefined;
    },
    clearLikeStatus: (state) => {
      state.likeStatus = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListCategory.fulfilled, (state, action) => {
      state.listCategory = orderBy(
        action.payload.result.items,
        ["displayOrder"],
        ["asc"]
      );
    });

    builder.addCase(getListCategory.rejected, (state) => {});

    builder.addCase(getProduct.fulfilled, (state, action) => {
      state.productDetail = action.payload.result;
    });

    builder.addCase(updateLike.fulfilled, (state, action) => {
      state.likeStatus = action.payload;
    });

    builder.addCase(getAllCategoryOnDashboard.fulfilled, (state, action) => {
      state.listCategoryOnDashboard = action.payload.result.items;
    });
  },
});

export const { setFavoriteCount, clearProduct, clearLikeStatus } =
  slice.actions;

export default slice.reducer;
