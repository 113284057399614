export enum ContactStatus {
  SELECT_METHOD = 1,
  SETTING_TIME,
  LEAVE_MESSAGE,
  CONFIRM,
  COMPLETE,
}

export interface ContactValue {
  method: string | number;
  candidateDates: Array<{ datetime: any }>;
  messageContent: string;
  notSendMessage: boolean | any[];
}

export enum ContactMethodEnum {
  ONLINE = 1,
  OFFLINE,
  MESSAGE,
}

export interface ContactPayloadValue {
  productId: number | string;
  type: number;
  time1: any;
  time2?: any;
  time3?: any;
  messageType: number | string;
  content: string;
  isSendMessage: boolean;
  id: number | string;
  token: string;
  orderToken: string;
}

export interface ContactDetail {
  orderId: number;
  type: number;
  time1: Date;
  time2?: any;
  time3?: any;
  messageType: number;
  content: string;
  isSendMessage: boolean;
  timeConfirmed: number;
  noteConfirmed?: any;
  status: number;
  partnerName: string;
  partnerCompany: string;
  id: number;
  orderToken: string;
  contactToken: string;
}
