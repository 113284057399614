/* eslint-disable jsx-a11y/media-has-caption */
import React, { FC, useEffect, useRef, useState } from "react";
import "./styles.scss";
import ReactPlayer from "react-player";
import { ReactComponent as Play } from "@components/Icons/play.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "src/store/configureStore";
import AppImageView from "../AppImageView";

interface Props {
  width?: string;
  height?: string;
  url: string;
  isPlay?: boolean;
  onPlayChange?: (isPlay: boolean) => void;
  onClick?: () => void;
  isEnableControl?: boolean;
}
const AppVideoPlayer: FC<Props> = ({ ...props }) => {
  const { width, height, url, isPlay, onPlayChange, onClick, isEnableControl } =
    props;

  const [playing, setPlaying] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isAndroidWebView, setAndroidWebView] = useState<boolean>(false);
  const videoRef = useRef(null);

  const convertVideoImage = useSelector(
    (state: RootState) => state.GlobalReducer.convertVideoImage
  );

  const sasCode = useSelector(
    (state: RootState) => state.GlobalReducer.sasCode
  );

  useEffect(() => {
    setPlaying(!!isPlay);
    if (isAndroidWebView) {
      if (isPlay) {
        (videoRef.current as any).play();
      } else {
        (videoRef.current as any).pause();
      }
    }
  }, [isPlay]);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.indexOf("android") > -1;
    if (isAndroid && (window as any).ReactNativeWebView) {
      setAndroidWebView(true);
    }
  }, []);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );

  return (
    <div className="app-video-container">
      <div
        style={{
          width: "100%",
          height: "100%",
          background:
            isReady || !url.toUpperCase().includes(".MP4") ? "unset" : "#000",
        }}
        onClick={() => {
          if (isPlay) {
            setPlaying(false);
            if (onPlayChange) {
              onPlayChange(false);
            }
          } else if (onClick) {
            onClick();
          }
        }}
      >
        {!isAndroidWebView && (
          <ReactPlayer
            controls={isEnableControl}
            config={{
              file: {
                forceVideo: true,
              },
            }}
            onReady={() => {
              console.log("onReady");
              setIsReady(true);
            }}
            onBuffer={() => {
              console.log("onBuffer");
            }}
            onBufferEnd={() => {
              console.log("onBufferEnd");
            }}
            onEnded={() => {
              setPlaying(false);
              if (onPlayChange) {
                onPlayChange(false);
              }
            }}
            on
            url={url}
            playing={playing}
            height={height || "100%"}
            width={width || "100%"}
          />
        )}
        {isAndroidWebView && (
          <video
            controls={isEnableControl}
            onEnded={() => {
              setPlaying(false);
              if (onPlayChange) {
                onPlayChange(false);
              }
            }}
            ref={videoRef}
            src={`${url}#t=0.1`}
            preload="auto"
            width={width || "100%"}
            height={height || "100%"}
            onCanPlay={() => {
              setIsReady(true);
            }}
          ></video>
        )}
      </div>
      {!isReady && !isEnableControl && url.toUpperCase().includes(".MP4") && (
        <div className="icon-play">
          <Spin indicator={antIcon} />
        </div>
      )}

      {!isReady && !isEnableControl && !url.toUpperCase().includes(".MP4") && (
        <div className="image-preload">
          <AppImageView
            src={convertVideoImage?.concat(sasCode as string) || ""}
          />
        </div>
      )}

      {!playing && isReady && !isEnableControl && (
        <div
          className="icon-play"
          onClick={() => {
            setPlaying(true);
            if (onPlayChange) {
              onPlayChange(true);
            }
          }}
        >
          <Play />
        </div>
      )}
    </div>
  );
};

export default AppVideoPlayer;

AppVideoPlayer.defaultProps = { isPlay: false };
