import React, { memo, FC, useState } from "react";
import classnames from "classnames";
import { SelectProps, Select, Form, Row, Col, Modal } from "antd";
import { LabeledValue } from "antd/es/select";
import { ReactComponent as Close } from "@components/Icons/close-1.svg";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as ArrowDown } from "../../../../../../components/Icons/arrow-down.svg";

interface Props extends SelectProps {
  label?: string;
  rules?: object[];
  name: string;
  options: LabeledValue[];
  ref?: any;
}

const { Option } = Select;

const AppSelect: FC<Props> = React.forwardRef(({ ...props }, ref: any)  => {
  const { name, label, rules, options, placeholder, className, clearIcon } =
    props;
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  return (
    <Row>
      <Col xs={24} id={name}>
        <Form.Item
          labelCol={{ span: 24 }}
          name={name}
          label={label}
          rules={rules}
          validateTrigger={["onBlur", "onChange"]}
        >
          <Select
            {...props}
            ref={ref}
            placeholder={placeholder}
            className={classnames("app-select", className)}
            // dropdownClassName="ant-select-custom-dropdown"
            dropdownClassName={classnames("p-0 br-8")}
            suffixIcon={<ArrowDown />}
            getPopupContainer={() =>
              document.getElementById(`${name}`) as HTMLElement
            }
            listItemHeight={isMobile ? 48 : 32}
            listHeight={isMobile ? 129 : 200}
            clearIcon={clearIcon}
            dropdownRender={(menu) => (
              <div className="ant-select-custom-dropdown">{menu}</div>
            )}
          >
            {options?.map((option: LabeledValue, index: number) => (
              <Option key={index} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
});

export default AppSelect;

AppSelect.defaultProps = { options: [], clearIcon: <Close /> };
