import React, { FC, memo, useEffect, useState } from "react";
import "./styles.scss";
import { LabeledValue } from "antd/es/select";
import { remove } from "lodash";
import { Form, RadioProps } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ReactComponent as CloseIcon } from "@components/Icons/close-small.svg";

declare type RawValue = string | number;
interface TagProps {
  value: RawValue[];
  option: LabeledValue[];
  onChange?: (value: any) => void;
  onAddTag?: () => void;
  isFilterByCategory?: boolean;
}

const CustomAppTag: FC<TagProps> = memo(({ ...props }) => {
  const [tagList, setTagList] = useState(props.option || []);

  const change = (tagList: LabeledValue[]) => {
    setTagList(tagList);
    const newValue = tagList.map((x) => x.value);
    if (typeof props.onChange === "function") {
      props.onChange(newValue);
    }
  };

  function removeTag(value: any) {
    remove(tagList, {
      value: value,
    });

    change(tagList);
  }

  useEffect(() => {
    change(props.option);
  }, [props.option]);

  return (
    <div className="tag-container">
      {
        tagList && tagList.length
          ? tagList.map((tag, index) => (
            <div key={index} className="tag-value">
              <span className="title">{tag.label}</span>
              <CloseIcon onClick={() => removeTag(tag.value)} />
            </div>
          ))
          : props.isFilterByCategory && (
            <div className="tag-value">
              <span className="title" style={{ marginRight: 0 }}>すべて</span>
            </div>
          )
      }

      <div
        className="tag-add text-body-4 color-primary"
        onClick={props.onAddTag}
      >
        <span className="title">追加</span>
        <PlusOutlined />
      </div>
    </div>
  );
});

interface TagFormProps extends RadioProps {
  options: LabeledValue[];
  onAddTag: () => void;
  label?: string;
  isFilterByCategory?: boolean;
}

const AppTagSelection: FC<TagFormProps> = memo(({ ...props }) => (
  <Form.Item name={props.name} label={props.label} labelCol={{ span: 24 }}>
    <CustomAppTag
      value={props.options.map((x) => x.value)}
      option={props.options}
      onAddTag={props.onAddTag}
      onChange={props.onChange}
      isFilterByCategory={props.isFilterByCategory}
    />
  </Form.Item>
));

export default AppTagSelection;

AppTagSelection.defaultProps = {};
