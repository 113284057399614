import React, { FC, useState } from "react";
import "./index.scss";
import { Col, Row } from "antd";
import AppNavigation from "@components/AppNavigation";
import SaleHistoryList from "./SaleHistoryList";


const MyPageSalesHistory: FC = ({ ...props }) => 
  // useHideBottomBar(true);

   (
    <div className="ui-my-page-sale-history">

      <Row gutter={[32, 32]} justify="center">
        <Col xs={24}>
          <AppNavigation
            route="/my-page"
            title="販売履歴"
          />
        </Col>
      </Row>

      <SaleHistoryList />
    </div>
  )
;

export default MyPageSalesHistory;

MyPageSalesHistory.defaultProps = {};
