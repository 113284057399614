import React, { FC, memo } from "react";
import { Button, ButtonProps } from "antd";
import classNames from "classnames";

interface Props extends ButtonProps {
  buttontype: "primary" | "secondary";
  disabled?: boolean;
}

const AppButton: FC<Props> = memo(({ ...props }) => {
  const { buttontype, className } = props;
  const classname = {
    primary: "ant-btn-custom-primary",
    secondary: "ant-btn-custom-secondary"
  };

  return (
      <Button
        className={classNames("app-button", classname[buttontype], className)}
        {...props}
      />
  );
});

export default AppButton;

AppButton.defaultProps = { buttontype: "primary" };
