import React, { FC, useEffect } from "react";
import { Col, Form, Row } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { resendForgotPassword } from "@service/API/ResetPassword";
import { setAppLoading, setTitle } from "@store/State";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@store/configureStore";
import AppButton from "../../../../components/AppButton";

import { ResetPasswordStatus, ResetPasswordStatusName } from "../../model";
import "./index.scss";
import AppNavigation from "../../../../components/AppNavigation";
import { ReactComponent as Success } from "../../../../components/Icons/success.svg";
import { ReactComponent as Fail } from "../../../../components/Icons/fail.svg";

interface Props {
  status?: ResetPasswordStatus;
  setStatus?: (status: ResetPasswordStatus) => void;
}

const ResetPasswordResult: FC<Props> = ({ ...props }) => {
  const navigate = useNavigate();
  const { status, setStatus } = props;
  const { t } = useTranslation();
  const [searchParams, _] = useSearchParams();
  const token = searchParams.get("token");
  const loading = useSelector(
    (state: RootState) => state.AppStateReducer.loading
  );
  const dispatch = useDispatch<AppDispatch>();

  const handleResetPasswordFail = async () => {
    try {
      dispatch(setAppLoading(true));
      setStatus?.(ResetPasswordStatus.INPUT_EMAIL);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setAppLoading(false));
    }
  };

  useEffect(() => {
    if (status === ResetPasswordStatus.SUCCESS) {
      dispatch(setTitle(ResetPasswordStatusName.SENT_EMAIL));
    } else dispatch(setTitle(ResetPasswordStatusName.INPUT_EMAIL));
    return () => {
      dispatch(setTitle(""));
    };
  }, []);

  return !loading ? (
    <div className="ui-reset-password-result">
      <div className="ui-reset-password-result__pageTitle">
        <Row gutter={[32, 32]} justify="center">
          <Col xs={24}>
            <AppNavigation
              title={
                status === ResetPasswordStatus.SUCCESS
                  ? t("resetPassword.result.success.pageTitle")
                  : t("resetPassword.result.fail.pageTitle")
              }
            />
          </Col>
        </Row>
      </div>
      <div className="ui-reset-password-result__content">
        <Row gutter={[32, 32]} justify="center">
          <Col xs={24} md={12} xl={8}>
            <div className="ui-reset-password-result__icon">
              {status === ResetPasswordStatus.SUCCESS ? <Success /> : <Fail />}
            </div>
            <div className="ui-reset-password-result__title">
              {status === ResetPasswordStatus.SUCCESS
                ? t("resetPassword.result.success.title")
                : t("resetPassword.result.fail.title")}
            </div>
            {status === ResetPasswordStatus.FAIL && (
              <div className="ui-reset-password-result__description">
                {t("resetPassword.result.fail.description")}
              </div>
            )}
          </Col>
        </Row>
      </div>

      <div className="ui-reset-password-result__action">
        <Form.Item style={{ margin: 0 }}>
          <Row gutter={[48, 48]} justify="center">
            <Col xs={24} md={12} xl={6}>
              {status === ResetPasswordStatus.SUCCESS ? (
                <AppButton
                  buttontype="primary"
                  onClick={() => navigate("/login")}
                >
                  {t("resetPassword.result.login")}
                </AppButton>
              ) : (
                <AppButton
                  buttontype="primary"
                  onClick={handleResetPasswordFail}
                >
                  {t("resetPassword.result.resetPassword")}
                </AppButton>
              )}
            </Col>
          </Row>
        </Form.Item>
      </div>
    </div>
  ) : null;
};

export default ResetPasswordResult;

ResetPasswordResult.defaultProps = {};
