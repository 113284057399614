import { Form, FormInstance, Input } from "antd";
import React, { memo, FC, FocusEvent } from "react";
import { TextAreaProps } from "antd/lib/input";
import classNames from "classnames";

interface Props extends TextAreaProps {
  rows?: number;
  formgroup?: FormInstance<Object>;
  label?: string;
  rules?: object[];
  shouldUpdate?: boolean;
  maxLength?: number;
}

export const AppTextArea: FC<Props> = memo(({ rows, ...props }) => {
  const { formgroup, name, onBlur, label, rules, className, shouldUpdate, maxLength } =
    props;

  function onBlurInput(e: FocusEvent<HTMLTextAreaElement>) {
    formgroup?.setFields([
      {
        name: name || "",
        value: e.target.value.trim(),
      },
    ]);

    if (onBlur) {
      onBlur(e);
    }
  }

  return (
    <Form.Item
      labelCol={{ span: 24 }}
      shouldUpdate={shouldUpdate}
      name={name}
      label={label}
      rules={rules}
      validateTrigger={["onBlur"]}
      className="app-custom-textarea"
    >
      <Input.TextArea
        {...props}
        className={classNames("app-textarea", className)}
        rows={rows}
        onBlur={(e) => onBlurInput(e)}
        showCount
        maxLength={maxLength}
      />
    </Form.Item>
  );
});

export default AppTextArea;

AppTextArea.defaultProps = {
  rows: 4,
  rules: [],
  maxLength: 100,
};
