import React, { forwardRef } from "react";

type LineProps = {
    url?: string;
} & Omit<React.ComponentProps<"a">, "herf" | "target" | "rel">;


export const LineItem = forwardRef<HTMLAnchorElement, LineProps>(
    (
        { url, ...intrinsicProps },
        forwardedRef,
    ) => {
        const lineUrl = new URL(`http://line.me/R/msg/text/? + ${url}`);

        return (
            <a
              ref={forwardedRef}
              href={lineUrl.toString()}
              target="_blank"
              rel="noopener noreferrer"
              {...intrinsicProps}
            ><img alt="" src="/assets/images/sns_line.png" className="sns-img" /></a>
          );
    },
);
export default LineItem;