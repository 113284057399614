import React, { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { Col, Row } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@store/configureStore";
import AppNavigation from "@components/AppNavigation";
import { ReactComponent as User } from "@components/Icons/user.svg";
import { ReactComponent as BankAccount } from "@components/Icons/bank-account.svg";
import { ReactComponent as Favorite } from "@components/Icons/favorite.svg";
import { ReactComponent as PurchaseHistory } from "@components/Icons/purchase-history.svg";
import { ReactComponent as SalesHistory } from "@components/Icons/sales-history.svg";
import { ReactComponent as SettingPassword } from "@components/Icons/setting-password.svg";
import { ReactComponent as QuitMembership } from "@components/Icons/quit-membership.svg";
import { ReactComponent as Logout } from "@components/Icons/logout.svg";
import { ReactComponent as ArrowRight } from "@components/Icons/arrow-right.svg";
import { PATH_NAME } from "src/configs";
import UserService from "src/services/API/User/index";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";
import { getNoticeTransactionDashboard } from "src/store/MyPage";
import AppModal from "src/components/AppModal";
import {
  setTotalUnreadMyNotice,
  setTotalUnreadNotice,
  setVideoCallStatus,
} from "src/store/Global";
import { ReactComponent as Warning } from "@components/Icons/warning.svg";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { clearRoomIds } from "@store/Chat";
import ChatService from "@service/API/Chat";
import { Collection } from "@models/chat.model";
import preval from "preval.macro";
import useHideBottomBar from "../../../../hooks/useHideBottomBar";

interface MenuItem {
  icon: ReactNode;
  label: string;
  hasBorderBottom?: boolean;
  notificationQuantity?: number;
  params?: string;
  pathname?: string;
  hidden?: boolean;
  keyPathname?: string;
  action?: () => void;
}

const MyPageMenu: FC = ({ ...props }) => {
  useHideBottomBar(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userInfo = useSelector(
    (state: RootState) => state.UserReducer.userInfo
  );
  const noticeTransactionDashboard = useSelector(
    (state: RootState) => state.MyPageReducer.noticeTransactionDashboard
  );
  const deviceToken = useSelector(
    (state: RootState) => state.GlobalReducer.deviceToken
  );

  const location = useLocation();

  const { t } = useTranslation();

  const [isConfirmLogout, setIsConfirmLogout] = useState(false);

  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });

  useEffect(() => {
    dispatch(getNoticeTransactionDashboard());
  }, []);

  const connectionSystem = useSelector(
    (state: RootState) => state.AppStateReducer.connectionSystem
  );

  const handleLogout = async () => {
    dispatch(clearRoomIds([]));
    setIsConfirmLogout(false);
    UserService.logout();
    if (deviceToken) {
      const userIndex = await ChatService.getDocumentIndex(
        Collection.USERS,
        "userId",
        userInfo?.id as number
      );
      await ChatService.deleteUserToken(
        userIndex as string,
        deviceToken as string
      );
    }
    navigate(PATH_NAME.LOGIN);
    dispatch(setTotalUnreadNotice(0));
    dispatch(setTotalUnreadMyNotice(0));
    dispatch(setVideoCallStatus(undefined));
    const message = {
      type: "LOGOUT",
    };
    (window as any)?.ReactNativeWebView?.postMessage(JSON.stringify(message));
    if (connectionSystem) {
      connectionSystem.stop();
    }
  };

  const items: MenuItem[] = useMemo(
    () => [
      {
        icon: <User />,
        label: "会員情報管理",
        pathname: "profile?tab=0",
        keyPathname: "profile",
        hasBorderBottom: false,
      },
      {
        icon: <BankAccount />,
        label: "銀行口座情報",
        pathname: "bank-account?tab=0",
        keyPathname: "bank-account",
        hasBorderBottom: false,
      },
      {
        icon: <Favorite />,
        label: "お気に入り一覧",
        pathname: "favorite?tab=0",
        keyPathname: "favorite",
        hasBorderBottom: false,
      },
      {
        icon: <PurchaseHistory />,
        label: "購入履歴",
        pathname: "purchase-history?tab=0",
        keyPathname: "purchase-history",
        hasBorderBottom: false,
        notificationQuantity: noticeTransactionDashboard?.purchaseHistoryTotal,
      },
      {
        icon: <SalesHistory />,
        label: "販売履歴",
        pathname: "sales-history?tab=0",
        keyPathname: "sales-history",
        hasBorderBottom: false,
        notificationQuantity: noticeTransactionDashboard?.saleHistoryTotal,
      },
      {
        icon: <SettingPassword />,
        label: "パスワード変更",
        pathname: "change-password?tab=0",
        keyPathname: "change-password",
        hasBorderBottom: !isDesktop,
      },
      {
        hidden: isDesktop,
        icon: <Logout />,
        label: "ログアウト",
        pathname: "/logout",
        hasBorderBottom: false,
        action: () => setIsConfirmLogout(true),
      },
      {
        icon: <QuitMembership />,
        label: "退会",
        pathname: "quit-membership?tab=0",
        keyPathname: "quit-membership",
        hasBorderBottom: false,
      },
    ],
    [noticeTransactionDashboard]
  );

  const onNavigate = (params?: string, pathname?: string) => {
    if (pathname) {
      if (
        pathname === "purchase-history?tab=0" ||
        pathname === "sales-history?tab=0" ||
        pathname === "favorite?tab=0" ||
        pathname === "profile?tab=0" ||
        pathname === "bank-account?tab=0" ||
        pathname === "change-password?tab=0" ||
        pathname === "quit-membership?tab=0"
      ) {
        navigate({
          pathname: `${pathname}&times=${Date.now()}`,
        });
      } else {
        navigate({
          pathname: pathname,
        });
      }
    }
    if (params) {
      navigate({
        search: `type=${params}`,
      });
    }
  };

  const buildTime = preval`
  module.exports = new Date().toLocaleDateString("ja-JP", {hour: '2-digit', minute:'2-digit'});
  `;

  return (
    <div className="ui-my-page-menu">
      <AppModal
        icon={<Warning />}
        visible={isConfirmLogout}
        title={t("logout.modal.title")}
        okText={t("logout.modal.ok")}
        cancelText={t("logout.modal.cancel")}
        onOk={handleLogout}
        onCancel={() => setIsConfirmLogout(false)}
      />

      <Row gutter={[32, 32]} justify="center">
        <Col xs={24}>
          <AppNavigation title="マイページ" />
        </Col>
      </Row>

      <div className="ui-my-page-menu__content">
        <Row gutter={[32, 32]} justify="center">
          <Col xs={24} md={24} xl={24}>
            <div className="ui-my-page-menu__name three-dot">
              {userInfo?.name}
            </div>
            <div className="ui-my-page-menu__email three-dot">
              {userInfo?.emailAddress}
            </div>
            <div className="ui-my-page-menu__menu">
              {items &&
                items.length &&
                items.map(
                  (
                    {
                      label,
                      icon,
                      hasBorderBottom,
                      params,
                      pathname,
                      action,
                      hidden,
                      keyPathname,
                      notificationQuantity,
                    },
                    index
                  ) => (
                    <div
                      className={classNames("ui-my-page-menu__menu-item", {
                        hidden: hidden,
                        focus:
                          keyPathname &&
                          location.pathname.includes(keyPathname),
                      })}
                      key={index}
                      style={{
                        borderBottom: `solid ${
                          hasBorderBottom ? "1px" : "0"
                        } #D9DBE9`,
                        paddingTop: index === items.length - 2 ? " 24px" : 0,
                      }}
                      onClick={() => {
                        if (action) {
                          action();
                          return;
                        }
                        onNavigate(params, pathname);
                      }}
                    >
                      <div className="ui-my-page-menu__menu-left">
                        <div className="ui-my-page-menu__menu-icon">{icon}</div>
                        <div className="ui-my-page-menu__menu-label">
                          {label}
                        </div>
                        {notificationQuantity && notificationQuantity > 0 ? (
                          <div className="ui-my-page-menu__menu-count-notice">
                            {notificationQuantity}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {!isDesktop && (
                        <div className="ui-my-page-menu__menu-arrow">
                          <ArrowRight />
                        </div>
                      )}
                    </div>
                  )
                )}
            </div>
            <div>Build Time: {buildTime}</div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MyPageMenu;

MyPageMenu.defaultProps = {};
