import { Button, Col, Form, Row } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import AppModal from "@components/AppModal";
import "./styles.scss";
import { ReactComponent as Success } from "@components/Icons/success.svg";
import { useTranslation } from "react-i18next";
import { ProductItem } from "src/models/product.model";
import ProductService from "src/services/API/Product";
import { setAppLoading } from "src/store/State";
import { useDispatch, useSelector } from "react-redux";
import { getIdError } from "src/helper";
import { injectHandleOk } from "src/App";
import { useNavigate } from "react-router-dom";
import { RootState } from "src/store/configureStore";
import AppTextField from "@components/Form/AppTextField";
import { CHARACTER_SPECIALS } from "src/configs";
import { checkZipCode, getZipCodes } from "@service/API/SignUp";
import { ZipCodeValue } from "@pages/sign-up/model";

interface ConfirmProps {
  onConfirm?: any;
  product: ProductItem;
  shippingInfo: any;
  transactionId: number;
  isAccept: boolean;
}
const ConfirmScreen: FC<ConfirmProps> = ({ ...props }) => {
  const { t: translate } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);
  const dispatch = useDispatch();
  const [isCanCallZipCode, setIsCanCallZipCode] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [showSuccess, setShowSuccess] = useState(false);
  const [orderToken, setOrderToken] = useState();
  const navigate = useNavigate();
  const currentPathname = useSelector(
    (state: RootState) => state.GlobalReducer.currentPathname
  );
  const currentZipCode = useRef("");
  const [isExistZipCode, setIsExistZipCode] = useState<boolean>(true);
  const [zipCodeListByDistrict, setZipCodeListByDistrict] = useState<
    ZipCodeValue[]
  >([]);
  const [zipCodeListByProvince, setZipCodeListByProvince] = useState<
    ZipCodeValue[]
  >([]);

  useEffect(() => {
    dispatch(setAppLoading(true));
    ProductService.getProductHistoryDetail(Number(props.transactionId))
      .then((res) => {
        dispatch(setAppLoading(false));
        setOrderToken(res.data.result.orderToken);
      })
      .catch((err) => {
        dispatch(setAppLoading(false));
      })
      .finally(() => {
        dispatch(setAppLoading(false));
      });
  }, [props.transactionId]);

  const onCancel = () => {
    setShowConfirm(false);
  };

  const onOk = () => {
    setShowConfirm(false);
    if (orderToken) {
      const { zipCode, shippingAddress } = form.getFieldsValue();
      dispatch(setAppLoading(true));
      ProductService.confirmBuyProduct({
        orderId: Number(props.transactionId),
        zipCode,
        shippingAddress,
        token: orderToken,
      })
        .then((res) => {
          dispatch(setAppLoading(false));
          setShowSuccess(true);
        })
        .catch((err) => {
          dispatch(setAppLoading(false));
          if (getIdError(err) === "M0127") {
            injectHandleOk(() => navigate(`/my-page/purchase-history/${props.transactionId}`, { replace: true, }));
          }
        });
    }
  };

  const onOkSuccess = () => {
    // props.onConfirm();
    if (currentPathname && currentPathname[currentPathname.length - 2]?.includes("purchase-history?tab=0")) {
      navigate("/my-page/purchase-history?tab=0", { replace: true });
    } else navigate(`/my-page/purchase-history/${props.transactionId}`, { replace: true });
  };

  const checkLength = (_: any, value: string) => {
    const length = value?.length;
    const zipCodeLength = 7;
    if (length !== zipCodeLength) {
      return Promise.reject(translate("signup.form.validate.zipCode.incorrect"));
    }
    return Promise.resolve();
  };

  const handleCheckZipCodeExists = async (zipCode: string) => {
    try {
      await checkZipCode(zipCode);
    } catch (error) {
      setIsExistZipCode(false);
    }
  };

  function filterZipCodeByKey(data: ZipCodeValue[], key: any) {
    const seen: any = new Set();
    return data.filter((item) => {
      const k: any = key(item);
      return seen.has(k) ? false : seen.add(k);
    });
  }

  const handleZipCodeChange = async () => {
    const zipCode = `${form.getFieldValue("zipCode") || ""}`;
    if (
      zipCode.length === 7 &&
      isCanCallZipCode &&
      zipCode !== currentZipCode.current
    ) {
      dispatch(setAppLoading(true));
      try {
        const responseZipCodes = await getZipCodes(zipCode);
        const zipCodeRes = responseZipCodes.data.result;
        currentZipCode.current = zipCode as string;
        // if (zipCodeRes.length === 0) {
        //   handleCheckZipCodeExists(zipCode);
        //   return;
        // }
        setIsExistZipCode(true);
        const filterZipCodeByDistrict = filterZipCodeByKey(
          zipCodeRes,
          (zipCode: ZipCodeValue) => zipCode.district
        );
        setZipCodeListByDistrict(filterZipCodeByDistrict);
        const filterZipCodeByProvince = filterZipCodeByKey(
          zipCodeRes,
          (zipCode: ZipCodeValue) => zipCode.province
        );
        setZipCodeListByProvince(filterZipCodeByProvince);
        if (zipCodeRes.length === 1) {
          const zipCode = zipCodeRes[0];
          form.setFields([
            {
              name: "shippingAddress",
              value: `${zipCode?.province || ''} ${zipCode?.district || ''} ${zipCode?.street || ''}`,
              touched: true,
            }
          ]);
          await form.validateFields(["shippingAddress"]);
        } else if (
          zipCodeRes.length > 1 &&
          filterZipCodeByDistrict.length > 1
        ) {
          form.setFields([
            {
              name: "shippingAddress",
              value: zipCodeRes[0].province,
              touched: true,
            }
          ]);
          await form.validateFields(["shippingAddress"]);
        } else if (
          zipCodeRes.length > 1 &&
          filterZipCodeByDistrict.length === 1
        ) {
          form.setFields([
            {
              name: "shippingAddress",
              value: `${zipCodeRes[0].province} ${filterZipCodeByDistrict[0].district}`,
              touched: true
            }
          ]);
          await form.validateFields(["shippingAddress"]);
        } else if (zipCodeRes.length === 0) {
          form.setFieldsValue({ shippingAddress: "" });
        }
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(setAppLoading(false));
      }
    } else if (zipCode.length !== 7) {
      form.setFieldsValue({ shippingAddress: "" });
      setZipCodeListByProvince([]);
      setZipCodeListByDistrict([]);
      currentZipCode.current = zipCode;
    }
  };

  return (
    <div className="confirm-container">
      {/* <div className="shiiping-info"> */}
      {/*  <div className="bold text-body-3"> */}
      {/*    {translate("product.delivery.step2")}: &nbsp;{" "} */}
      {/*    <span> */}
      {/*      {translate( */}
      {/*        `product.delivery.option${props.shippingInfo.shippingMethod || 0}` */}
      {/*      )} */}
      {/*    </span> */}
      {/*  </div> */}
      {/*  {props.shippingInfo.shippingMethod === 3 && ( */}
      {/*    <div className="color-body text-body-3">{props.shippingInfo.shippingNote}</div> */}
      {/*  )} */}
      {/* </div> */}
      <div className="confirm-container__content" style={{ marginBottom: '24px' }}>
        <div className="bold text-body-2 text-title">
          {translate("product.detail.overview")}
        </div>
        <div className="box">
          <div className="box_row">
            <div className="box_row__label text-body-4">
              {translate("product.detail.category")}
            </div>
            <div className="box_row__value text-body-4 bold">
              {ProductService.getCategoryNameFromBE(props.product.categoryName)}
            </div>
          </div>

          <div className="box_row">
            <div className="box_row__label text-body-4">
              {translate("product.detail.maker")}
            </div>
            <div className="box_row__value text-body-4 bold">
              {props.product.maker}
            </div>
          </div>

          <div className="box_row">
            <div className="box_row__label text-body-4">
              {translate("product.detail.title")}
            </div>
            <div className="box_row__value text-body-4 bold">
              {props.product.title}
            </div>
          </div>
          <div className="box_row">
            <div className="box_row__label text-body-4">
              {translate("editProduct.form.modelYear")}
            </div>
            <div className="box_row__value text-body-4 bold">
              {props.product.yearOfManufacture ?  props.product.yearOfManufacture >= 1980
                ? props.product.yearOfManufacture
                : "1980年以前" : "年式不明"}
            </div>
          </div>

          {/* <div className="box_row"> */}
          {/*  <div className="box_row__label text-body-4"> */}
          {/*    {translate("product.detail.keyword")} */}
          {/*  </div> */}
          {/*  <div className="box_row__value text-body-4 bold"> */}
          {/*    {props.product.keyword} */}
          {/*  </div> */}
          {/* </div> */}

          {/* <div className="box_row"> */}
          {/*  <div className="box_row__label text-body-4"> */}
          {/*    {translate("product.detail.comment")} */}
          {/*  </div> */}
          {/*  <div className="box_row__value text-body-4 bold"> */}
          {/*    {props.product.comment} */}
          {/*  </div> */}
          {/* </div> */}

          <div className="box_row">
            <div className="box_row__label text-body-4 text-16">
              {translate("product.confirm.purchase.price")}
            </div>
            <div className="box_row__value text-body-4 bold color-primary">
              <span className="text-caption-2">¥</span>
              <NumberFormat
                value={props.product.price}
                displayType="text"
                thousandSeparator
              />
            </div>
          </div>

          <div className="box_row">
            <div className="box_row__label text-body-4 text-16">
              {translate("common.commission")}
            </div>
            <div className="box_row__value text-body-4 bold color-primary">
              <div>
                <span className="text-caption-2">¥</span>
                <NumberFormat
                  // value={props.product.commissionAmount}
                  value={Number(
                    (props.product?.commissionAmount as number) +
                      (props.product?.consumptionTaxAmount as number) || 0
                  )}
                  displayType="text"
                  thousandSeparator
                />
                <span className="color-title">（内税）</span>
              </div>

              {/* <div className="color-body text-caption-2">
                {`${translate("product.confirm.note")} `}
                <span className="text-caption-2">¥</span>
                <NumberFormat
                  value={18000}
                  displayType="text"
                  thousandSeparator
                />
                {` ${translate("product.confirm.note2")}`}
              </div> */}
            </div>
          </div>

          <div className="box_row">
            <div className="box_row__label text-body-4 text-16">
              {translate("product.confirm.total.amount")}
            </div>
            <div className="box_row__value text-body-4 bold color-primary">
              <span className="text-caption-2">¥</span>
              <NumberFormat
                value={props.product.totalAmount}
                displayType="text"
                thousandSeparator
              />
            </div>
          </div>
        </div>
        {/* <div className="note">付帯サービス: 搬出・配送サービス</div> */}
      </div>

      <Form form={form} className='confirm-buy-product' onFinish={() => {
        setShowConfirm(true);
      }}>
        <Row gutter={[16, 16]} className="h-p-16">
          <Col xs={24}>
            <div className="confirm-buy-product__title bold text-body-2 text-title">{translate("product.confirm.address.title")}</div>
            <AppTextField
              onlyInputNumber="true"
              name="zipCode"
              label={translate("signup.form.zipCode")}
              placeholder=""
              formgroup={form}
              extra={translate("signup.form.validate.zipCode.guideline", {
                params: '"ー"',
              })}
              rules={[
                {
                  required: true,
                  message: translate("signup.form.validate.zipCode.required"),
                },
                {
                  validator: checkLength,
                },
              ]}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  (event.target as any).blur();
                  event.stopPropagation();
                  event.preventDefault();
                }

                if (CHARACTER_SPECIALS.includes(event.key)) {
                  event.preventDefault();
                }
              }}
              onWheel={(e) => (e.target as any).blur()}
              onKeyDown={(event) => {
                if (
                  event.key === "e" ||
                  event.key === "E" ||
                  event.key === "ArrowUp" ||
                  event.key === "ArrowDown"
                ) {
                  event.preventDefault();
                }
              }}
              type="number"
              pattern="[0-9]*"
              onBlur={() => handleZipCodeChange()}
              onPaste={(e) => {
                e.preventDefault();
                if (
                  CHARACTER_SPECIALS.some((char) =>
                    e.clipboardData.getData("text").includes(char)
                  )
                ) {
                  return false;
                }
                form.setFieldsValue({
                  zipCode: e.clipboardData.getData("text"),
                });
              }}
              onChange={(e) => {
                setIsCanCallZipCode(!!(e.target.value.length === 7));
              }}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]} className="h-p-16">
          <Col xs={24}>
            <AppTextField
              label="住所"
              name="shippingAddress"
              rules={[
              {
                required: true,
                message: "発送先住所は必須です。",
              }]}
            />
          </Col>
        </Row>

        <div className="button-container">
          <Button
            htmlType="submit"
            className="button-secondary"
          >
            {translate("product.delivery.step4")}
          </Button>
        </div>
      </Form>

      <AppModal
        visible={showConfirm}
        title={translate("product.confirm.popup.text1")}
        content={
          <span>
            購入確定後は購入者からキャンセルできません。
            <br /> 購入代金の振込先はメールで通知されます。
          </span>
        }
        cancelText={translate("product.confirm.popup.cancel")}
        okText="確定"
        onCancel={onCancel}
        onOk={onOk}
      />

      <AppModal
        visible={showSuccess}
        title="購入確定が完了しました。"
        content="購入代金のお振込先がメールで送信されました。メールボックスをご確認下さい。"
        cancelText={undefined}
        okText="閉じる"
        onOk={onOkSuccess}
        icon={<Success />}
      />
    </div>
  );
};

export default ConfirmScreen;
