import React, { FC, memo, ReactNode, useState } from "react";
import classnames from "classnames";
import { Form, Modal, ModalProps } from "antd";
import { useTranslation } from "react-i18next";
import ProductService from "src/services/API/Product";
import { useDispatch } from "react-redux";
import { setAppLoading } from "src/store/State";
import AppDatePicker from "@components/AppDatePicker";
import moment from "moment";
import { getIdError } from "src/helper";
import { injectHandleOk } from "src/App";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "src/configs";
import { ReactComponent as Warning } from "../Icons/warning.svg";
import AppButton from "../AppButton";
import "./index.scss";
// eslint-disable-next-line import/order
import * as dayjs from "dayjs";

const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');

dayjs.extend(isSameOrBefore);

export interface AppModalProps extends ModalProps {
  icon?: ReactNode;
  title?: string | ReactNode;
  content?: ReactNode;
  okText?: string;
  cancelText?: string;
  validateFields?: string[];
  transactionId: number;
  updateDeliveryItem?: (
    scheduledDeliveryDate: string,
    expectedArrivalDate: string
  ) => void;
  value?: { expectedArrivalDate?: string; scheduledDeliveryDate?: string };
  handleCancel?: ()=> void;
}

const AppModalConfirmTransfer: FC<AppModalProps> = memo(({ ...props }) => {
  const { t: translate } = useTranslation();
  const disPatch = useDispatch();
  const {
    visible,
    icon,
    title,
    onOk,
    onCancel,
    className,
    okText,
    cancelText,
    transactionId,
    content,
    updateDeliveryItem,
    value,
    handleCancel
  } = props;

  const [form] = Form.useForm();

  const handleCancelTransaction = () => {
    if (
      Object.values(form.getFieldsValue()).filter((item) => item === undefined)
        .length === 0
    ) {
      disPatch(setAppLoading(true));
      const expectedArrivalDate = moment(
        form.getFieldValue("expectedArrivalDate")
      ).toISOString();
      const scheduledDeliveryDate = moment(
        form.getFieldValue("scheduledDeliveryDate")
      ).toISOString();
      ProductService.updateSettingDeliveryTime(
        expectedArrivalDate,
        scheduledDeliveryDate,
        transactionId
      )
        .then((res) => {
          disPatch(setAppLoading(false));
          if (onOk) {
            onOk({} as any);
            if (res.data.result.id) {
              updateDeliveryItem?.(scheduledDeliveryDate, expectedArrivalDate);
            }
          }
        })
        .catch((err) => {
          disPatch(setAppLoading(false));
          if (onCancel) {
            onCancel({} as any);
            injectHandleOk(() => handleCancel?.());
          }
          
        });
    }
  };

  function onFinish() {
    handleCancelTransaction();
  }

  return (
    <Modal
      className={classnames("app-modal", className)}
      visible={visible}
      centered
      footer={null}
      closable={false}
      onOk={onOk}
    >
      <div className="ui-modal-confirm-transfer ">
        <div className="ui-modal__icon">{icon}</div>
        <div className="ui-modal__title">{title}</div>
        {content && <div className="ui-modal__content">{content}</div>}

        <div className="ui-modal__action">
          <Form form={form} onFinish={onFinish}>
            <AppDatePicker
              form={form}
              name="scheduledDeliveryDate"
              onChangeValue={() => {
                // console.log()
                if (form.getFieldValue("expectedArrivalDate")) {
                  form.validateFields(["expectedArrivalDate"]);
                }
              }}
              values={
                value?.scheduledDeliveryDate
                  ? moment(value?.scheduledDeliveryDate, DATE_FORMAT)
                  : form.getFieldValue("scheduledDeliveryDate")
              }
              label="搬出日"
              rules={[
                {
                  required: true,
                  message: "搬出日は必須です。",
                },
              ]}
              type="feature"
              startyear={moment().add(1, "year").year()}
              endyear={moment().year()}
            />

            <AppDatePicker
              form={form}
              name="expectedArrivalDate"
              values={
                value?.expectedArrivalDate
                  ? moment(value?.expectedArrivalDate, DATE_FORMAT)
                  : form.getFieldValue("expectedArrivalDate")
              }
              label="到着日"
              rules={[
                {
                  required: true,
                  message: "到着日は必須です。",
                },
                {
                  validator(_: any, value: any) {
                    const startDate = form.getFieldValue(
                      "scheduledDeliveryDate"
                    );
                    if (!startDate || startDate.isSameOrBefore(value, 'day')) {
                      form.setFields([
                        {
                          name: "expectedArrivalDate",
                          errors: [],
                        },
                      ]);
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "到着日は搬出日より後の日付を入力してください。"
                    );
                  },
                },
              ]}
              type="feature"
              startyear={moment().add(1, "year").year()}
              endyear={moment().year()}
            />

            <div className="ui-modal__ok-btn">
              <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                {() => (
                  <AppButton buttontype="primary" htmlType="submit">
                    {okText}
                  </AppButton>
                )}
              </Form.Item>
            </div>

            <div className="ui-modal__cancel-btn" onClick={onCancel}>
              <AppButton buttontype="secondary">閉じる</AppButton>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
});

export default AppModalConfirmTransfer;
AppModalConfirmTransfer.defaultProps = {
  icon: <Warning />,
  title: "代金の振込が確認できました。搬出予定日を入力して下さい。",
  content: undefined,
  okText: "搬出予定を通知する",
  cancelText: "キャンセル",
};
