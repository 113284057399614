import React, { FC, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AppModal from "src/components/AppModal";
import { ReactComponent as Warning } from "@components/Icons/warning.svg";
import { ReactComponent as Success } from "@components/Icons/success.svg";
import { Col, Form, Row } from "antd";
import AppNavigation from "src/components/AppNavigation";
import AppPassword from "src/components/Form/AppPassword";
import { PATH_NAME, REGEX_PASSWORD } from "src/configs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store/configureStore";
import { setAppLoading } from "src/store/State";
import AppButton from "src/components/AppButton";
import useHideBottomBar from "src/hooks/useHideBottomBar";
import UserService from "src/services/API/User";
import "./index.scss";
import { clearCurrentPathname, setIsQuitMembership } from "src/store/Global";
import { getIdError } from "src/helper";
import { injectHandleOk } from "src/App";

const QuitMembership: FC = memo(({ ...props }) => {
  useHideBottomBar(true);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector(
    (state: RootState) => state.AppStateReducer.loading
  );
  const [isConfirmQuitMembership, setIsConfirmQuitMembership] =
    useState<boolean>(false);
  const [token, setToken] = useState<string>();
  const isQuitMember = useSelector(
    (state: RootState) => state.GlobalReducer.isQuitMembership
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    form.resetFields();
  }, [searchParams]);

  const handleGetUserToken = async () => {
    dispatch(setAppLoading(true));
    try {
      const res = await UserService.getUserToken();
      setToken(res.data.result);
    } catch (e) {
      console.log(e);
      dispatch(setAppLoading(false));
    } finally {
      dispatch(setAppLoading(false));
    }
  };

  useEffect(() => {
    handleGetUserToken();
  }, []);

  const onFinish = async () => {
    dispatch(setAppLoading(true));
    try {
      await UserService.checkValidateAccount(
        form.getFieldValue("currentPassword")
      );
      setIsConfirmQuitMembership(true);
    } catch (e) {
      setIsConfirmQuitMembership(false);
    } finally {
      dispatch(setAppLoading(false));
    }
  };

  const handleQuitMembership = async () => {
    const value = form.getFieldsValue();
    const formData = {
      password: value.currentPassword,
      token: token || "",
    };
    dispatch(setAppLoading(true));
    try {
      await UserService.deActiveAccount(formData);
      dispatch(setIsQuitMembership(true));
      setIsConfirmQuitMembership(false);
      dispatch(clearCurrentPathname());
      UserService.logout();
    } catch (e) {
      setIsConfirmQuitMembership(false);
      if (
        getIdError(e) === "M0178" &&
        !isQuitMember &&
        location.pathname.includes(PATH_NAME.MY_PAGE_QUIT_MEMBERSHIP) &&
        UserService.isAuthenticated()
      ) {
        injectHandleOk(() => {
          form.resetFields();
          handleGetUserToken();
        });
      }
    } finally {
      dispatch(setAppLoading(false));
    }
  };

  return (
    <>
      <AppModal
        icon={<Warning />}
        visible={isConfirmQuitMembership}
        title={t("my.page.quit.membership.popup.title")}
        content={t("my.page.quit.membership.popup.content")}
        okText={t("my.page.quit.membership.popup.button.confirm")}
        cancelText={t("my.page.quit.membership.popup.button.cancel")}
        onCancel={() => setIsConfirmQuitMembership(false)}
        onOk={handleQuitMembership}
      />
      <div className="ui-quit-membership-form">
        <AppNavigation
          title={t("my.page.quit.membership")}
          onClick={() => navigate("/my-page")}
        />
        <div className="ui-quit-membership-form__form">
          <div className="ui-quit-membership-form__note">
            <div className="ui-quit-membership-form__note-row">
              <span>１．</span>
              <span>{t("my.page.quit.membership.note1")}</span>
            </div>
            <div className="ui-quit-membership-form__note-row">
              <span>２．</span>
              <span>{t("my.page.quit.membership.note2")}</span>
            </div>
            <div className="ui-quit-membership-form__note-row">
              <span>３．</span>
              <span>{t("my.page.quit.membership.note3")}</span>
            </div>
            <div className="ui-quit-membership-form__note-row">
              <span>４．</span>
              <span>{t("my.page.quit.membership.note4")}</span>
            </div>
          </div>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={[16, 16]} className="row-input">
              <Col xs={24}>
                <AppPassword
                  name="currentPassword"
                  label={t("my.page.quit.membership.password.label")}
                  placeholder=""
                  formgroup={form}
                  rules={[
                    {
                      required: true,
                      message: t("my.page.quit.membership.password.required"),
                    },
                  ]}
                  maxLength={255}
                  autoComplete="off"
                />
              </Col>
            </Row>

            <div className="ui-quit-membership-form__action">
              <Form.Item style={{ margin: 0 }}>
                <Row gutter={[16, 16]} justify="center">
                  <Col xs={24} md={12} xl={12}>
                    <div className="ui-quit-membership-form__action-button">
                      <AppButton buttontype="primary" htmlType="submit">
                        {t("my.page.quit.membership.button.confirm")}{" "}
                      </AppButton>
                    </div>
                  </Col>
                </Row>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
});
export default QuitMembership;

QuitMembership.defaultProps = {};
