import React, { FC, memo, useEffect, useMemo, useRef, useState } from "react";
import { Col, Form, Row } from "antd";
import { useTranslation } from "react-i18next";
import "./index.scss";
import AppTextArea from "@components/Form/AppTextArea";
import AppCheckbox from "@components/Form/AppCheckbox";
import AppButton from "@components/AppButton";
import { Desktop, Mobile } from 'src/layouts/break-point';
import classNames from "classnames";
import { ContactMethodEnum, ContactStatus, ContactValue } from "../../model";

interface Props {
  setStatus?: (status: ContactStatus) => void;
  setValue?: (status: ContactValue) => void;
  value?: ContactValue;
  setVisibleWarningModal?: () => void;
  onSubmit?: () => void;
  loading?: boolean
}

const ContactMessage: FC<Props> = memo(({ ...props }) => {
  const [form] = Form.useForm();
  const [notSendMessage, setNotSendMessage] = useState<boolean>(false);
  const { setStatus, setValue, value, setVisibleWarningModal } = props;
  const { t } = useTranslation();
  const [fieldTouched, setFieldTouched] = useState<boolean>(false);
  const dummyRef = useRef<HTMLDivElement | null>(null);

  const userAgent = navigator.userAgent.toLowerCase();
  const isAndroid = userAgent.indexOf("android") > -1;
  const isApp = (window as any).ReactNativeWebView;

  const checkboxOptions = useMemo(() => ([
    { label: t("contact.step3.notSendMessage"), value: true }
  ]), []);

  useEffect(() => {
    setNotSendMessage(!!((value?.notSendMessage as any).length));
    form.setFieldsValue(value);
  }, [value]);

  const onFinish = () => {
    const formValue = form.getFieldsValue();
    setStatus?.(value?.method !== ContactMethodEnum.MESSAGE ? ContactStatus.CONFIRM : ContactStatus.LEAVE_MESSAGE);
    setValue?.(formValue);
  };

  const onValueChanges = () => {
    const value = form.getFieldsValue();
    setNotSendMessage(!!(value.notSendMessage?.length));
    setValue?.(value);
  };

  const handleChange = () => {
    if (fieldTouched) {
      form.validateFields(['messageContent']);
    }
  };

  return (
    (
      <div className={classNames("ui-contact-message", (isAndroid && isApp) && "pb-80" )}>
        <Row gutter={[32, 32]} justify="center">
          <Col xs={24} md={14}>
            <div className="ui-contact-message__note">
              <div style={{ whiteSpace: 'pre-line' }}>{t(`contact.step3.${value?.method === ContactMethodEnum.ONLINE ? 'online' : value?.method === ContactMethodEnum.OFFLINE ? 'offline' : 'message'}.note1`)}</div>
              <div>{t(`contact.step3.${value?.method === ContactMethodEnum.ONLINE ? 'online' : value?.method === ContactMethodEnum.OFFLINE ? 'offline' : 'message'}.note2`)}</div>
              <div className="ui-contact-message__form">
                <Form
                  form={form}
                  onFinish={onFinish}
                  onValuesChange={onValueChanges}
                  layout="vertical"
                >
                  {
                    value?.method !== ContactMethodEnum.MESSAGE && (
                      <AppCheckbox
                        onChange={handleChange}
                        name="notSendMessage"
                        options={checkboxOptions}
                        type="default"
                      />
                    )
                  }

                  <AppTextArea
                    name="messageContent"
                    label={t("contact.step3.messageContent")}
                    disabled={value?.method === ContactMethodEnum.MESSAGE ? false : notSendMessage}
                    maxLength={1000}
                    rules={[{
                      required: value?.method === ContactMethodEnum.MESSAGE ? true : !notSendMessage,
                      message:
                        <>
                          <div>{t("contact.step3.notSendMessage.note1")}</div>
                          {
                            value?.method !== ContactMethodEnum.MESSAGE &&
                              <div>{t("contact.step3.notSendMessage.note2")}</div>
                          }
                        </>
                    }]}
                    onBlur={() => {
                      document.body.scrollTo({ top: 0 });
                      const value = form.getFieldValue("messageContent");
                      const handledValue = value.replace(/  +/g, "");
                      form.setFieldsValue({ messageContent: handledValue });
                      form.validateFields(["messageContent"]);
                      setValue?.({ messageContent: handledValue } as any);
                    }}
                    onFocus={() => {
                      setTimeout(() => {
                        dummyRef.current?.scrollIntoView({ block: "nearest" });
                      }, 300);
                      setFieldTouched(true);
                    }}
                    placeholder={
                      value?.method === ContactMethodEnum.ONLINE
                        ? "オンライン見学で特に確認したい点があれば、内容を記載して下さい。"
                        : value?.method === ContactMethodEnum.OFFLINE
                        ? "内見時で特に確認したい点があれば、内容を記載して下さい。"
                        : ""
                    }
                  />

                  <div ref={dummyRef} />

                  <Mobile>
                    <div className="ui-contact-method__action">
                      <Row gutter={[32, 32]} justify="center">
                        <Col xs={12} md={6} xl={4}>
                          <AppButton buttontype="secondary"
                                     onClick={setVisibleWarningModal}>{t("contact.action.close")}</AppButton>
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                          <AppButton buttontype="primary" htmlType="submit">{t("contact.action.next")}</AppButton>
                        </Col>
                      </Row>
                    </div>
                  </Mobile>

                  <Desktop>
                    <Row gutter={[32, 32]} justify="center" style={{ marginTop: "16px" }}>
                      <Col xs={6}>
                        <AppButton buttontype="secondary"
                                   onClick={setVisibleWarningModal}>{t("contact.action.close")}</AppButton>
                      </Col>
                      <Col xs={6}>
                        <AppButton buttontype="primary" htmlType="submit">{t("contact.action.next")}</AppButton>
                      </Col>
                    </Row>
                  </Desktop>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  );
});

export default ContactMessage;

ContactMessage.defaultProps = {};
