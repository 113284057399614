import classNames from "classnames";
import { cloneDeep, get } from "lodash";
import React, { FC, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import AppImageView from "src/components/AppImageView";
import { PATH_NAME } from "src/configs";
import {
  ProductItem,
  ProductTransactionStatus,
} from "src/models/product.model";
import ProductService from "src/services/API/Product";
import { EyeOutlined, HeartOutlined, HeartFilled, HistoryOutlined } from "@ant-design/icons";
import "./styles.scss";
import { RootState } from "src/store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import { updateLike } from "src/store/Product";
import { AppDispatch } from "@store/configureStore";
import AppModal from '@components/AppModal';
import CommentIconItem from "../../pickupIcon";

interface Props {
  product: ProductItem;
  type?: "SEARCH" | "DASHBOARD";
  changeFavorite?: () => void;
  listtype?: ListViewType;
}

export enum ListViewType {
  PICKUP = "PICKUP",
  RECENTLY = "RECENTLY",
}

export const ItemDesktop: FC<Props> = ({ ...props }) => {
  const { t: translate } = useTranslation();
  const { product, type } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [item, setItem] = useState<ProductItem>(product);
  const [loadingLike, setLoadingLike] = useState(false);
  const isLogined = useSelector(
    (state: RootState) => state.UserReducer.isLogin
  );
  const likeStatus = useSelector(
    (state: RootState) => state.ProductReducer.likeStatus
  );
  const user = useSelector((state: RootState) => state.UserReducer.userInfo);
  const isMyProduct = item?.userId === user?.id;
  const [confirmLogin, setConfirmLogin] = useState<boolean>(false);
  const [isLiked, setLiked] = useState(false);

  const sasCode = useSelector(
    (state: RootState) => state.GlobalReducer.sasCode
  );

  useEffect(() => {
    if (likeStatus?.id === product.id) {
      setItem((item) => ({
        ...item,
        canRemoveFromFavorite: likeStatus.status,
        favoriteCount: likeStatus.count,
      }));
      setLiked(likeStatus?.status || false);
    }
  }, [likeStatus]);

  useEffect(() => {
    setLiked(product?.canRemoveFromFavorite || false);
  }, [product]);

  async function handleLike() {
    setLoadingLike(true);
    const response = await dispatch(updateLike(item));

    if (updateLike.rejected.match(response)) {
      setLoadingLike(false);
    }
    if (updateLike.fulfilled.match(response)) {
      setLoadingLike(false);
    }

    if (props.changeFavorite) {
      props.changeFavorite();
    }
  }

  // function handleLike() {
  //   setLoadingLike(true);
  //   ProductService.updateLike(Number(item.id))
  //     .then((res) => {
  //       setLoadingLike(false);
  //       const itemClone = cloneDeep(item);
  //       itemClone.favoriteCount = item.canRemoveFromFavorite
  //         ? item.favoriteCount - 1
  //         : item.favoriteCount + 1;
  //       itemClone.canRemoveFromFavorite = !item.canRemoveFromFavorite;
  //       setItem({ ...itemClone });
  //     })
  //     .catch((err) => {
  //       setLoadingLike(false);
  //     });
  // }

  function getColor(status: ProductTransactionStatus) {
//    return "#FFA726";
    return "#ffffff";
  }

  function getBackground(status: ProductTransactionStatus) {
    // return "#FFEACC";
    return "#ff0000";
  }

  function goToDetail(params: any) {
    navigate(`/product-detail/${params.id}`);
  }

  function getImage() {
    if (!item || !(item.productMedias || []).length) {
      // return "/assets/images/fake3.png";
      return "";
    }
    const firstImage = item.productMedias.filter((f) => f.type === 1)[0];
    // return firstImage ? firstImage.url.concat(sasCode as string) : "/assets/images/fake3.png";
    return firstImage ? firstImage.url.concat(sasCode as string) : "";
  }

  const EndDaysBody = ({ item }: { item: ProductItem | undefined }) => {
    if ( item === undefined ) return <div className="text-days"></div>;
    const publicDate = moment(item?.publicDate);
    const nowDate = moment();
    const elapsedDays = nowDate.diff(publicDate, 'days');
    if ( elapsedDays === 0 ) return <div className="text-days">本日登録</div>;
    return <div className="text-days">{elapsedDays}日前</div>;
  };

  const MultiLineBody = ({ body }: { body: string }) => {
    if (body === undefined || body === null) return <div></div>;
    const texts = body.split(/\n/g).map((item, index) => 
        <React.Fragment key={index}>
          {item}
          <br />
        </React.Fragment>
    );
    return <div>{texts}</div>;
  };

  return (
    <>
      <AppModal
        visible={confirmLogin}
        title="この先の機能はログインが必要となります。"
        content=""
        okText="ログイン"
        cancelText="閉じる"
        onOk={() => navigate(PATH_NAME.LOGIN)}
        onCancel={() => {
          setConfirmLogin(false);
        }}
      />
      
      <div className="list-slider-item-lg" onClick={() => 
        goToDetail({ id: product.id })
      }>
        {/* {(!isMyProduct || !isLogined) && (
          <div
            className={classNames("button-like", "color-primary", {
              disabled: loadingLike,
            })}
            onClick={(e) => {
              e.stopPropagation();
              if (!isLogined) {
                setConfirmLogin(true);
                return;
              }
              handleLike();
            }}
          >
            {item.canRemoveFromFavorite ? <HeartFilled /> : <HeartOutlined />}
          </div>
        )} */}

        <AppImageView src={getImage()} />
        {/* <div className="product-name color-title bold text-body-4 text-cat"> */}
        <div className="product-name">
          {item.categoryName}
        </div>
        {/* <div className="product-des color-title bold text-body-4"> */}
        <div className="product-des">
          {/* <span className="three-dot">{product.maker} </span> */}
          <span className="product-model">{product.maker} </span>
          <span className="min-w-110 pl-10">
          { item?.yearOfManufacture ? item?.yearOfManufacture >= 1980
            ? `${item?.yearOfManufacture}年式`
            : "1980年以前" : "年式不明"}
          </span>
        </div>

        {/* <div className="product-title color-title bold text-body-4 three-dot"> */}
        <div className="product-title color-title bold text-body-4 text-cat">
          {item.title}
        </div>
        <div className="product-price color-primary bold text-body-2">
          <div>
          {item?.orderStatus && (
              <span
                className="text-action-status text-caption-2"
                style={{
                  background: getBackground(item?.orderStatus),
                  color: getColor(item?.orderStatus),
                  whiteSpace: "nowrap",
                }}
              >
              取引中
            </span>
            )}
          </div>
          <div className="text-action-search">
            {/* <span className="text-caption-2">¥</span> */}
            <span className="text-caption-3">¥</span>
            <NumberFormat
              value={product.price}
              displayType="text"
              thousandSeparator
            />
          </div>
        </div>
        <div className="product-action">
          <div className="favorite-item__product-favorite">
            <EyeOutlined />
            <span className="text-value">
              {item.viewCount > 999 ? "999+" : item.viewCount}
            </span>
          </div>
          <div className="favorite-item__product-favorite">
              {/* <HeartOutlined /> */}

              <div
                className={classNames(
                  "button-like-desktop",
                  { disabled: loadingLike },
                  { active: isLiked },
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  handleLike();
                  setLiked(!isLiked);
                }}
              >
                {isLiked ? <HeartFilled /> : <HeartOutlined />}
              </div>

              <span className="text-value" style={{ marginLeft: "10px" }}>
                {item.favoriteCount > 999 ? "999+" : item.favoriteCount}
              </span>
          </div>
          <div className="favorite-item__product-favorite2">
            <HistoryOutlined />
            <EndDaysBody item={item} />
          </div>

          {/* <div className="action" onClick={() => goToDetail({ id: product.id })}>
            <ArrowRightOutlined />
          </div> */}
          {/* {item?.orderStatus && (
            <span
              className="text-action-search text-caption-2"
              style={{
                background: getBackground(item?.orderStatus),
                color: getColor(item?.orderStatus),
              }}
            >
            取引中
          </span>
          )} */}
        </div>
        {(props.listtype === ListViewType.PICKUP && item.comment !== null && item.comment !== undefined)  && (
        <div>
          <CommentIconItem
            src="/assets/images/type1.png"
            src2="/assets/images/type3.png"
          />

          <div className="product-comment"><MultiLineBody body={product?.pickupComment ?? '商品ピックアップコメントです。'}></MultiLineBody></div>                       
        </div>
        )}
        
        {/* <div
          className="lg-button-action"
          onClick={() => goToDetail({ id: product.id })}
        >
          詳細を見る
        </div> */}
      </div>
    </>
  );
};
// eslint-disable-next-line react/default-props-match-prop-types
