import fileSaver from 'file-saver';
import { get } from 'lodash';
import axios from 'axios';

const transformStringDate = (date = "", symbol = "/") => {
  const dateArray = date.split(symbol);
  dateArray[0] = `${ dateArray[0] }年`;
  dateArray[1] = `${ dateArray[1] }月`;
  dateArray[2] = `${ dateArray[2] }日`;
  return dateArray.join("");
};

const getTimeZone = () => new Date().getHours() - new Date().getUTCHours();

const urlify = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => `<a target="_blank" href="${  url  }">${  url  }</a>`);
};

const stripHtmlTag = (value: string = '') => value.replace(/(<([^>]+)>)/gi, "");

function getOS() {
  const { userAgent } = navigator;
  const platform = (window.navigator as any)?.userAgentData?.platform || window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

const paramsDisableModal = () => {
  const haveParams = window.location.href.includes("?");
  if (!haveParams) return '?disableModal=true';
  const params = window.location.href.split('?')[1];
  return `?${params}&disableModal=true`;
};


const handleOpenApp = (url: string, handler: (value: boolean) => void) => {
  // const currentUrl = window.location.href;
  // const path = currentUrl.split("?")[0];
  const isApp = (window as any).ReactNativeWebView;
  if (isApp) return;
  switch (getOS()) {
    case "Android":
      if ("getInstalledRelatedApps" in navigator) {
        (navigator as any)
          .getInstalledRelatedApps()
          .then((relatedApps: any) => {
            if (relatedApps.length !== 0) {
              handler(true);
            }
          });
      }
      break;
    case "iOS":
      handler(true);
      // window.location.assign(url);
      // setTimeout(() => {
      //   if (url.includes('success')) {
      //     window.location.assign(`${path}?token=success&disableModal=true`);
      //     return;
      //   }
      //   window.location.assign(`${path}${paramsDisableModal()}`);
      // }, 100);
      break;
    default:
      return "Windows";
  }
};

const buildLink = (link: string) => {
  if (getOS() === 'iOS') {
    axios
    .post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_API_KEY}`,
      {
        dynamicLinkInfo: {
          domainUriPrefix: process.env.REACT_APP_PAGE_LINK,
          // eslint-disable-next-line no-useless-escape
          link: `https://apps.apple.com/jp/app/apple-store/id1643658859?link=${link}`,
          androidInfo: {
            androidPackageName: "jp.co.haraseikou.mashipla",
          },
          iosInfo: {
            iosBundleId: "jp.co.haraseikou.mashipla",
          },
          socialMetaTagInfo: {
            // socialTitle: 'ㅤ',
            // socialDescription: '「マシプラ」がインストールされていません。「開く」をクリックしてアプリをダウンロードしてください。',
            // socialDescription: '「マシプラ」がインストールされていません。「開く」をクリックしてダウンロードしてください。',
            socialImageLink: 'https://haraseikou.azureedge.net/upload-images/defaultImage.png?sv=2021-06-08&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2050-12-31T11:39:12Z&st=2022-01-01T03:39:12Z&spr=https&sig=C40QJ8BgJTXbkrGgI8CQ3CSg9eRqVid9mNxoLwXBbpw%3D'
          }
        },
      },
      { headers: { "Content-Type": "application/json" } }
    )
    .then((res) => {
      console.log('res.data: ', res.data);
      window.location.assign(res.data.shortLink);
    })
    .catch((err) => {
      console.error("Error: ", err);
    });
  }
};

const handleConnectionNotice = (connectionSystem: any, methodHubs: string[], action: any[]) => {
  if (connectionSystem) {
    connectionSystem
      .start()
      .then(() => {
        connectionSystem.on(methodHubs[0], action[0]);
        connectionSystem.on(methodHubs[1], action[1]);
        connectionSystem.on(methodHubs[2], action[1]);
        connectionSystem.on(methodHubs[3], action[2]);
        connectionSystem.on(methodHubs[4], action[3]);
      })
      .catch((error: any) => console.log(error));
  }
};

const handleDownloadFromUrl = (href: string) => {
  (async () => {
    const name = href.split("?")[0].split("/").pop();
    const blob = await fetch(href).then((r) => r.blob());
    fileSaver.saveAs(blob, name);
  })();
};

const getIdError = (err: any) => get(err, "response.data.error.validationErrors[0].message");

export {
  transformStringDate,
  urlify,
  handleOpenApp,
  stripHtmlTag,
  handleConnectionNotice,
  paramsDisableModal,
  handleDownloadFromUrl,
  getIdError,
  getTimeZone,
  buildLink,
  getOS
};
