import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "antd";
import moment from "moment";
import AppTextField from "@components/Form/AppTextField";
import AppDatePicker from "@components/AppDatePicker";
import AppSelect from "@components/Form/AppSelect";
import { CHARACTER_SPECIALS, DATE_FORMAT, PATH_NAME } from "@configs/index";
import AppButton from "@components/AppButton";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@store/configureStore";
import { useTranslation } from "react-i18next";
import UserService from "@service/API/User";
import { setAppLoading, setTitle } from "src/store/State";
import { getUserInfo as getUserInfoAction } from "@store/User";
import { ReactComponent as Warning } from "@components/Icons/warning.svg";
import AppModal from "src/components/AppModal";
import { getDistricts, getZipCodes } from "src/services/API/SignUp";
import { usePrompt } from "src/hooks/useBlocker";
import AppNavigation from "src/components/AppNavigation";
import { useLocation, useNavigate } from "react-router-dom";
import useHideBottomBar from "src/hooks/useHideBottomBar";
import { ZipCodeValue } from "src/pages/sign-up/model";
import CommonService from "src/services/Common";
import { ReactComponent as Success } from "@components/Icons/success.svg";
import "./index.scss";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "src/firebase";
import { Collection } from "@models/chat.model";
import ChatService from "@service/API/Chat";
import { FileType } from "@models/common.model";
import { getTimeZone } from "src/helper";
import { UserInformation } from "../../model";

const ProfileEdit: FC = ({ ...props }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  useHideBottomBar(true);
  const { provinces } = useSelector((state: RootState) => state.GlobalReducer);
  const { t } = useTranslation();

  const appScroll = useRef<HTMLElement | undefined>(undefined);

  const userInfo = useSelector(
    (state: RootState) => state.UserReducer.userInfo
  );
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [cityValue, setCityValue] = useState<string>(userInfo?.city as string);
  const [districts, setDistricts] = useState<string[]>([]);
  const [modalWarningVisible, setModalWarningVisible] =
    useState<boolean>(false);
  const [isCanCallZipCode, setIsCanCallZipCode] = useState<boolean>(false);
  const [zipCodeListByDistrict, setZipCodeListByDistrict] = useState<
    ZipCodeValue[]
  >([]);
  const [zipCodeListByProvince, setZipCodeListByProvince] = useState<
    ZipCodeValue[]
  >([]);
  const [warningEditModalVisible, setWarningEditModalVisible] =
    useState<boolean>(false);
  const [successEditModalVisible, setSuccessEditModalVisible] =
    useState<boolean>(false);
  const location = useLocation();

  const navigate = useNavigate();
  const routerTx = useRef();
  const blocker = useCallback((tx: any) => {
    routerTx.current = tx;
    setModalWarningVisible(true);
  }, []);
  const currentZipCode = useRef("");

  usePrompt(blocker, isDirty);

  const onValuesChange = () => {
    setIsDirty(true);
  };

  const currentPathname = useSelector(
    (state: RootState) => state.GlobalReducer.currentPathname
  );

  function handleBack() {
    if (!currentPathname?.length || currentPathname.length === 1) {
      navigate("/");
    } else {
      navigate((location.state as any)?.routeState || -1);
    }
  }

  useEffect(() => {
    dispatch(getUserInfoAction());
  }, []);

  useEffect(() => {
    const clonedData = { ...userInfo };
    if (clonedData?.dateOfBirth) {
      clonedData.dateOfBirth = moment(clonedData.dateOfBirth, DATE_FORMAT);
    }
    form.setFieldsValue(clonedData);
  }, [userInfo]);

  const onFinish = () => setWarningEditModalVisible(true);

  const handleSaveProfile = async () => {
    setIsDirty(false);
    const value = form.getFieldsValue();
    const formData: UserInformation = {
      id: userInfo?.id,
      emailAddress: userInfo?.emailAddress,
      name: value.name,
      dateOfBirth: moment(value.dateOfBirth).startOf("day").add("hour", getTimeZone()).toISOString(),
      companyName: value.companyName,
      department: value.department,
      position: value.position,
      zipCode: value.zipCode,
      city: value.city,
      district: value.district,
      street: value.street,
      buildingName: value.buildingName,
      phoneNumber: value.phoneNumber,
    };
    dispatch(setAppLoading(true));
    try {
      const res = await UserService.updateUserInfo(formData);
      const updatedProfile = res.data.result;
      const roomIndexes: Array<string> = [];
      const q = query(
        collection(db, Collection.ROOMS),
        where("memberIds", "array-contains", updatedProfile.id)
      );
      const snapshot = await getDocs(q);
      snapshot.forEach((doc) => roomIndexes.push(doc.id));
      if (roomIndexes.length) {
        await Promise.all(
          roomIndexes.map((roomIndex) =>
            ChatService.updateRoomMember(
              roomIndex,
              {
                id: userInfo?.id as number,
                name: userInfo?.name as string,
                companyName: userInfo?.companyName as string,
              },
              {
                id: updatedProfile.id,
                name: updatedProfile.name,
                companyName: updatedProfile.companyName,
              }
            )
          )
        );
      }
      dispatch(setAppLoading(false));
      if (res) {
        setSuccessEditModalVisible(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setAppLoading(false));
      setWarningEditModalVisible(false);
      setIsDirty(false);
    }
  };

  function filterZipCodeByKey(data: ZipCodeValue[], key: any) {
    const seen: any = new Set();
    return data.filter((item) => {
      const k: any = key(item);
      return seen.has(k) ? false : seen.add(k);
    });
  }

  const handleZipCodeChange = async () => {
    const zipCode = `${form.getFieldValue("zipCode") || ""}`;
    if (
      zipCode.length === 7 &&
      isCanCallZipCode &&
      zipCode !== currentZipCode.current
    ) {
      dispatch(setAppLoading(true));
      try {
        const responseZipCodes = await getZipCodes(zipCode);
        const zipCodeRes = responseZipCodes.data.result;
        currentZipCode.current = zipCode as string;
        const filterZipCodeByDistrict = filterZipCodeByKey(
          zipCodeRes,
          (zipCode: ZipCodeValue) => zipCode.district
        );
        setZipCodeListByDistrict(filterZipCodeByDistrict);
        const filterZipCodeByProvince = filterZipCodeByKey(
          zipCodeRes,
          (zipCode: ZipCodeValue) => zipCode.province
        );
        setZipCodeListByProvince(filterZipCodeByProvince);

        if (zipCodeRes.length === 1) {
          form.setFields([
            {
              name: "city",
              value: zipCodeRes[0].province,
              touched: true,
            },
            {
              name: "district",
              value: zipCodeRes[0].district,
              touched: true,
            },
            {
              name: "street",
              value: zipCodeRes[0].street,
              touched: true,
            },
          ]);
          await form.validateFields(["city", "district"]);
          if (zipCodeRes[0].street) {
            await form.validateFields(["street"]);
          }
        } else if (
          zipCodeRes.length > 1 &&
          filterZipCodeByDistrict.length > 1
        ) {
          form.setFields([
            {
              name: "city",
              value: zipCodeRes[0].province,
              touched: true,
            },
            {
              name: "district",
              value: "",
              touched: true,
            },
            {
              name: "street",
              value: "",
              touched: true,
            },
          ]);

          await form.validateFields(["city"]);
        } else if (
          zipCodeRes.length > 1 &&
          filterZipCodeByDistrict.length === 1
        ) {
          form.setFields([
            {
              name: "city",
              value: zipCodeRes[0].province,
              touched: true,
            },
            {
              name: "district",
              value: filterZipCodeByDistrict[0].district,
              touched: true,
            },
            {
              name: "street",
              value: "",
              touched: true,
            },
          ]);
          await form.validateFields(["city", "district"]);
        } else if (zipCodeRes.length === 0) {
          form.setFieldsValue({ city: "", district: "", street: "" });
        }
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(setAppLoading(false));
      }
    } else if (zipCode.length !== 7) {
      form.setFieldsValue({ city: "", district: "", street: "" });
      setZipCodeListByProvince([]);
      setZipCodeListByDistrict([]);
      currentZipCode.current = zipCode;
    }
  };

  const handleGetDistricts = async () => {
    try {
      dispatch(setAppLoading(true));
      const response = await getDistricts(cityValue);
      const zipCodeInfo = response.data.result;
      setDistricts(
        zipCodeInfo
          .map((item: any) => item.district)
          .filter((district: null) => district !== null)
      );
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setAppLoading(false));
    }
  };

  useEffect(() => {
    if (cityValue) {
      handleGetDistricts();
    }
  }, [cityValue]);

  const checkLength = (_: any, value: string) => {
    const length = value?.length;
    const zipCodeLength = 7;
    if (length !== zipCodeLength) {
      return Promise.reject(t("signup.form.validate.zipCode.incorrect"));
    }
    return Promise.resolve();
  };

  useEffect(() => {
    const scroll = document.getElementById("appLayout") || undefined;
    appScroll.current = scroll;
  }, []);

  useEffect(() => {
    document.body.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    dispatch(setTitle(t("my.page.profile")));
    return () => {
      dispatch(setTitle(""));
    };
  }, []);

  return (
    <div className="ui-my-page-profile">
      <AppNavigation onClick={() => handleBack()} title="会員情報" />

      <div className="ui-my-page-profile-edit">
        <AppModal
          visible={warningEditModalVisible}
          title={t("editProduct.warningEditModal.title")}
          content={t("editProduct.warningEditModal.content")}
          okText={t("editProduct.warningEditModal.okText")}
          cancelText={t("editProduct.warningEditModal.cancelText")}
          onCancel={() => setWarningEditModalVisible(false)}
          onOk={handleSaveProfile}
        />

        <AppModal
          icon={<Success />}
          visible={successEditModalVisible}
          title={t("editProduct.successEditModal.title")}
          content={t("editProduct.successEditModal.content")}
          okText={t("editProduct.successEditModal.okText")}
          onOk={() => {
            setSuccessEditModalVisible(false);
            navigate("/my-page/profile");
            appScroll.current?.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />

        <AppModal
          visible={modalWarningVisible}
          icon={<Warning />}
          title="変更された情報は破棄されます。よろしいですか？"
          content=""
          okText="はい"
          cancelText="いいえ"
          onCancel={() => setModalWarningVisible(false)}
          onOk={() => {
            (routerTx.current as any).retry();
          }}
        />
        <div className="ui-my-page-profile-edit__form">
          <Row gutter={[32, 32]} justify="center">
            <Col xs={24}>
              <div className="ui-my-page-profile-edit__note">
                {t("my.page.profile.edit.title")}
              </div>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onValuesChange={onValuesChange}
              >
                <div className="ui-my-page-profile-edit__form-input">
                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <div className="form-email">
                        <div className="form-email__label">
                          {t("signup.form.email")}
                        </div>
                        <div className="form-email__content">
                          {userInfo?.emailAddress}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <AppTextField
                        allowonlyalphabet="true"
                        name="name"
                        label={t("signup.form.name")}
                        placeholder=""
                        rules={[
                          {
                            required: true,
                            message: t("signup.form.validate.name.required"),
                          },
                          {
                            validator(_: any, value: any) {
                              if (value && value.trim().length > 0) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                t("signup.form.validate.name.required")
                              );
                            },
                          },
                        ]}
                        maxLength={255}
                        formgroup={form}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <AppDatePicker
                        values={form.getFieldValue("dateOfBirth")}
                        name="dateOfBirth"
                        label="生年月日"
                        placeholder=""
                        form={form}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "signup.form.validate.dateOfBirth.required"
                            ),
                          },
                        ]}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <AppTextField
                        type="number"
                        onlyInputNumber="true"
                        pattern="[0-9]*"
                        name="phoneNumber"
                        label="電話番号"
                        placeholder=""
                        formgroup={form}
                        maxLength={11}
                        extra={t("my.page.profile.edit.phone.guideline", {
                          params: '"ー"',
                        })}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "signup.form.validate.phoneNumber.required"
                            ),
                          },
                          {
                            pattern: /^[0][0-9]{9,10}$/,
                            message: t(
                              "signup.form.validate.phoneNumber.incorrect"
                            ),
                          },
                        ]}
                        onKeyPress={(event) => {
                          if (CHARACTER_SPECIALS.includes(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onWheel={(e) => (e.target as any).blur()}
                        onKeyDown={(event) => {
                          if (
                            event.key === "e" ||
                            event.key === "E" ||
                            event.key === "ArrowUp" ||
                            event.key === "ArrowDown"
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                          if (
                            e.clipboardData.getData("text").includes("-") ||
                            e.clipboardData.getData("text").length > 11
                          ) {
                            return false;
                          }
                          form.setFieldsValue({
                            phoneNumber: e.clipboardData.getData("text"),
                          });
                        }}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <AppTextField
                        name="companyName"
                        label={t("signup.form.companyName")}
                        placeholder=""
                        formgroup={form}
                        maxLength={255}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <AppTextField
                        name="department"
                        label={t("signup.form.department")}
                        placeholder=""
                        formgroup={form}
                        maxLength={255}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <AppTextField
                        name="position"
                        label={t("signup.form.position")}
                        placeholder=""
                        maxLength={255}
                        formgroup={form}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <AppTextField
                        name="zipCode"
                        label={t("signup.form.zipCode")}
                        placeholder=""
                        formgroup={form}
                        extra={t("my.page.profile.edit.zip.code.guideline", {
                          params: '"ー"',
                        })}
                        rules={[
                          {
                            required: true,
                            message: t("signup.form.validate.zipCode.required"),
                          },
                          {
                            validator: checkLength,
                          },
                        ]}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            (event.target as any).blur();
                            event.stopPropagation();
                            event.preventDefault();
                          }

                          if (event.key === "-") {
                            event.preventDefault();
                          }
                        }}
                        type="number"
                        onlyInputNumber="true"
                        onWheel={(e) => (e.target as any).blur()}
                        pattern="[0-9]*"
                        onBlur={() => handleZipCodeChange()}
                        onPaste={(e) => {
                          e.preventDefault();
                          if (
                            CHARACTER_SPECIALS.some((char) =>
                              e.clipboardData.getData("text").includes(char)
                            )
                          ) {
                            return false;
                          }
                          form.setFieldsValue({
                            zipCode: e.clipboardData.getData("text"),
                          });
                        }}
                        onChange={(e) =>
                          setIsCanCallZipCode(!!(e.target.value.length === 7))
                        }
                      />
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <AppSelect
                        options={
                          zipCodeListByProvince.length > 0
                            ? zipCodeListByProvince.map((zipCode) => ({
                                label: zipCode.province,
                                value: zipCode.province,
                              }))
                            : provinces.map((province) => ({
                                label: province,
                                value: province,
                              }))
                        }
                        name="city"
                        label={t("signup.form.city")}
                        placeholder=""
                        rules={[
                          {
                            required: true,
                            message: t("signup.form.validate.city.required"),
                          },
                        ]}
                        onChange={(value) => {
                          setCityValue(value);
                          form.setFieldsValue({ district: null, street: null });
                        }}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <AppSelect
                        name="district"
                        label={t("signup.form.district")}
                        placeholder=""
                        onChange={() => {
                          form.setFieldsValue({ street: null });
                        }}
                        rules={[
                          {
                            required: true,
                            message: t("signup.form.validate.district.required"),
                          },
                        ]}
                        options={
                          zipCodeListByDistrict.length > 0
                            ? zipCodeListByDistrict.map((zipCode) => ({
                                label: zipCode.district,
                                value: zipCode.district,
                              }))
                            : districts.map((district) => ({
                                label: district,
                                value: district,
                              }))
                        }
                      />
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <AppTextField
                        name="street"
                        label={t("signup.form.street")}
                        placeholder=""
                        rules={[
                          {
                            required: true,
                            message: t("signup.form.validate.street.required"),
                          },
                          {
                            validator(_: any, value: any) {
                              if (value && value.trim().length > 0) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                t("signup.form.validate.street.required")
                              );
                            },
                          },
                        ]}
                        maxLength={255}
                        formgroup={form}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <AppTextField
                        name="buildingName"
                        label={t("signup.form.buildingName")}
                        placeholder=""
                        formgroup={form}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="ui-my-page-profile-edit__action">
                  <Row gutter={[48, 48]} justify="center">
                    <Col xs={24} md={10}>
                      <AppButton
                        buttontype="primary"
                        htmlType="submit"
                        onClick={() => {
                          CommonService.scrollToError();
                        }}
                      >
                        保存{" "}
                      </AppButton>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ProfileEdit;

ProfileEdit.defaultProps = {};
