import "./styles.scss";
import { cloneDeep, get, remove } from "lodash";
import React, { useState, FC, useEffect, useRef } from "react";
import MediaChooser from "src/components/AppChooseMedia";
import AppSliderMedia, { MediaSlider } from "src/components/AppSliderMedia";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Checkbox, Col, Row } from "antd";
import { v4 as uuidv4 } from "uuid";
import AppButton from "src/components/AppButton";
import { useDispatch } from "react-redux";
import { setAppLoading, setError } from "src/store/State";
import { useTranslation } from "react-i18next";
import { Mobile } from "src/layouts/break-point";
import { MessageType } from "src/models/common.model";
import useHideBottomBar from "src/hooks/useHideBottomBar";

interface Props {
  onConfirm: any;
  onHasData: (isHasData: boolean) => void;
}
const AddMediaScreen: FC<Props> = ({ ...props }) => {
  const dispatch = useDispatch();

  const { t: translate } = useTranslation();

  const scrollView = useRef<HTMLElement | any>(null);

  const [isLoading, setLoading] = useState(false);

  const [listMedia, setImageMedia] = useState<MediaSlider[]>([]);

  const [isSaveYoutube, setSaveYoutube] = useState(false);

  const savedMedia = useRef<MediaSlider[]>([]);

  const [type, setTyped] = useState<string>("");

  function removeMediaItem(index: number) {
    remove(listMedia, {
      id: index,
    });

    savedMedia.current = cloneDeep(listMedia);

    setImageMedia(cloneDeep(listMedia));

    if (listMedia.filter((f) => f.type === "video").length === 0) {
      setSaveYoutube(false);
    }
  }

  function onChangePosition(listMedia: MediaSlider[]) {
    setImageMedia(cloneDeep(listMedia));
    savedMedia.current = cloneDeep(listMedia);
  }

  function scrollToBottom() {
    setTimeout(() => {
      if (scrollView.current) {
        scrollView.current.scrollTo({
          top: scrollView.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 100);
  }

  function addToMedia(files: any[], listUrl: string[], type: string) {
    let newList = [...listMedia];

    for (let i = 0; i < files.length; ++i) {
      const url = listUrl[i];
      const newMedia: MediaSlider = {
        type: url.indexOf("upload-images") > 0 ? "image" : "video",
        src: url,
        id: uuidv4(),
      };
      newList = [newMedia, ...newList];
    }
    setImageMedia([...newList]);
    setTyped(type);
    scrollToBottom();
  }

  function addToMediaFromApp(listUrl: string[]) {
    let newList = [...savedMedia.current];

    for (let i = 0; i < listUrl.length; ++i) {
      const url = listUrl[i];
      const newMedia: MediaSlider = {
        type: url.indexOf("upload-images") > 0 ? "image" : "video",
        src: listUrl[i],
        id: uuidv4(),
      };
      newList = [newMedia, ...newList];
    }
    savedMedia.current = newList;
    setImageMedia(newList);
    setTyped(type);
    scrollToBottom();
  }

  function validateMedia() {
    const listType = listMedia.map((x) => x.type);
    const listImage = listType.filter((f) => f === "image");

    // validate at least 1 image
    if (get(listImage, "length") <= 0) {
      dispatch(setError({ message: "C001" }));
      return false;
    }

    // validate at max 10 image
    if (get(listImage, "length") > 10) {
      dispatch(setError({ message: "C004" }));
      return false;
    }

    const listVideo = listType.filter((f) => f === "video");
    // validate at max 1 video
    if (get(listVideo, "length") > 1) {
      dispatch(setError({ message: "C004" }));
      return false;
    }

    return true;
  }

  function handleMessageFromApp(event: any) {
    if (!event || !event.data) {
      return;
    }
    try {
      const message = JSON.parse(event.data);
      switch (message.type) {
        case MessageType.LOADING:
          dispatch(setAppLoading(message.data));
          break;

        case MessageType.MEDIA:
          addToMediaFromApp(message.data);
          break;
        case MessageType.ERROR:
          if (message.data) {
            dispatch(setError(message.data || {}));
          }
          break;

        default:
          break;
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  useEffect(() => {
    const scroll = document.getElementById("sellingContainer") || null;
    scrollView.current = scroll;

    window.removeEventListener("message", handleMessageFromApp, false); // Fails
    window.addEventListener("message", (ev) => handleMessageFromApp(ev), false);
    document.addEventListener(
      "message",
      (ev) => handleMessageFromApp(ev),
      false
    );
    return () => {
      window.removeEventListener("message", handleMessageFromApp, false);
      document.removeEventListener(
        "message",
        (ev) => handleMessageFromApp(ev),
        false
      );
    };
  }, []);

  function onSubmit() {
    if (validateMedia()) {
      props.onConfirm({
        medias: listMedia.map((media) => media.src),
        isSaveYoutube: isSaveYoutube,
        listMedia: listMedia,
      });
    }
  }

  useEffect(() => {
    props.onHasData(listMedia.length > 0);
    try {
      const message = {
        type: "UPLOAD_MEDIA",
        data: type,
        listMedia: listMedia,
      };
      (window as any).ReactNativeWebView?.postMessage(JSON.stringify(message));
    } catch (e) {
      console.log(e);
    }
  }, [listMedia]);

  return (
    <>
      <div className="add-screen-container">
        <div className="screen-title">
          {translate("public.product.media.title")}
        </div>
        <div className="screen-note">
          <InfoCircleOutlined />
          <span className="color-body text-body-4">
            100MB以下の動画と5MB以下の最大10枚の画像（png、jpg、jpeg、gif、heic）をアップロードすることができます。
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;自社および他社の宣伝、公序良俗に反するもの、当サイトに関係する商品の画像および動画以外はアップロードしないで下さい。
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;製造年月日がわかるもの（銘板等）をなるべく撮影して下さい。
          </span>
        </div>
        <div className="media-container">
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <MediaChooser
                listMedia={listMedia}
                useCase="CREATE"
                label={translate("editProduct.media.gallery")}
                type="gallery"
                onSelect={(files: any[], listUrl: string[], type: string) =>
                  addToMedia(files, listUrl, type)
                }
              />
            </Col>
            <Mobile>
              <Col xs={12}>
                <MediaChooser
                  listMedia={listMedia}
                  useCase="CREATE"
                  label={translate("editProduct.media.video")}
                  type="video"
                  onSelect={(files: any[], listUrl: string[], type: string) =>
                    addToMedia(files, listUrl, type)
                  }
                />
              </Col>

              <Col xs={12}>
                <MediaChooser
                  listMedia={listMedia}
                  useCase="CREATE"
                  label={translate("editProduct.media.image")}
                  type="image"
                  onSelect={(files: any[], listUrl: string[], type: string) =>
                    addToMedia(files, listUrl, type)
                  }
                />
              </Col>
            </Mobile>
          </Row>
        </div>

        <div className="line-break">&nbsp;</div>
        {listMedia && listMedia.length > 0 && (
          <div className="note-drag-drop">
            <InfoCircleOutlined />
            画像をドラッグアンドドロップして順番を変えることができます
          </div>
        )}

        <div className="media-slider">
          <AppSliderMedia
            listMedia={listMedia}
            onRemoveItem={removeMediaItem}
            onChangePosition={onChangePosition}
          />
        </div>
        {/* {listMedia.length > 0 &&
          listMedia.filter((f) => f.type === "video").length > 0 && (
            <div className="checkbox-youtube">
              <Checkbox
                className="app-custom-checkbox"
                checked={isSaveYoutube}
                onChange={($event) => {
                  setSaveYoutube($event.target.checked);
                }}
              >
                {translate("public.product.media.youtube")}
              </Checkbox>
            </div>
          )} */}
      </div>
      <div className="button-container">
        <AppButton
          loading={isLoading}
          // disabled={listMedia.length <= 0}
          buttontype="primary"
          onClick={() => {
            onSubmit();
          }}
        >
          {translate("public.product.step2.next")}
        </AppButton>
      </div>
    </>
  );
};

export default AddMediaScreen;
