import AppHttpClient from '@core/http';
import { SignUpFormValue } from "@pages/sign-up/model";

const BASE_URL = '/api/services/app';

const signUp = (data : SignUpFormValue) => AppHttpClient.post(`${BASE_URL}/Account/SignUp`, data);

const verifyToken = (token: string) => AppHttpClient.post(`${BASE_URL}/Account/EmailVerification`, { token });

const resendEmail = (email: string) => AppHttpClient.post(`${BASE_URL}/Account/ResendEmailVerification`, { email });

const isEmailExisted = (email: string) => AppHttpClient.post(`${BASE_URL}/Account/CheckUserActiveExist`, { email });

const getProvinces = () => AppHttpClient.get(`${BASE_URL}/ZipCode/GetAllProvinces`);

const getDistricts = (province: string) => AppHttpClient.get(`${BASE_URL}/ZipCode/GetProvinceByCondition?Province=${province}`);

const getZipCodes = (zipCode: string) => AppHttpClient.get(`${BASE_URL}/ZipCode/GetZipCodes?ZipCode=${zipCode}`, {} );

const checkZipCode = (zipCode: string) => AppHttpClient.post(`${BASE_URL}/ZipCode/CheckZipcodeExist?zipcode=${zipCode}`, {} );

export { signUp, verifyToken, resendEmail, getProvinces, getDistricts, getZipCodes, isEmailExisted, checkZipCode };
