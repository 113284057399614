import "./styles.scss";
import React, { FC, useEffect, useState } from "react";
import AppButton from "src/components/AppButton";
import AppSliderMedia from "src/components/AppSliderMedia";
import {
  ProductBody,
  ProductItem,
  ProductPublicStatus,
} from "src/models/product.model";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/configureStore";
import ProductService from "src/services/API/Product";
import AppModal from "src/components/AppModal";
import { ReactComponent as Success } from "@components/Icons/success.svg";
import { ReactComponent as Checked } from "@components/Icons/icon-checked.svg";
import moment from "moment";
import { setAppLoading } from "src/store/State";
import { Checkbox } from "antd";
import classNames from "classnames";
import { getIdError } from "src/helper";
import { injectHandleOk } from "src/App";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Warning } from "@components/Icons/warning.svg";
import UserService from "src/services/API/User";
import { DATE_FORMAT } from "src/configs";


interface Props {
  onConfirm: any;
  onSettingBankAcc: any;
  productBody: ProductBody;
  listMedia: any;
  commissionAmountOnTax?: number;
}

const AddConfirmScreen: FC<Props> = ({ ...props }) => {
  const { productBody, onConfirm, listMedia, commissionAmountOnTax, onSettingBankAcc } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const listCategory = useSelector(
    (state: RootState) => state.ProductReducer.listCategory
  );
  const [productId, setProductId] = useState(-1);
  const [productDetail, setProductDetail] = useState<ProductItem>();

  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showConfirmPublish, setShowConfirmPublish] = useState(false);
  const [isHasBankInfo, setIsHasBankInfo] = useState<boolean>(false);
  const [showConfirmBankInfo, setShowConfirmBankInfo] = useState<boolean>(false);

  const onOk = () => {
    setShowConfirm(false);
    dispatch(setAppLoading(true));
    ProductService.createProduct(productBody)
      .then((res) => {
        if (res.data.result) {
          dispatch(setAppLoading(false));
          setProductId(res.data.result.id);
          setProductDetail(res.data.result);
          // setShowSuccess(true);
          setShowConfirmPublish(true);
        }
      })
      .catch((error) => {
        setShowConfirm(false);
        dispatch(setAppLoading(false));
      });
  };

  const onOkSuccess = () => {
    setShowSuccess(false);
    onConfirm(productId);
  };

  const onCancelPublish = () => {
    setShowConfirmPublish(false);
    onConfirm(productId);
  };

  const onOkPublish = () => {
    setShowConfirmPublish(false);
    if (isHasBankInfo) {
      dispatch(setAppLoading(true));
      ProductService.updateStatusProductProduct({
        productId: Number(productId),
        status: ProductPublicStatus.Published,
        userToken: productDetail?.canPublish ? productDetail.userToken : "",
      })
        .then((res) => {
          if (res.data.result) {
            setShowSuccess(true);
            dispatch(setAppLoading(false));
          }
        })
        .catch((error) => {
          dispatch(setAppLoading(false));
          if (getIdError(error) === "M0178") {
            injectHandleOk(() => navigate(`/product-detail/${productId}`));
          }
        });
    } else setShowConfirmBankInfo(true);
  };

  useEffect(() => {
    (async () => {
      dispatch(setAppLoading(true));
      try {
        const response = await UserService.getBankAccountStatus();
        setIsHasBankInfo(response.data.result);
        dispatch(setAppLoading(false));
      } catch (e) {
        console.log(e);
        dispatch(setAppLoading(false));
      }
    })();
  }, []);

  return (
    <>
      <AppModal
        visible={showConfirm}
        title={`${translate("public.product.save.confirm")}?`}
        content="登録では商品は公開されません。次の画面で公開すると、商品の販売が開始されます。"
        cancelText={translate("public.product.save.cancel")}
        okText={translate("public.product.save.ok")}
        onCancel={() => {
          setShowConfirm(false);
        }}
        onOk={onOk}
      />
      <AppModal
        visible={showConfirmBankInfo}
        icon={<Warning />}
        title="口座情報を設定して下さい。"
        content="商品登録にはマシプラからお支払いを行うための銀行口座登録が必要となります。"
        okText="設定へ"
        cancelText="キャンセル"
        onCancel={onCancelPublish}
        onOk={() => {
          onSettingBankAcc();
        }}
      />
      <AppModal
        visible={showConfirmPublish}
        title={
          <div>
            <span>商品が登録されました。</span>
            <br />
            <span>登録した商品を公開しますか?</span>
          </div>
        }
        content="公開すると登録商品は販売が開始されます。"
        cancelText={translate("public.product.save.cancel")}
        okText={translate("public.product.save.ok")}
        onOk={onOkPublish}
        onCancel={onCancelPublish}
        icon={<Success />}
      />

      <AppModal
        visible={showSuccess}
        title={translate("public.product.public.success.content")}
        content={moment().format("YYYY/MM/DD HH:mm")}
        cancelText={undefined}
        okText="閉じる"
        onOk={onOkSuccess}
        icon={<Success />}
      />

      <div className="confirm-screen-container">
        <div className="screen-title">
          <div className="text-body-3">{`${translate(
            "public.product.confirm.text1"
          )}?`}</div>
        </div>

        <div className="media-slider">
          <AppSliderMedia
            listMedia={listMedia}
            onRemoveItem={() => {}}
            disableClose
            disabled
          />
        </div>

        <div className="product-content">
          <div className="content-overview">
            <div className="title color-title text-body-2 bold"></div>
            <div className="content">
              {/* {(productBody?.medias?.filter((item) =>
                item
                  .toUpperCase()
                  .includes(
                    ".MP4" || ".MOV" || ".WMV" || ".AVI" || ".FLV" || ".MKV"
                  )
              ).length as number) > 0 && (
                <div className="row-info">
                  <div className="i-title color-body text-body-4">
                    <span
                      className={classNames(
                        "color-title text-body-4 bold box-check",
                        productBody?.isPostVideoToYoutube && "box-checked"
                      )}
                    >
                      <Checkbox
                        className="app-checkbox point-event-none"
                        checked={productBody?.isPostVideoToYoutube}
                      ></Checkbox>
                    </span>
                    <span>{translate("product.detail.upload.youtube")}</span>
                  </div>
                </div>
              )} */}
              <div className="row-info">
                <div className="i-title color-body text-body-4">
                  {translate("product.detail.category")}
                </div>
                <div className="i-value color-title text-body-4 bold">
                  {ProductService.getGroupCategoryString(
                    listCategory,
                    productBody.categoryId
                  )}
                </div>
              </div>

              <div className="row-info">
                <div className="i-title color-body text-body-4">
                  {translate("product.detail.maker")}
                </div>
                <div className="i-value color-title text-body-4 bold">
                  {productBody.maker}
                </div>
              </div>

              <div className="row-info">
                <div className="i-title color-body text-body-4">
                  {translate("product.detail.title")}
                </div>
                <div className="i-value color-title text-body-4 bold">
                  {productBody.title}
                </div>
              </div>

              <div className="row-info">
                <div className="i-title color-body text-body-4">
                  {translate("product.detail.year")}
                </div>
                <div className="i-value color-title text-body-4 bold">
                  {productBody?.yearOfManufacture
                    ? (productBody?.yearOfManufacture || 1980) >= 1980
                      ? productBody?.yearOfManufacture
                      : "1980年以前"
                    : "年式不明"}
                </div>
              </div>

              <div className="row-info">
                <div className="i-title color-body text-body-4">
                  {translate("product.detail.keyword")}
                </div>
                <div className="i-value color-title text-body-4 bold">
                  {productBody.keyword}
                </div>
              </div>

              <div className="row-info">
                <div className="i-title color-body text-body-4">
                  {translate("product.detail.comment")}
                </div>
                <div className="i-value color-title text-body-4 bold">
                  {productBody.comment}
                </div>
              </div>

              <div className="row-info">
                <div className="i-title color-body text-body-4">
                  {translate("product.detail.location")}
                </div>
                <div className="i-value color-title text-body-4 bold">
                  {productBody.city || ''} {productBody.district || ''} {productBody.street || ''}
                </div>
              </div>

              {/* <div className="row-info">
                <div className="i-title color-body text-body-4">
                  {translate("editProduct.form.carryingCost")}
                </div>
                <div className="i-value color-title text-body-4 bold">
                  {productBody.carryingCost === 1
                    ? translate("editProduct.form.seller")
                    : translate("editProduct.form.buyer")}
                </div>
              </div> */}

              <div className="row-info">
                <div className="i-title color-body text-body-4">
                  {translate("product.detail.price")}
                </div>
                <div className="i-value color-primary text-body-4 bold">
                  <span className="text-caption-2">¥</span>
                  <NumberFormat
                    value={productBody.price}
                    displayType="text"
                    thousandSeparator
                  />
                </div>
              </div>

              <div className="row-info">
                <div className="i-title color-body text-body-4">
                  {translate("product.detail.commission")}
                </div>
                <div className="i-value color-primary text-body-4 bold">
                  <span className="text-caption-2">¥</span>
                  <NumberFormat
                    value={commissionAmountOnTax}
                    displayType="text"
                    thousandSeparator
                  />
                  <span className="color-title text-caption-2">（内税）</span>
                </div>
              </div>

              <div className="row-info">
                <div className="i-title color-body text-body-4">
                  {translate("product.detail.posting.end.date")}
                </div>
                <div className="i-value color-title text-body-4 bold">
                  {productBody.settingDaysEndDate}<span>日（商品の公開から）</span>
                  {/* {productBody.endDate ?  moment(productBody.endDate).format("YYYY年MM月DD日") : "年式不明"} */}
                </div>
              </div>
              <div className="row-info">
                <div className="i-title color-body text-body-4">
                  <span
                    className={classNames(
                      "color-title text-body-4 bold box-check",
                      productBody?.isAllowOnlineTours && "box-checked"
                    )}
                  >
                    <Checkbox
                      className="app-checkbox point-event-none"
                      checked={productBody?.isAllowOnlineTours}
                    ></Checkbox>
                  </span>
                  <span>{translate("product.detail.visit.online")}</span>
                </div>
              </div>

              <div className="row-info">
                <div className="i-title color-body text-body-4">
                  {translate("product.detail.person.payment.ship")}
                </div>
                <div className="i-value color-title text-body-4 bold">
                  {productBody?.carryingCost === 1 ? "販売者" : "購入者"}
                </div>
              </div>

              {/* <div className="row-info"> */}
              {/*  <div className="i-title color-body text-body-4"> */}
              {/*    <span */}
              {/*      className={classNames( */}
              {/*        "color-title text-body-4 bold box-check", */}
              {/*        productBody?.isNeedTakeBackQuote && "box-checked" */}
              {/*      )} */}
              {/*    > */}
              {/*      <Checkbox */}
              {/*        className="app-checkbox point-event-none" */}
              {/*        checked={productBody?.isNeedTakeBackQuote} */}
              {/*      ></Checkbox> */}
              {/*    </span> */}

              {/*    <span> {translate("product.detail.request.quotation")} </span> */}
              {/*  </div> */}
              {/* </div> */}
              {productBody.estimationDate && (
                <div className="row-info">
                  <div className="i-title color-body text-body-4">
                    {translate("product.detail.desired.date.quotation")}
                  </div>
                  <div className="i-value color-title text-body-4 bold">
                    {moment(productBody.estimationDate, DATE_FORMAT).format(
                      "YYYY年MM月DD日"
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="button-container confirm-screen-button">
        <AppButton
          buttontype="primary"
          onClick={() => {
            setShowConfirm(true);
          }}
        >
          登録{" "}
        </AppButton>
      </div>
    </>
  );
};

export default AddConfirmScreen;
