import React, { FC, memo, ReactNode } from "react";
import { RadioProps, Radio, Form, Row, Col } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import classNames from "classnames";
import { get } from "lodash";

interface Props extends RadioProps {
  name: string;
  label?: string;
  rules?: object[];
  options?: Array<{
    label: ReactNode;
    value: CheckboxValueType;
    note?: string;
    disabled?: boolean;
  }>;
  type?: "custom" | "normal" | undefined;
  defaultValue?: string | number;
  hasNotDefaultValue?: boolean;
}

const AppRadio: FC<Props> = memo(({ children, ...props }) => {
  const {
    name,
    label,
    rules,
    options,
    className,
    defaultValue,
    hasNotDefaultValue,
  } = props;

  return (
    <>
      {(!get(props, "type") || props.type === "custom") && (
        <Form.Item
          labelCol={{ span: 24 }}
          name={name}
          label={label}
          rules={rules}
          initialValue={!hasNotDefaultValue && (defaultValue || get(options, "[0].value"))}
        >
          <Radio.Group
            className="app-radio-group"
            style={{ width: "100%" }}
            onChange={props.onChange}
          >
            {options &&
              options.length &&
              options.map(({ label, value, note, disabled }, index) => (
                <Row key={index}>
                  <Col xs={24}>
                    <Radio
                      disabled={disabled}
                      className={classNames("app-radio", className)}
                      value={value}
                    >
                      {label}
                    </Radio>
                  </Col>
                </Row>
              ))}
          </Radio.Group>
        </Form.Item>
      )}

      {props.type === "normal" && (
        <Form.Item name={name} label={label} rules={rules}>
          <Radio.Group
            onChange={props.onChange}
            className="app-normal-radio-group"
            style={{ width: "100%" }}
            defaultValue={!hasNotDefaultValue && get(options, "[0].value")}
          >
            {options &&
              options.length &&
              options.map(({ label, value, note }, index) => (
                <Radio key={index} value={value}>
                  {label}
                </Radio>
              ))}
          </Radio.Group>
        </Form.Item>
      )}
    </>
  );
});

export default AppRadio;

AppRadio.defaultProps = { rules: [], type: "custom", hasNotDefaultValue: false };
