import React, { FC, memo, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import {
  ProductItem,
  ProductTransactionStatus,
} from "src/models/product.model";
import {
  EyeOutlined,
  HeartOutlined,
  ArrowRightOutlined,
  HeartFilled,
  HistoryOutlined,
} from "@ant-design/icons";
import moment from 'moment';
import { createSearchParams, useNavigate } from "react-router-dom";
import { PATH_NAME } from "src/configs";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import "./style.scss";
import AppImageView from "src/components/AppImageView";
import ProductService from "src/services/API/Product";
import { AppDispatch, RootState } from "@store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from 'antd';
import AppModal from '@components/AppModal';
import { clearLikeStatus, updateLike } from "../../../store/Product/index";


interface ItemProp {
  item: ProductItem;
  type: ItemViewType;
  onClick?: (item: ProductItem) => void;
  changeFavorite?: () => void;
  listtype?: ListViewType;
}

export enum ItemViewType {
  DASHBOARD = "DASHBOARD",
  DETAIL = "DETAIL",
  SEARCH = "SEARCH",
  CONFIRM_BUY = "CONFIRM_BUY",
}

export enum ListViewType {
  PICKUP = "PICKUP",
  RECENTLY = "RECENTLY",
}

const VerticalItem: FC<ItemProp> = memo(({ ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t: translate } = useTranslation();
  const [item, setItem] = useState<ProductItem>(props.item);
  const [loadingLike, setLoadingLike] = useState(false);

  const [isLiked, setLiked] = useState(false);

  const likeStatus = useSelector(
    (state: RootState) => state.ProductReducer.likeStatus
  );

  const sasCode = useSelector(
    (state: RootState) => state.GlobalReducer.sasCode
  );

  const user = useSelector((state: RootState) => state.UserReducer.userInfo);
  const isMyProduct = item?.userId === user?.id;

  const [confirmLogin, setConfirmLogin] = useState<boolean>(false);
  const isLogined = useSelector(
    (state: RootState) => state.UserReducer.isLogin
  );

  useEffect(() => {
    setItem(props.item);
  }, [props.item]);

  useEffect(() => {
    if (likeStatus?.id === props.item.id) {
      setItem({
        ...props.item,
        canRemoveFromFavorite: likeStatus?.status,
        favoriteCount: likeStatus?.count,
      });
    }
  }, [likeStatus]);

  useEffect(
    () => () => {
      dispatch(clearLikeStatus());
    },
    []
  );

  useEffect(() => {
    setLiked(item?.canRemoveFromFavorite || false);
  }, [item]);

  function getImage() {
    if (!item || !(item.productMedias || []).length) {
      // return "/assets/images/fake3.png";
      return "";
    }
    const firstImage = item.productMedias.filter((f) => f.type === 1)[0];
    // return firstImage ? firstImage.url.concat(sasCode as string) : "/assets/images/fake3.png";
    return firstImage ? firstImage.url.concat(sasCode as string) : "";
  }

  // function goToDetail(params: any) {
  //   navigate(
  //     {
  //       pathname: PATH_NAME.PRODUCT_DETAIL,
  //       search: `?${createSearchParams(new URLSearchParams(params))}`,
  //     },
  //     { state: { id: 1000 } }
  //   );
  // }

  async function handleLike() {
    setLoadingLike(true);
    const response = await dispatch(updateLike(item));

    if (updateLike.rejected.match(response)) {
      setLoadingLike(false);
    }
    if (updateLike.fulfilled.match(response)) {
      setLoadingLike(false);
    }

    if (props.changeFavorite) {
      props.changeFavorite();
    }

  }

  function getColor(status: ProductTransactionStatus) {
//    return "#FFA726";
    return "#ffffff";
  }

  function getBackground(status: ProductTransactionStatus) {
    // return "#FFEACC";
    return "#ff0000";
  }

  const MultiLineBody = ({ body }: { body: string }) => {
    if (body === undefined || body === null) return <div></div>;
    const texts = body.split(/\n/g).map((item, index) => 
        <React.Fragment key={index}>
          {item}
          <br />
        </React.Fragment>
    );
    return <div>{texts}</div>;
  };

  const EndDaysBody = ({ item }: { item: ProductItem }) => {
    const publicDate = moment(item.publicDate);
    const nowDate = moment();
    const elapsedDays = nowDate.diff(publicDate, 'days');
    if ( elapsedDays === 0 ) return <div className="text-days">本日登録</div>;
    return <div className="text-days">{elapsedDays}日前</div>;
  };
  
  return (
    <>
      <AppModal
        visible={confirmLogin}
        title="この先の機能はログインが必要となります。"
        content=""
        okText="ログイン"
        cancelText="閉じる"
        onOk={() => navigate(PATH_NAME.LOGIN)}
        onCancel={() => {
          setConfirmLogin(false);
        }}
      />
      <div className="v-list-slider-item">
        {/* {props.type !== ItemViewType.CONFIRM_BUY && !isMyProduct && (
          <div
            className={classNames("button-like", "color-primary", {
              disabled: loadingLike,
            })}
            onClick={() => {
              if (!isLogined) {
                setConfirmLogin(true);
                return;
              }
              handleLike();
            }}
          >
            {item.canRemoveFromFavorite ? <HeartFilled /> : <HeartOutlined />}
          </div>
        )} */}
        <div className="grid-item grid-itemA">
        <AppImageView
          src={getImage()}
          onClick={() => {
            if (props.onClick) {
              props.onClick(item);
            }
          }}
        />
        </div>
        <div
          // style={{ paddingLeft: props.type === ItemViewType.CONFIRM_BUY ? "22px" : "8px" }}
          className="v-child-container grid-item grid-itemB"
          onClick={() => {
            if (props.onClick) {
              props.onClick(item);
            }
          }}
        >
          {/* <div className="product-title color-title bold text-body-4 three-dot">
            {item.title}
          </div> */}

          
          <div className="product-des">
            <div className="product-category-name">
              {item.categoryName}
            </div>
          </div>

          <div className="product-des">
            <div className="product-model">
              {item.maker}
            </div>
            <div className="product-year">
              <span className="min-w-110 pl-10">
                {item?.yearOfManufacture ?  item?.yearOfManufacture >= 1980
                  ? `${item?.yearOfManufacture}年式`
                  : "1980年以前" : "年式不明"}
              </span>
            </div>
          </div>

          <div className="product-title color-title bold text-body-4 text-cat">
            {item.title}
          </div>

          {/* <div className="product-price color-secondary bold text-body-2 grid-item grid-itemE">
            {item?.orderStatus && (
              <span
                className="text-action-status text-caption-2"
                style={{
                  background: getBackground(item?.orderStatus),
                  color: getColor(item?.orderStatus),
                }}
              >
              取引中
            </span>
            )} */}
            {/* <span className="text-caption-2">¥</span> */}
            {/* <div className="text-action-price">
              <span className="text-caption-3">¥</span>
              <NumberFormat
                value={props.item.price || 0}
                displayType="text"
                thousandSeparator
              />
            </div>
          </div> */}
        </div>
        <div className="product-price color-secondary bold text-body-2 grid-item grid-itemE">
          <div className="product-footer-left">
            {item?.orderStatus && (
              <span
                className="text-caption-2 text-status"
                style={{
                  background: getBackground(item?.orderStatus),
                  color: getColor(item?.orderStatus),
                  whiteSpace: "nowrap",
                }}
              >
              取引中
              </span>
            )}           
          </div>
          <div className="product-footer-right text-action-price">
            <span className="text-caption-3">¥</span>
            <NumberFormat
              value={props.item.price || 0}
              displayType="text"
              thousandSeparator
            />
          </div>
        </div>
          {(props.type === ItemViewType.DASHBOARD ||
            props.type === ItemViewType.SEARCH) && (
            <div className="product-action grid-item grid-itemC">
              <div className="product-views">
                <EyeOutlined />
                <span style={{ marginLeft: "10px" }}>
                  {item.viewCount > 999 ? "999+" : item.viewCount}
                </span>
              </div>
              <div className="product-favorite">
                <div
                    className={classNames(
                      "button-like-menu",
                      { active: isLiked },
                      { disabled: loadingLike },
                    )}
                    onClick={() => {
                      if (!isLogined) {
                        setConfirmLogin(true);
                        return;
                      }
                      handleLike();
                    }}
                  >
                  {isLiked ? <HeartFilled /> : <HeartOutlined />}
                </div>
                {/* <HeartOutlined /> */}
                <span style={{ marginLeft: "10px" }}>
                  {item.favoriteCount > 999 ? "999+" : item.favoriteCount}
                </span>
                {/* {props.type === ItemViewType.DASHBOARD && (
                  <div className="action">
                    <ArrowRightOutlined />
                  </div>
                )} */}
              </div>
              <div className="product-favorite2">
                  <HistoryOutlined />
                  <EndDaysBody item={item} />
                </div>
            </div>
          )}
          {(props.listtype === ListViewType.PICKUP && item.comment !== null && item.comment !== undefined)  && (
              <div className="product-comment grid-item grid-itemD"><MultiLineBody body={item?.pickupComment ?? '本製品は年式も新しく状態がとても良いため大変貴重な工作機械です。早めに売れることが予想されますので、お急ぎの型は早めに購入して下さい。'}></MultiLineBody></div>
          )}
      </div>
    </>
  );
});

export default VerticalItem;
