import { useEffect } from "react";

const useHideBottomBar = (hide: boolean) => {
  useEffect(() => {
    const bottomBarRef = document.querySelector(".bottom-bar-container") as HTMLDivElement;
    const outletContainer = document.querySelector(".outlet-container") as HTMLDivElement;
    if (bottomBarRef) {
      if (hide) {
        bottomBarRef.style.display = "none";
        outletContainer.style.paddingBottom = '0px';
      } else {
        bottomBarRef.style.display = "";
        outletContainer.style.paddingBottom = '60px';
      }
    }
  }, []);
};

export default useHideBottomBar;
