import { Button, Checkbox, Form } from "antd";
import React, { FC, useEffect, useState } from "react";
import "./styles.scss";
import AppModalCancelTransaction from "src/components/AppModalCancelTransaction";
import { ReactComponent as Success } from "@components/Icons/success.svg";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AppModal from "src/components/AppModal";
import { RootState } from "src/store/configureStore";
import { AppDispatch } from "@store/configureStore";
import AppButton from '@components/AppButton';
import CommonService from '@service/Common';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { getPolicyOrderConfig } from "../../../store/Global/index";
import { CANCEL_PURCHASE_REASON } from "../../../models/product.model";

interface TermProps {
  onConfirm: any;
  isAccept: boolean;
  setAccept: (accept: boolean) => void;
  // onCancel: any;
}
const TermScreen: FC<TermProps> = ({ ...props }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const transactionId = searchParams.get("id");
  const { t: translate } = useTranslation();
  const [successModalCancelVisible, setSuccessModalCancelVisible] =
    useState(false);
  const policyConfig = useSelector(
    (state: RootState) => state.GlobalReducer.policyOrder
  );

  useEffect(() => {
    if (!policyConfig) {
      dispatch(getPolicyOrderConfig());
    }
  });

  return (
    <>
      <AppModal
        icon={<Success />}
        visible={successModalCancelVisible}
        title={translate("purchase.history.transaction.success.title")}
        okText={translate("purchase.history.transaction.success.button.close")}
        onOk={() => {
          setSuccessModalCancelVisible(false);
          navigate("/my-page/purchase-history?tab=0");
        }}
      />

      <div className="term-container">
        <div className="term-container__content">
          {/* <div className="row">
            <span className="dot">&nbsp;</span>
            <span>{translate("product.term.text1")}</span>
          </div>
          <div className="row">
            <span className="dot">&nbsp;</span>
            <span>{translate("product.term.text2")}</span>
          </div>
          <div className="row">
            <span className="dot">&nbsp;</span>
            <span>{translate("product.term.text3")}</span>
          </div>
          <div className="row">
            <span className="dot">&nbsp;</span>
            <span>{translate("product.term.text4")}</span>
          </div> */}
          <div
            className="inner-html"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: policyConfig }}
          ></div>

          <div className="accept-term">
            <Checkbox
              checked={props.isAccept}
              onChange={(e: CheckboxChangeEvent) => props.setAccept(e.target.checked)}
            >
              購入規約を承諾する。
            </Checkbox>
          </div>
        </div>

        <div className="button-container term">
          {/* <Button */}
          {/*  className="button-primary" */}
          {/*  // onClick={() => { */}
          {/*  //   props.onCancel(); */}
          {/*  // }} */}
          {/* > */}
          {/*  {translate("product.term.button1")} */}
          {/* </Button> */}

          <AppButton buttontype="primary" style={{ height: "52px" }} onClick={props.onConfirm} disabled={!props.isAccept}>
            {translate("product.term.button2")}
          </AppButton>
        </div>
      </div>
    </>
  );
};

export default TermScreen;
