import React, { FC, memo } from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import "./index.scss";
import ContactConfirm from "@pages/contact/components/Confirm";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Success } from "@components/Icons/success.svg";
import AppButton from "@components/AppButton";
import { Desktop, Mobile } from "src/layouts/break-point";
import { useMediaQuery } from "react-responsive";
import { ContactMethodEnum, ContactValue } from "../../model";

interface Props {
  value?: ContactValue;
}

const ContactComplete: FC<Props> = memo(({ ...props }) => {
  const { value } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: "(max-width: 992px)",
  });

  return (
    <div className="ui-contact-complete">
      <Row gutter={[32, 32]} justify="center" style={{ rowGap: 0 }}>
        <Col xs={24} md={14} className="col-content">
          <div className="ui-contact-complete__icon">
            <Success />
          </div>
          <div className="ui-contact-complete__title">
            {t("contact.step5.congratulation")}
          </div>
          {value?.method === ContactMethodEnum.ONLINE && (
            <div className="ui-contact-complete__description">
              {t("contact.step5.description1")}
              <br />
              {!isMobile && <br />}
              {t("contact.step5.description2")}
            </div>
          )}
        </Col>
        <Col xs={24}>
          <ContactConfirm
            type="complete"
            value={value as ContactValue}
            notSendMessage={!!(value?.notSendMessage as any)?.length}
          />
        </Col>
        <Col xs={24} md={14}>
          <Mobile>
            <div className="ui-contact-complete__action">
              <Row gutter={[48, 48]} justify="center">
                <Col xs={24} md={12}>
                  <AppButton
                    buttontype="primary"
                    onClick={() => navigate("/my-page/purchase-history?tab=0")}
                  >
                    {t("contact.modal.success.close")}
                  </AppButton>
                </Col>
              </Row>
            </div>
          </Mobile>

          <Desktop>
            <Row gutter={[48, 48]} justify="center">
              <Col xs={24} md={12}>
                <AppButton
                  buttontype="primary"
                  onClick={() => navigate("/my-page/purchase-history?tab=0")}
                >
                  {t("contact.modal.success.close")}
                </AppButton>
              </Col>
            </Row>
          </Desktop>
        </Col>
      </Row>
    </div>
  );
});

export default ContactComplete;

ContactComplete.defaultProps = {};
