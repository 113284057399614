import React, { FC, useEffect, useRef, useState } from "react";
import "./index.scss";
import { Col, Pagination, Row } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { Desktop, Mobile } from "src/layouts/break-point";
import ProductService from "src/services/API/Product";
import AppHSkeleton from "src/components/skeleton/AppHSkeleton";
import { useMediaQuery } from "react-responsive";
import { useSearchParams } from "react-router-dom";
import AppVSkeleton from "src/components/skeleton/AppVSkeleton";
import AppPullToRefresh from "src/layouts/pull-refresh/AppPullToRefresh";
import AppNavigation from "../../../../components/AppNavigation";
import FavoriteItem from "./FavoriteItem";
import { FavoriteItems } from "./Favorite.model";

const MyPageFavorite: FC = ({ ...props }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [listFavorite, setListFavorite] = useState<FavoriteItems[]>([]);
  const page = useRef(0);
  const totalCount = useRef(0);
  const numberOfPage = 6;
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });
  const appScroll = useRef<HTMLElement | undefined>(undefined);
  const [searchParam, setSearchParam] = useSearchParams();

  function getListFavorite(isLoading: boolean) {
    page.current = isLoading ? 0 : page.current;
    appScroll.current?.scrollTo({ top: 0, behavior: "smooth" });
    setLoading(isLoading);
    ProductService.getListFavorite(
      window.innerWidth > 768
        ? ((((Number(searchParam.get("currentPage")) || 1) - 1) *
            numberOfPage) as number)
        : page.current * numberOfPage,
      numberOfPage
    )
      .then((res) => {
        setLoading(false);
        totalCount.current = res.data.result.totalCount;

        setListFavorite(
          isLoading
            ? res.data.result.items
            : listFavorite.concat(res.data.result.items)
        );
        page.current += 1;
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const handleReload = () => {
    if (totalCount.current % 6 === 1 && isDesktop) {
      searchParam.set("currentPage", `${Math.floor(totalCount.current / 6)}`);
      setSearchParam(searchParam);
    } else if (!isDesktop) {
      setListFavorite([]);
      getListFavorite(true);
    } else getListFavorite(true);
  };

  useEffect(() => {
    getListFavorite(true);
  }, [searchParam]);

  useEffect(() => {
    const scroll = document.getElementById("appLayout") || undefined;
    appScroll.current = scroll;
  }, []);

  return (
    <AppPullToRefresh onRefresh={() => {getListFavorite(true);}}>
      <div className="ui-my-page-favorite">
        <Row gutter={[32, 32]} justify="center">
          <Col xs={24}>
            <AppNavigation route="/my-page" title="お気に入り一覧" />
          </Col>
        </Row>
        <Mobile>
          <div id="scrollableDiv">
            <InfiniteScroll
              dataLength={listFavorite.length}
              next={() => {
                getListFavorite(false);
              }}
              hasMore={
                listFavorite.length > 0 &&
                listFavorite.length < totalCount.current
              }
              loader={undefined}
              scrollableTarget="scrollableDiv"
            >
              <div className="ui-my-page-favorite__items">
                {loading ? (
                  <div className="favorite-skeleton">
                    {[...Array(3)].map((x, i) => (
                      <div key={i} className="favorite-skeleton__item">
                        <AppVSkeleton />
                      </div>
                    ))}
                  </div>
                ) : (
                  listFavorite?.length > 0 &&
                  listFavorite.map((item, index) => (
                    <div key={index} className="favorite-item">
                      <FavoriteItem
                        key={index}
                        item={item}
                        onReload={handleReload}
                      />
                    </div>
                  ))
                )}
              </div>
            </InfiniteScroll>
          </div>
        </Mobile>
        <Desktop>
          <div className="ui-my-page-favorite__items">
            {loading ? (
              <Row gutter={16}>
                {[...Array(6)].map((x, i) => (
                  <Col key={i} span={8}>
                    <AppHSkeleton />
                  </Col>
                ))}
              </Row>
            ) : (
              <div className="favorite-item">
                <Row gutter={[32, 32]}>
                  {listFavorite?.length > 0 &&
                    listFavorite.map((item, index) => (
                      <FavoriteItem
                        key={index}
                        item={item}
                        onReload={handleReload}
                      />
                    ))}
                </Row>
              </div>
            )}
          </div>
          {!loading && listFavorite && listFavorite.length > 0 && (
            <div className="ui-my-page-favorite__pagination">
              <Pagination
                current={Number(searchParam.get("currentPage")) || 1}
                pageSize={numberOfPage}
                total={totalCount.current}
                defaultCurrent={1}
                showSizeChanger={false}
                onChange={(value) => {
                  searchParam.set("currentPage", `${value}`);
                  setSearchParam(searchParam);
                }}
              />
            </div>
          )}
        </Desktop>
      </div>
    </AppPullToRefresh>
  );
};

export default MyPageFavorite;

MyPageFavorite.defaultProps = {};
