import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Col, Form, Row } from "antd";
import AppTextField from "@components/Form/AppTextField";
import {
  ALLOW_ONLY_CHARACTER_BANK_NAME,
  CHARACTER_SPECIALS,
} from "@configs/index";
import AppButton from "@components/AppButton";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@store/configureStore";
import { useTranslation } from "react-i18next";
import { setAppLoading, setTitle } from "src/store/State";
import { ReactComponent as Warning } from "@components/Icons/warning.svg";
import AppModal from "src/components/AppModal";
import { usePrompt } from "src/hooks/useBlocker";
import AppNavigation from "src/components/AppNavigation";
import { useLocation, useNavigate } from "react-router-dom";
import useHideBottomBar from "src/hooks/useHideBottomBar";
import CommonService from "src/services/Common";
import { ReactComponent as Success } from "@components/Icons/success.svg";
import "./index.scss";
import AppRadio from "src/components/Form/AppRadio";
import UserService from "src/services/API/User";
import _ from "lodash";
import { getIdError } from "src/helper";
import { injectHandleOk } from "src/App";
import { MessageType } from "src/models/common.model";
import AppSelect from "./components/AppSelect";
import { BankAccountInformation, BankInformation } from "../../model";
import { ReactComponent as InfoIcon } from "../../../../components/Icons/info-icon-2.svg";

const MyBankAccountEdit: FC = ({ ...props }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  useHideBottomBar(true);
  const { t } = useTranslation();
  const appScroll = useRef<HTMLElement | undefined>(undefined);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [bankCode, setBankCode] = useState<string>();
  const [modalWarningVisible, setModalWarningVisible] =
    useState<boolean>(false);
  const [warningEditModalVisible, setWarningEditModalVisible] =
    useState<boolean>(false);
  const [successEditModalVisible, setSuccessEditModalVisible] =
    useState<boolean>(false);
  const [bankInfo, setBankInfo] = useState<BankAccountInformation>();
  const [disableBranch, setDisableBranch] = useState<boolean>(
    !bankInfo?.bankName
  );
  const [listBanks, setListBanks] = useState<BankInformation[]>([]);
  const [listBranches, setListBranches] = useState<BankInformation[]>([]);
  const [listBranchesOrigin, setListBranchesOrigin] = useState<
    BankInformation[]
  >([]);
  const location = useLocation();
  const navigate = useNavigate();
  const routerTx = useRef();
  const blocker = useCallback((tx: any) => {
    routerTx.current = tx;
    setModalWarningVisible(true);
  }, []);
  const refSelectBranch = useRef<any>(null);
  const refSelectBank = useRef<any>(null);
  usePrompt(blocker, isDirty);

  const onValuesChange = () => {
    setIsDirty(true);
  };

  const currentPathname = useSelector(
    (state: RootState) => state.GlobalReducer.currentPathname
  );

  function handleBack() {
    if (!currentPathname?.length || currentPathname.length === 1) {
      navigate("/");
    } else {
      navigate((location.state as any)?.routeState || -1);
    }
  }

  async function getListBranches(bankCode: string) {
    try {
      dispatch(setAppLoading(true));
      const response = await UserService.getBranches(bankCode);
      setListBranches(response.data.result);
      setListBranchesOrigin(response.data.result);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setAppLoading(false));
    }
  }

  async function getBankInfo() {
    dispatch(setAppLoading(true));
    try {
      const response = await UserService.getBankInfo();
      setBankInfo(response.data.result);
      if (response.data.result.bankCode) {
        getListBranches(response.data.result.bankCode);
      }
      dispatch(setAppLoading(false));
    } catch (e) {
      console.log(e);
      dispatch(setAppLoading(false));
    }
  }

  async function getListBank() {
    dispatch(setAppLoading(true));
    try {
      const response = await UserService.getBanks();
      setListBanks(response.data.result);
      dispatch(setAppLoading(false));
    } catch (e) {
      console.log(e);
      dispatch(setAppLoading(false));
    }
  }

  useEffect(() => {
    getBankInfo();
    getListBank();
  }, []);

  useEffect(() => {
    if (bankInfo) {
      const clonedData = { ...bankInfo };
      clonedData.bankName = bankInfo?.bankName
        ? `${bankInfo?.bankCode}:${bankInfo?.bankName}`
        : "";
      clonedData.branchName = bankInfo?.branchName
        ? `${bankInfo?.branchCode}:${bankInfo?.branchName}`
        : "";
      clonedData.bankAccountType =
        bankInfo && bankInfo?.bankAccountType !== null
          ? bankInfo?.bankAccountType
          : 1;
      form.setFieldsValue(clonedData);
    }
  }, [bankInfo]);

  const onFinish = () => setWarningEditModalVisible(true);

  const handleSaveProfile = async () => {
    setIsDirty(false);
    const value = form.getFieldsValue();
    const clonedData = [...listBranchesOrigin];
    const selectedBranch = clonedData.filter(
      (data) => data.code === value.branchName.split(":")[0]
    )[0] as BankInformation;
    const formData: BankAccountInformation = {
      branchId: selectedBranch
        ? Number(selectedBranch.key)
        : Number(bankInfo?.branchId),
      bankAccountNumber: value.bankAccountNumber,
      bankAcountName: value.bankAccountName,
      bankAccountType: value.bankAccountType,
      token: bankInfo?.token || "",
    };
    dispatch(setAppLoading(true));
    try {
      const response = await UserService.updateBankAccount(formData);
      dispatch(setAppLoading(false));
      if (response) {
        setSuccessEditModalVisible(true);
      }
    } catch (e) {
      console.log(e);
      if (getIdError(e) === "M0156") {
        injectHandleOk(() => getBankInfo());
      }
    } finally {
      dispatch(setAppLoading(false));
      setWarningEditModalVisible(false);
      setIsDirty(false);
    }
  };

  useEffect(() => {
    if (bankCode) {
      getListBranches(bankCode);
    }
  }, [bankCode]);

  useEffect(() => {
    document.body.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    dispatch(setTitle(t("my.page.bank.account")));
    return () => {
      dispatch(setTitle(""));
    };
  }, []);

  return (
    <div className="ui-my-page-bank">
      <AppNavigation
        onClick={() => handleBack()}
        title={t("my.page.bank.account")}
      />
      <div className="ui-my-page-bank-edit">
        <AppModal
          visible={warningEditModalVisible}
          title="変更情報を保存します。よろしいですか？"
          content=""
          okText="はい"
          cancelText="いいえ"
          onCancel={() => setWarningEditModalVisible(false)}
          onOk={handleSaveProfile}
        />

        <AppModal
          icon={<Success />}
          visible={successEditModalVisible}
          title={t("editProduct.successEditModal.title")}
          content=""
          okText={t("editProduct.successEditModal.okText")}
          onOk={() => {
            setSuccessEditModalVisible(false);
            navigate("/my-page/bank-account");
            appScroll.current?.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />

        <AppModal
          visible={modalWarningVisible}
          icon={<Warning />}
          title="変更された情報は破棄されます。よろしいですか？"
          content=""
          okText="はい"
          cancelText="いいえ"
          onCancel={() => setModalWarningVisible(false)}
          onOk={() => {
            (routerTx.current as any).retry();
          }}
        />
        <div className="ui-my-page-bank-edit__form">
          <Row gutter={[32, 32]} justify="center">
            <Col xs={24}>
              <div className="ui-my-page-bank-edit__guideline">
                <span>
                  <InfoIcon />
                </span>
                <span>{t("my.page.bank.account.guideline")}</span>
              </div>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onValuesChange={onValuesChange}
              >
                <div className="ui-my-page-bank-edit__form-input">
                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <AppSelect
                        options={listBanks.map((bank) => ({
                          label: `${bank.key}:${bank.value}`,
                          value: `${bank.key}:${bank.value}`,
                        }))}
                        name="bankName"
                        label={t("my.page.bank.account.label.bank.name")}
                        placeholder=""
                        rules={[
                          {
                            required: true,
                            message: t(
                              "my.page.bank.account.label.bank.name.required"
                            ),
                          },
                        ]}
                        onChange={(value) => {
                          setBankCode(value.split(":")[0]);
                          form.setFieldsValue({ branchName: null });
                        }}
                        ref={refSelectBank}
                        onDropdownVisibleChange={(visible: boolean) => {
                          if (!visible) {
                            refSelectBank.current.blur();
                          }
                        }}
                        showSearch
                        allowClear
                        onClear={() => {
                          setDisableBranch(true);
                          form.setFieldsValue({ bankName: null });
                        }}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <AppSelect
                        name="branchName"
                        label={t("my.page.bank.account.label.branch.name")}
                        placeholder=""
                        rules={[
                          {
                            required: true,
                            message: t(
                              "my.page.bank.account.label.branch.name.required"
                            ),
                          },
                        ]}
                        options={listBranches.map((branch) => ({
                          label: `${branch.code}:${branch.value}`,
                          value: `${branch.code}:${branch.value}`,
                        }))}
                        showSearch
                        disabled={
                          disableBranch && !form.getFieldValue("bankName")
                        }
                        allowClear
                        ref={refSelectBranch}
                        onDropdownVisibleChange={(visible: boolean) => {
                          if (!visible) {
                            refSelectBranch.current.blur();
                          }
                        }}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col xs={16} md={10} lg={6}>
                      <AppRadio
                        hasNotDefaultValue={true}
                        label={t("my.page.bank.account.label.account.type")}
                        options={[
                          {
                            label: t(
                              "my.page.bank.account.label.account.type.normal"
                            ),
                            value: 1,
                          },
                          {
                            label: t(
                              "my.page.bank.account.label.account.type.temporarily"
                            ),
                            value: 2,
                          },
                        ]}
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                        name="bankAccountType"
                        type="normal"
                      ></AppRadio>
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <AppTextField
                        type="number"
                        onlyInputNumber="true"
                        pattern="[0-9]*"
                        name="bankAccountNumber"
                        label={t("my.page.bank.account.label.account.number")}
                        placeholder=""
                        formgroup={form}
                        maxLength={7}
                        extra={t(
                          "my.page.bank.account.label.account.number.guideline"
                        )}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "my.page.bank.account.label.account.number.required"
                            ),
                          },
                          {
                            pattern: /^[0-9]{7}$/,
                            message: t(
                              "my.page.bank.account.label.account.number.validate"
                            ),
                          },
                        ]}
                        onKeyPress={(event) => {
                          if (CHARACTER_SPECIALS.includes(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onWheel={(e) => (e.target as any).blur()}
                        onKeyDown={(event) => {
                          if (
                            event.key === "e" ||
                            event.key === "E" ||
                            event.key === "ArrowUp" ||
                            event.key === "ArrowDown"
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                          if (
                            e.clipboardData.getData("text").includes("-") ||
                            e.clipboardData.getData("text").length > 11
                          ) {
                            return false;
                          }
                          form.setFieldsValue({
                            phoneNumber: e.clipboardData.getData("text"),
                          });
                        }}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <AppTextField
                        name="bankAccountName"
                        label={t("my.page.bank.account.label.account.name")}
                        placeholder=""
                        rules={[
                          {
                            required: true,
                            message: t(
                              "my.page.bank.account.label.account.name.required"
                            ),
                          },
                          {
                            validator(_: any, value: any) {
                              if (
                                value &&
                                value.trim().length > 0 &&
                                ALLOW_ONLY_CHARACTER_BANK_NAME.test(value)
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                t(
                                  "my.page.bank.account.label.account.name.validate"
                                )
                              );
                            },
                          },
                        ]}
                        maxLength={255}
                        formgroup={form}
                        extra={t(
                          "my.page.bank.account.label.account.name.guideline.1"
                        )}
                      />
                    </Col>
                  </Row>
                  <div className="ui-my-page-bank-edit__guideline-name">
                    <span>詳細は</span>
                    <span style={{ color: "#FF671E" }}>
                      {!(window as any).ReactNativeWebView ? (
                        <a
                          href="https://mashipla.com/faq/#i-8"
                          target="_blank"
                          rel="noreferrer"
                        >
                          よくある質問
                        </a>
                      ) : (
                        <span
                          style={{ color: "#FF671E", cursor: "pointer" }}
                          onClick={() => {
                            const message = {
                              type: MessageType.MODAL,
                              data: "https://mashipla.com/faq/#i-8",
                            };
                            (window as any).ReactNativeWebView?.postMessage(
                              JSON.stringify(message)
                            );
                          }}
                        >
                          よくある質問
                        </span>
                      )}
                    </span>

                    <span>をご覧下さい。</span>
                  </div>
                </div>
                <div className="ui-my-page-bank-edit__action">
                  <Row justify="center">
                    <Col xs={24} md={10}>
                      <AppButton
                        buttontype="primary"
                        htmlType="submit"
                        onClick={() => {
                          CommonService.scrollToError();
                        }}
                      >
                        完了{" "}
                      </AppButton>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default MyBankAccountEdit;

MyBankAccountEdit.defaultProps = {};
