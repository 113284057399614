import React, { FC, memo, useEffect, useMemo, useState } from "react";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AppNavigation from "@components/AppNavigation";
import AppButton from "@components/AppButton";
import { ReactComponent as SuccessFull } from "@components/Icons/success.svg";

import { SuccessType } from "@pages/reset-password/model";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store/configureStore";
import { setTitle } from "src/store/State";
import { MessageType } from "src/models/common.model";
import {
  CollapseData,
  SignUpFormValue,
  SignUpStatusName,
} from "../../model";
import Collapse from "../Collapse";

interface Props {
  type?: SuccessType;
  value?: SignUpFormValue;
}

const Success: FC<Props> = memo(({ ...props }) => {
  const { type, value } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [clientUrl, setClientUrl] = useState<any>();
  const [webDomain, setWebDomain] = useState<any>();
  const [urlFAQ, setUrlFAQ] = useState<any>();

  const systemConfig = useSelector(
    (state: RootState) => state.GlobalReducer.systemConfig
  );
  const isApp = (window as any).ReactNativeWebView;

  useEffect(() => {
    setClientUrl(systemConfig.find((item) => item.key === "ClientUrl"));
    setUrlFAQ(systemConfig.find((item) => item.key === "FAQ"));
    // setWebDomain(systemConfig.find((item) => item.key === "WebDomain"));
    setWebDomain('info@mashipla.com');
  }, [systemConfig]);

  const items: CollapseData[] = useMemo(
    () => [
      // {
      //   title: t("signup.success.collapse1.title"),
      //   content: (
      //     <div>
      //       {t("signup.success.collapse1.text1")}
      //       <span
      //         onClick={() => navigate("/reset-password")}
      //         style={{ color: "#FF671E", cursor: "pointer" }}
      //       >
      //         {t("signup.success.collapse1.text2")}
      //       </span>
      //       {t("signup.success.collapse1.text3")}
      //     </div>
      //   ),
      // },
      {
        title: t("signup.success.collapse2.title"),
        content: (
          <div>
            <span style={{ color: "#FF671E" }}>
              {!isApp ? (
                  webDomain
              ) : (
                <span
                  onClick={() => {
                    if (clientUrl) {
                      const message = {
                        type: MessageType.MODAL,
                        data: clientUrl?.value,
                      };
                      (window as any).ReactNativeWebView.postMessage(
                        JSON.stringify(message)
                      );
                    }
                  }}
                >
                  {webDomain}
                </span>
              )}
            </span>
            {t("signup.success.collapse2.text2")}
          </div>
        ),
      },
      {
        title: t("signup.success.collapse3.title"),
        content: (
          <div>
            {t("signup.success.collapse3.text1")}
            <span style={{ color: "#FF671E" }}>
              {!isApp ? (
                <a href={urlFAQ?.value} target="_blank" rel="noreferrer">
                  {t("signup.success.collapse3.text2")}
                </a>
              ) : (
                <span
                  onClick={() => {
                    if (urlFAQ) {
                      const message = {
                        type: MessageType.MODAL,
                        data: urlFAQ?.value,
                      };
                      (window as any).ReactNativeWebView.postMessage(
                        JSON.stringify(message)
                      );
                    }
                  }}
                >
                  {t("signup.success.collapse3.text2")}
                </span>
              )}
            </span>
            {t("signup.success.collapse3.text3")}
          </div>
        ),
      },
      {
        title: t("signup.success.collapse4.title"),
        content: <div>{t("signup.success.collapse4.text")}</div>,
      },
    ],
    [urlFAQ, clientUrl]
  );

  useEffect(() => {
    dispatch(setTitle(SignUpStatusName.SIGNUP_SENT_EMAIL));
    const scroll = document.getElementById("appLayout") as HTMLDivElement;
    if (scroll) {
      scroll.scrollTo({ top: 0, behavior: "smooth" });
    }
    return () => {
      dispatch(setTitle(""));
    };
  }, []);

  return (
    <div className="ui-sign-up-success">
      <div className="ui-sign-up-success__pageTitle">
        <Row gutter={[32, 32]} justify="center">
          <Col xs={24}>
            <AppNavigation
              title={t(
                type === SuccessType.SIGN_UP
                  ? "signup.success.pageTitle"
                  : "resetPassword.success.pageTitle"
              )}
            />
          </Col>
        </Row>
      </div>
      <div className="ui-sign-up-success__content">
        <Row gutter={[32, 32]} justify="center">
          <Col xs={24} md={12} xl={24}>
            <div className="ui-sign-up-success__icon">
              <SuccessFull />
            </div>
            <h5 className="ui-sign-up-success__title">
              {t("signup.success.title")}
            </h5>
            <p className="ui-sign-up-success__description">
              「{value?.emailAddress}」{t("signup.success.description")}
            </p>
            {items &&
              items.length &&
              items.map(({ title, content }, index) => (
                <Collapse key={index} title={title} content={content} />
              ))}
          </Col>
        </Row>
      </div>

      <div className="ui-sign-up-success__action">
        <Row gutter={[48, 48]} justify="center">
          <Col xs={24} md={12} xl={8}>
            <AppButton buttontype="primary" onClick={() => navigate("/login")}>
              {type === SuccessType.SIGN_UP
                ? t("signup.success.signin")
                : t("signup.success.exit")}
            </AppButton>
          </Col>
        </Row>
      </div>
    </div>
  );
});

export default Success;

Success.defaultProps = { type: SuccessType.SIGN_UP };
