/* eslint-disable no-plusplus */
import React, { FC, useEffect, useRef } from "react";
import "./styles.scss";
import ProductService from "src/services/API/Product";
import { useDispatch } from "react-redux";
import { setAppLoading, setError } from "src/store/State";

interface MediaProps {
  onSelect: any;
  label: string;
  type: "video" | "image" | "gallery";
  productId?: string;
  useCase: "EDIT" | "CREATE";
  listMedia: any[];
}

const MediaChooser: FC<MediaProps> = ({ ...props }) => {
  const dispatch = useDispatch();

  const inputRef = useRef(null);

  function validateMedia(files: FileList) {
    let totalSize = 0;
    const arrayFiles = Array.from(files);
    let totalImage = 0;
    let totalVideo = 0;

    for (let index = 0; index < arrayFiles.length; index++) {
      const element = arrayFiles[index];
      const size = element.size / 1024 ** 2;
      totalSize += size;
      if (element.type.includes("image")) {
        totalImage++;
        if (size > 5) {
          dispatch(setError({ message: "M0033" }));
          return false;
        }
      }

      if (element.type.includes("video")) {
        if (size > 100) {
          dispatch(setError({ message: "M0033" }));
          return false;
        }
        totalVideo++;
      }
    }

    if (totalSize > 150) {
      dispatch(setError({ message: "M0033" }));
      return false;
    }

    if (totalImage > 10 || totalVideo > 1) {
      dispatch(setError({ message: "M0032" }));
      return false;
    }

    return true;
  }

  function handleMediaChange(event: any) {
    const { files } = event.target;

    if (!validateMedia(files)) {
      return;
    }

    dispatch(setAppLoading(true));
    ProductService.uploadImages(files)
      .then((res) => {
        dispatch(setAppLoading(false));
        props.onSelect(files, res.data.result, props.type);
        if (inputRef.current) {
          (inputRef.current as any).value = null;
        }
      })
      .catch((err) => {
        dispatch(setAppLoading(false));
        if (inputRef.current) {
          (inputRef.current as any).value = null;
        }
      });
  }

  function sendMessage(type: String) {
    try {
      const message = {
        type: "UPLOAD_MEDIA",
        data: type,
        listMedia: props.listMedia,
      };
      (window as any).ReactNativeWebView.postMessage(JSON.stringify(message));
    } catch (e) {
      console.log(`Send message call to app fail ${type}`);
    }
  }

  // eslint-disable-next-line consistent-return

  function onSelectMedia(type: string) {
    if ((window as any).ReactNativeWebView) {
      sendMessage(type);
      return;
    }
    (inputRef.current as any).click();
  }

  //   // const listType = files.map((x) => x.type);
  //   // const listImage = listType.filter((f) => f === "image");

  //   // // validate at least 1 image
  //   // if (get(listImage, "length") <= 0) {
  //   //   dispatch(setError({ message: "C001" }));
  //   //   return false;
  //   // }

  //   // // validate at max 10 image
  //   // if (get(listImage, "length") > 10) {
  //   //   dispatch(setError({ message: "C004" }));
  //   //   return false;
  //   // }

  //   // const listVideo = listType.filter((f) => f === "video");
  //   // // validate at max 1 video
  //   // if (get(listVideo, "length") > 1) {
  //   //   dispatch(setError({ message: "C004" }));
  //   //   return false;
  //   // }

  //   return true;
  // }

  function getMediaType() {
    switch (props.type) {
      case "video":
        return "video/mp4,video/x-m4v,video/*";

      case "image":
        return "image/png, image/gif, image/jpeg, image/jpg, image/heic";

      default:
        return "image/png, image/gif, image/heic, image/jpeg,video/mp4,video/x-m4v,video/*";
    }
  }

  return (
    <>
      <input
        type="file"
        capture={props.type === "gallery" ? undefined : "environment"}
        multiple
        accept={getMediaType()}
        id="videoInput"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={handleMediaChange}
      />
      <div className="item-chooser" onClick={() => onSelectMedia(props.type)}>
        <img
          className="item-chooser__img"
          src={`/assets/icons/${props.type}.svg`}
          alt=""
        />
        <div className="item-chooser__title text-body-3 text-title bold">
          {props.label}
        </div>
      </div>
    </>
  );
};

export default MediaChooser;
