import {
  addDoc,
  setDoc,
  deleteDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
  increment, getDoc
} from 'firebase/firestore';
import { db } from 'src/firebase';
import { Collection, Message, Room } from '@models/chat.model';
import AppHttpClient from '@core/http';

const isRoomExisted = async (roomId: string) => {
  try {
    const querySnapshot = await getDocs(query(collection(db, Collection.ROOMS), where("roomId", "==", roomId)));
    return querySnapshot.docs.some(doc => doc.exists());
  } catch (e) {
    console.log(e);
  }
};

const createRoom = async (room: Room) => {
  try {
    await addDoc(collection(db, Collection.ROOMS), room);
  } catch (e) {
    console.log(e);
  }
};

const joinRoom = async (roomIndex: string, userId: number) => {
  try {
    await updateDoc(doc(db, Collection.ROOMS, roomIndex), {
      onlineMembers: arrayUnion(userId)
    });
  } catch (e) {
    console.log(e);
  }
};

const leaveRoom = async (roomIndex: string, userId: number) => {
  try {
    await updateDoc(doc(db, Collection.ROOMS, roomIndex), {
      onlineMembers: arrayRemove(userId)
    });
  } catch (e) {
    console.log(e);
  }
};

const createMessage = async (message: Message) => {
  try {
    await addDoc(collection(db, Collection.MESSAGES), message);
  } catch (e) {
    console.log(e);
  }
};

const increaseUnreadMessage = async (roomIndex: string, userId: number) => {
  try {
    await updateDoc(doc(db, Collection.ROOMS, roomIndex), {
      [`unreadMessage.${userId}`]: increment(1)
    });
  } catch (e) {
    console.log(e);
  }
};

const resetUnreadMessage = async (roomIndex: string, userId: number) => {
  try {
    await updateDoc(doc(db, Collection.ROOMS, roomIndex), {
      [`unreadMessage.${userId}`]: 0
    });
  } catch (e) {
    console.log(e);
  }
};

const getDocumentIndex = async (specifiedCollection: Collection, path: string, value: string | number) => {
  try {
    let documentIndex = "";
    const documentRef = await getDocs(query(collection(db, specifiedCollection), where(path, "==", value)));
    documentRef.forEach(doc => {
      documentIndex = doc.id;
    });
    return documentIndex;
  } catch (e) {
    console.log(e);
  }
};

const updateRoom = async (roomIndex: string) => {
  try {
    await updateDoc(doc(db, Collection.ROOMS, roomIndex), {
      totalMessage: increment(1),
      lastUpdatedAt: new Date().getTime()
    });
  } catch (e) {
    console.log(e);
  }
};

const deleteRoom = async (roomIndex: string) => {
  try {
    await deleteDoc(doc(db, Collection.ROOMS, roomIndex));
  } catch (e) {
    console.log(e);
  }
};

const deleteMessage = async (messageIndex: string, roomIndex: string, userId: number, isReceiverOnline: boolean, unreadReceiverMessageQuantity: number) => {
  try {
    await deleteDoc(doc(db, Collection.MESSAGES, messageIndex));
    await updateDoc(doc(db, Collection.ROOMS, roomIndex), {
      totalMessage: increment(-1),
      [`unreadMessage.${userId}`]: (!isReceiverOnline && unreadReceiverMessageQuantity) ? increment(-1) : 0
    });
  } catch (e) {
    console.log(e);
  }
};

const updateRoomInformation = async (roomIndex: string, data: { roomTitle: string, roomImage: string }) => {
  try {
    await updateDoc(doc(db, Collection.ROOMS, roomIndex), {
      roomTitle: data.roomTitle,
      roomImage: data.roomImage
    });
  } catch (e) {
    console.log(e);
  }
};

const updateRoomMember = async (roomIndex: string, oldData: { id: number, name: string, companyName: string }, newData: { id: number, name: string, companyName: string }) => {
  try {
    await updateDoc(doc(db, Collection.ROOMS, roomIndex), {
      members: arrayRemove(oldData)
    });
    await updateDoc(doc(db, Collection.ROOMS, roomIndex), {
      members: arrayUnion(newData)
    });
  } catch (e) {
    console.log(e);
  }
};

const addUser = async (userId: number, deviceToken: string) => {
  try {
    await setDoc(doc(db, Collection.USERS, String(userId)), {
      userId,
      tokens: [deviceToken]
    });
  } catch (e) {
    console.log(e);
  }
};

const updateUserToken = async (userIndex: string, deviceToken: string) => {
  try {
    await updateDoc(doc(db, Collection.USERS, userIndex), {
      tokens: arrayUnion(deviceToken)
    });
  } catch (e) {
    console.log(e);
  }
};

const deleteUserToken = async (userIndex: string, deviceToken: string) => {
  try {
    await updateDoc(doc(db, Collection.USERS, userIndex), {
      tokens: arrayRemove(deviceToken)
    });
  } catch (e) {
    console.log(e);
  }
};

const isUserExisted = async (userId: number) => {
  try {
    const querySnapshot = await getDocs(query(collection(db, Collection.USERS), where("userId", "==", userId)));
    return querySnapshot.docs.some(doc => doc.exists());
  } catch (e) {
    console.log(e);
  }
};

const isJoinAbleRoom = async (roomIndex: string, userId: number) => {
  try {
    const roomRef = doc(db, Collection.ROOMS, roomIndex as string);
    const room = await getDoc(roomRef);
    const roomData = room.data() as Room;
    return roomData.memberIds.includes(userId);
  } catch (e) {
    console.log(e);
  }
};

const uploadMedias = (medias: any) => {
  const form = new FormData();
  // eslint-disable-next-line no-restricted-syntax
  for (const file of medias) {
    form.append('Files', file);
    form.append('Types', file.type || 'image/heic');
  }
  return AppHttpClient.postFormData('/api/services/app/ChatService/UploadMedia', form);
};

const uploadDocuments = (documents: any) => {
  const form = new FormData();
  // eslint-disable-next-line no-restricted-syntax
  for (const file of documents) {
    form.append('File', file);
  }
  return AppHttpClient.postFormData('/api/services/app/ChatService/UploadFile', form);
};

const ChatService = {
  isRoomExisted,
  createRoom,
  joinRoom,
  leaveRoom,
  createMessage,
  getDocumentIndex,
  increaseUnreadMessage,
  resetUnreadMessage,
  uploadMedias,
  uploadDocuments,
  updateRoom,
  deleteRoom,
  deleteMessage,
  updateRoomInformation,
  updateRoomMember,
  addUser,
  updateUserToken,
  deleteUserToken,
  isUserExisted,
  isJoinAbleRoom
};

export default ChatService;
