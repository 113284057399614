import "./styles.scss";
import React, { FC, useEffect, useState } from "react";
import { Form, Popover, Select, SelectProps } from "antd";
import { LabeledValue } from "antd/lib/select";
import classnames from "classnames";
import { ReactComponent as ArrowDown } from "@components/Icons/arrow-down.svg";
// import CategorySelectForm from "../core/category-select";
import { useSelector } from "react-redux";
import { RootState } from "src/store/configureStore";
import ProductService from "src/services/API/Product";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Desktop, Mobile } from "src/layouts/break-point";
import CategorySelectRadioForm from "../core/category-select-radio/index";
import AppBottomSheet from "../AppBottomSheet";

interface Props extends SelectProps {
  label?: string;
  rules?: object[];
  name: string;
  options: LabeledValue[];
  value?: any;
  onChange: (value: any) => void;
}

const { Option } = Select;

const CustomSelect: FC<Props> = ({ ...props }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });

  const { t: translate } = useTranslation();

  const { placeholder, className } = props;
  const listCategory = useSelector(
    (state: RootState) => state.ProductReducer.listCategory
  );
  const [options, setOptions] = useState<LabeledValue[]>([]);
  const [valueSelect, setValueSelect] = useState();
  const [showCategory, setShowCategory] = useState(false);

  useEffect(() => {
    setValueSelect(props.value);

    setOptions([
      {
        label:
          ProductService.getGroupCategoryString(listCategory, props.value) ||
          "",
        value: props.value,
      },
    ]);
  }, [props.value]);

  function setValueForCategoryForm(listFilter: any) {
    if (get(listFilter, "[0].value")) {
      setOptions([
        {
          label:
            ProductService.getGroupCategoryString(
              listCategory,
              get(listFilter, "[0].value")
            ) || "",
          value: get(listFilter, "[0].value") || 0,
        },
      ]);

      props.onChange(get(listFilter, "[0].value"));
      setValueSelect(get(listFilter, "[0].value"));
    }
    if (!isDesktop) {
      setShowCategory(false);
    }
  }

  const handleVisibleChange = (newVisible: boolean) => {
    setShowCategory(newVisible);
  };

  return (
    <>
      <Mobile>
        {showCategory && (
          <AppBottomSheet
            title={translate("product.detail.category")}
            visible={showCategory}
            onClose={() => {
              setShowCategory(false);
            }}
            body={
              <CategorySelectRadioForm
                type="normal"
                value={[valueSelect || 0]}
                onSubmit={(listFilter: any[]) => {
                  setValueForCategoryForm(listFilter);
                  setShowCategory(false);
                }}
              />
            }
            height="550"
          ></AppBottomSheet>
        )}
        <div onClick={() => setShowCategory(true)}>
          <Select
            placeholder={placeholder}
            className={classnames("app-select", className)}
            dropdownClassName="ant-select-custom-dropdown"
            suffixIcon={<ArrowDown />}
            value={valueSelect}
          >
            {options?.map((option: LabeledValue, index: number) => (
              <Option key={index} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </div>
      </Mobile>
      <Desktop>
        <Popover
          content={
            <div>
              <div className="form-header">
                <div className="text-body-2 bold">カテゴリ</div>
                <div
                  className="button-cancel"
                  onClick={() => {
                    setShowCategory(false);
                  }}
                >
                  完了
                </div>
              </div>

              <CategorySelectRadioForm
                type="normal"
                value={[valueSelect || 0]}
                onSubmit={(listFilter: any[]) => {
                  setValueForCategoryForm(listFilter);
                }}
              />
            </div>
          }
          overlayClassName="custom-popup-select-category"
          title=""
          trigger="click"
          visible={showCategory}
          placement="bottom"
          onVisibleChange={handleVisibleChange}
          getPopupContainer={() => document.getElementById("app-category-select") as HTMLDivElement}
        >
          <div id="app-category-select" onClick={() => setShowCategory(true)}>
            <Select
              placeholder={placeholder}
              className={classnames("app-select", className)}
              dropdownClassName="ant-select-custom-dropdown"
              suffixIcon={<ArrowDown />}
              value={valueSelect}
            >
              {options?.map((option: LabeledValue, index: number) => (
                <Option key={index} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </div>
        </Popover>
      </Desktop>
    </>
  );
};

const AppSelectCategory: FC<Props> = ({ ...props }) => {
  const { name, label, rules } = props;

  return (
    <Form.Item
      labelCol={{ span: 24 }}
      name={name}
      label={label}
      rules={rules}
      validateTrigger={["onBlur", "onChange"]}
    >
      <CustomSelect {...props}></CustomSelect>
    </Form.Item>
  );
};

export default AppSelectCategory;
