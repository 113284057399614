import { ColorModel, ColorPalette, ThemeMode } from "./theme.config";

class ThemeService {
    theme = ThemeMode.LIGHT;

    constructor() {
        this.theme = ThemeMode.LIGHT;
    }

    setTheme = (theme: ThemeMode) => {
        this.theme = theme;
        const doc = document.documentElement;
        ColorPalette.forEach((color: ColorModel, index) => {
            doc.style.setProperty(color.key, color[this.theme] || '');
        });

    };
}

const themeService = new ThemeService();

export default themeService;
