import React, { FC } from "react";

interface Props {
  active?: boolean;
  color?: string;
  width?: string
}

const TransactionCompleted: FC<Props> = (({ ...props }) => {
  const { active, color, width } = props;
  const fill =  active ? "#FF671E" : ( color || "#666666");
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none" style={{ width: width }}>
      <g clipPath="url(#clip0_1944_13834)">
        <path d="M39.0328 5.39869L33.6868 10.7456L31.3658 8.42461C31.3024 8.35892 31.2266 8.30651 31.1427 8.27044C31.0589 8.23437 30.9687 8.21536 30.8774 8.21452C30.7861 8.21369 30.6956 8.23104 30.6111 8.26557C30.5266 8.3001 30.4498 8.35111 30.3852 8.41563C30.3206 8.48015 30.2695 8.55689 30.2349 8.64136C30.2003 8.72584 30.1829 8.81636 30.1836 8.90764C30.1844 8.99893 30.2033 9.08915 30.2393 9.17304C30.2753 9.25694 30.3276 9.33283 30.3932 9.39628L33.201 12.204C33.2647 12.268 33.3404 12.3188 33.4238 12.3534C33.5071 12.388 33.5965 12.4058 33.6868 12.4058C33.7771 12.4058 33.8665 12.388 33.9499 12.3534C34.0332 12.3188 34.1089 12.268 34.1727 12.204L40.0054 6.37128C40.0693 6.30742 40.1199 6.2316 40.1545 6.14816C40.189 6.06473 40.2068 5.9753 40.2068 5.88499C40.2068 5.79467 40.189 5.70524 40.1545 5.62181C40.1199 5.53837 40.0693 5.46255 40.0054 5.39869C39.9415 5.33483 39.8657 5.28418 39.7823 5.24962C39.6988 5.21505 39.6094 5.19727 39.5191 5.19727C39.4288 5.19727 39.3394 5.21505 39.2559 5.24962C39.1725 5.28418 39.0967 5.33483 39.0328 5.39869Z" fill={fill}/>
        <path d="M35.2004 0C33.4599 0 31.7585 0.516111 30.3114 1.48307C28.8642 2.45002 27.7363 3.82439 27.0703 5.43238C26.4042 7.04037 26.2299 8.80976 26.5695 10.5168C26.909 12.2238 27.7472 13.7918 28.9779 15.0225C30.2086 16.2532 31.7766 17.0914 33.4836 17.4309C35.1906 17.7705 36.96 17.5962 38.568 16.9301C40.176 16.2641 41.5504 15.1362 42.5173 13.689C43.4843 12.2419 44.0004 10.5405 44.0004 8.8C43.9975 6.46699 43.0694 4.23036 41.4197 2.58067C39.77 0.930985 37.5334 0.00291081 35.2004 0V0ZM35.2004 16.225C33.7319 16.225 32.2963 15.7895 31.0753 14.9737C29.8542 14.1578 28.9026 12.9982 28.3406 11.6414C27.7786 10.2847 27.6316 8.79176 27.9181 7.35145C28.2046 5.91114 28.9117 4.58814 29.9501 3.54973C30.9885 2.51133 32.3115 1.80416 33.7518 1.51767C35.1922 1.23117 36.6851 1.37821 38.0418 1.94019C39.3986 2.50217 40.5582 3.45385 41.3741 4.67489C42.1899 5.89592 42.6254 7.33147 42.6254 8.8C42.6227 10.7684 41.8396 12.6554 40.4477 14.0473C39.0558 15.4392 37.1688 16.2223 35.2004 16.225Z" fill={fill}/>
        <path d="M35.1853 18.7137C35.003 18.7137 34.8281 18.7861 34.6992 18.915C34.5703 19.044 34.4978 19.2188 34.4978 19.4012V42.6258H1.375V9.50391H12.4932V14.7665C12.4932 14.9488 12.5657 15.1237 12.6946 15.2526C12.8235 15.3816 12.9984 15.454 13.1807 15.454C13.3631 15.454 13.538 15.3816 13.6669 15.2526C13.7958 15.1237 13.8682 14.9488 13.8682 14.7665V9.50391H22.0046V18.4011H13.8682V17.2534C13.8682 17.0711 13.7958 16.8962 13.6669 16.7673C13.538 16.6383 13.3631 16.5659 13.1807 16.5659C12.9984 16.5659 12.8235 16.6383 12.6946 16.7673C12.5657 16.8962 12.4932 17.0711 12.4932 17.2534V19.0867C12.4932 19.2691 12.5657 19.4439 12.6946 19.5729C12.8235 19.7018 12.9984 19.7742 13.1807 19.7742H22.6921C22.8744 19.7742 23.0493 19.7018 23.1782 19.5729C23.3071 19.4439 23.3796 19.2691 23.3796 19.0867V9.50391H24.7711C24.9534 9.50391 25.1283 9.43147 25.2572 9.30254C25.3861 9.17361 25.4586 8.99874 25.4586 8.81641C25.4586 8.63407 25.3861 8.4592 25.2572 8.33027C25.1283 8.20134 24.9534 8.12891 24.7711 8.12891H0.6875C0.505164 8.12891 0.330295 8.20134 0.201364 8.33027C0.0724328 8.4592 0 8.63407 0 8.81641L0 43.3133C0 43.4957 0.0724328 43.6705 0.201364 43.7995C0.330295 43.9284 0.505164 44.0008 0.6875 44.0008H35.1853C35.3677 44.0008 35.5425 43.9284 35.6715 43.7995C35.8004 43.6705 35.8728 43.4957 35.8728 43.3133V19.4012C35.8728 19.2188 35.8004 19.044 35.6715 18.915C35.5425 18.7861 35.3677 18.7137 35.1853 18.7137Z" fill={fill}/>
      </g>
      <defs>
        <clipPath id="clip0_1944_13834">
          <rect width="44" height="44" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
});

export default TransactionCompleted;
