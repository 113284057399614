import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Col, Pagination, Row } from "antd";
import {
  ProductHistory,
  ProductTransactionStatus,
} from "src/models/product.model";
import { PATH_NAME } from "src/configs";
import ProductService from "src/services/API/Product";
import InfiniteScroll from "react-infinite-scroll-component";
import AppVSkeleton from "src/components/skeleton/AppVSkeleton";
import { useTranslation } from "react-i18next";
import useHideBottomBar from "src/hooks/useHideBottomBar";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Desktop, Mobile } from "src/layouts/break-point";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/configureStore";
import { getNoticeTransactionDashboard } from "src/store/MyPage";
import { usePrompt } from "src/hooks/useBlocker";
import AppPullToRefresh from "src/layouts/pull-refresh/AppPullToRefresh";
import {
  PurchaseHistorySearchParams,
  PURCHASE_HISTORY_TYPE,
} from "../../model";
import Tab from "../PurchaseHistory/Tab";
import SaleHistoryItem from "./SaleHistoryItem";

const SaleHistoryList: FC = ({ ...props }) => {
  useHideBottomBar(false);

  const [searchParam, setSearchParam] = useSearchParams();

  const [saleList, setSaleList] = useState<ProductHistory[]>([]);

  const { t: translate } = useTranslation();

  const page = useRef(0);

  const totalCount = useRef(0);

  const numberOfPage = 20;

  const numberOfPagePC = 5;

  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>();

  const appScroll = useRef<HTMLElement | undefined>(undefined);

  const config = [
    { label: translate("my.page.tab.1"), value: 0 },
    { label: translate("my.page.tab.3"), value: 2 },
    { label: translate("my.page.tab.2"), value: 1 },
  ];

  // const routerTx = useRef();

  // const blocker = useCallback((tx: any) => {
  //   routerTx.current = tx;
  //   if (searchParam.get("tab") === "0") {
  //     // eslint-disable-next-line @typescript-eslint/no-use-before-define
  //     getListHistory(0, true);
  //   } else {
  //     (routerTx.current as any).retry();
  //   }
  // }, []);

  // usePrompt(blocker, true);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [currentValue, setCurrentValue] = useState<PURCHASE_HISTORY_TYPE>(
    PURCHASE_HISTORY_TYPE.TRADING
  );

  function handleWhenClickStatus(
    status: ProductTransactionStatus,
    item: ProductHistory
  ) {
    switch (status) {
      case ProductTransactionStatus.PAYMENT_COMPLETE:
      case ProductTransactionStatus.IN_PAYMENT:
      case ProductTransactionStatus.IN_CONTACT:
      case ProductTransactionStatus.DONE:
      case ProductTransactionStatus.IN_REQUEST:
        navigate(`/my-page/sales-history/${item.id}`);
        break;
      case ProductTransactionStatus.PUBLISH:
      case ProductTransactionStatus.UNPUBLISH:
        navigate(`/product-detail/${item.productId}`, {
          state: { backUrl: `${location.pathname}${location.search}` },
        });
        break;
      default:
        navigate(`/my-page/sales-history/${item.id}`);
        break;
    }
  }

  function getListHistory(value: PURCHASE_HISTORY_TYPE, isLoading: boolean) {
    page.current = isLoading ? 0 : page.current;
    setLoading(isLoading);
    ProductService.getSaleHistory(
      window.innerWidth > 768
        ? ((((Number(searchParam.get("currentPage")) || 1) - 1) *
            numberOfPagePC) as number)
        : page.current * numberOfPage,
      window.innerWidth > 768 ? numberOfPagePC : numberOfPage,
      value
    )
      .then((res) => {
        setLoading(false);
        totalCount.current = res.data.result.totalCount;
        if (value === PURCHASE_HISTORY_TYPE.UNPUBLISH) {
          res.data.result.items.forEach((element: ProductHistory) => {
            element.status = 200 + element.productStatus;
          });
        }
        setSaleList(
          isLoading
            ? res.data.result.items
            : saleList.concat(res.data.result.items)
        );
        page.current += 1;
        if (value === PURCHASE_HISTORY_TYPE.TRADING) {
          dispatch(getNoticeTransactionDashboard());
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    appScroll.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, [saleList]);

  const handleTabChange = (value: PURCHASE_HISTORY_TYPE) => {
    if (!loading) {
      setCurrentValue(value);
      searchParam.set("tab", `${value}`);
      if (window.innerWidth > 768) {
        searchParam.set("currentPage", "1");
      }
      setSearchParam(searchParam);
    }
  };

  const onPageChange = (page: number) => {
    searchParam.set("currentPage", `${page}`);
    setSearchParam(searchParam);
  };

  useEffect(() => {
    if (
      searchParam.get("tab") &&
      Number(searchParam.get("tab")) !== currentValue
    ) {
      setCurrentValue(Number(searchParam.get("tab")) as PURCHASE_HISTORY_TYPE);
    }
    getListHistory(
      searchParam.get("tab") ? Number(searchParam.get("tab")) : currentValue,
      true
    );
  }, [searchParam]);

  useEffect(() => {
    const scroll = document.getElementById("appLayout") || undefined;
    appScroll.current = scroll;
  }, []);

  return (
    <AppPullToRefresh
      onRefresh={() => {
        getListHistory(currentValue, true);
      }}
    >
      <div className="ui-my-page-sale-history">
        <Row gutter={[32, 32]} justify="center">
          <Col xs={24} md={24}>
            <Tab
              config={config}
              currentValue={currentValue}
              onChange={handleTabChange}
            />
          </Col>
        </Row>

        <div className="ui-my-page-sale-history__items" id="scrollableDiv">
          {loading ? (
            <>
              <AppVSkeleton />
              <AppVSkeleton />
              <AppVSkeleton />
            </>
          ) : (
            <>
              <Mobile>
                <InfiniteScroll
                  dataLength={saleList.length}
                  next={() => {
                    getListHistory(currentValue, false);
                  }}
                  hasMore={
                    saleList.length > 0 && saleList.length < totalCount.current
                  }
                  loader={undefined}
                  scrollableTarget="scrollableDiv"
                >
                  {saleList &&
                    saleList.length > 0 &&
                    saleList.map((item, index) => (
                      <SaleHistoryItem
                        handleWhenClickStatus={handleWhenClickStatus}
                        key={item.id}
                        item={item}
                        onReload={() => {
                          getListHistory(currentValue, true);
                        }}
                      />
                    ))}
                </InfiniteScroll>
              </Mobile>
              <Desktop>
                {saleList &&
                  saleList.length > 0 &&
                  saleList.map((item, index) => (
                    <SaleHistoryItem
                      handleWhenClickStatus={handleWhenClickStatus}
                      key={item.id}
                      item={item}
                      onReload={() => {
                        getListHistory(currentValue, true);
                      }}
                    />
                  ))}

                {saleList && saleList.length > 0 && (
                  <div className="ui-my-page-sale-history__pagination">
                    <Pagination
                      current={Number(searchParam.get("currentPage")) || 1}
                      pageSize={numberOfPagePC}
                      total={totalCount.current}
                      showSizeChanger={false}
                      onChange={(page) => onPageChange(page)}
                    />
                  </div>
                )}
              </Desktop>
            </>
          )}
        </div>
      </div>
    </AppPullToRefresh>
  );
};

export default SaleHistoryList;

SaleHistoryList.defaultProps = {};
