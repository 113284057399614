import AppHttpClient from "@core/http";
import { ResetPasswordFormValue } from "@pages/reset-password/model";

const BASE_URL = "/api/services/app/Account";

const forgotPassword = (data: ResetPasswordFormValue) =>
  AppHttpClient.post(`${BASE_URL}/ForgotPassword`, data);

const confirmForgotPassword = (token: string) =>
  AppHttpClient.post(`${BASE_URL}/ConfirmForgotPassword`, { token });

const resendForgotPassword = (token: string) =>
  AppHttpClient.post(`${BASE_URL}/ResendForgotPassword`, { token });

const resetPassword = (token: string, newPassword: string) =>
  AppHttpClient.post(`${BASE_URL}/ResetPassword`, { token, newPassword });

const changePassword = (data: ResetPasswordFormValue) =>
  AppHttpClient.post(`${BASE_URL}/ChangePassword`, data);

const checkCurrentPassword = (currentPassword: string) =>
  AppHttpClient.post(`${BASE_URL}/CheckPassword`, { currentPassword });

export {
  forgotPassword,
  confirmForgotPassword,
  resetPassword,
  resendForgotPassword,
  changePassword,
  checkCurrentPassword,
};
