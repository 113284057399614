import "./styles.scss";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ReactComponent as ArrowBack } from "@components/Icons/arrow-left-3.svg";
import AppNavigation from "src/components/AppNavigation";
import AppSteps from "src/components/AppSteps";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { isEmpty } from "lodash";
import { Desktop } from "src/layouts/break-point";
import { ReactComponent as Success } from "@components/Icons/success.svg";
import PublicForm from "@components/Product-Public/index";
import { ProductBody } from "src/models/product.model";
import classNames from "classnames";
import { ReactComponent as Warning } from "@components/Icons/warning.svg";
import AppModal from "src/components/AppModal";
import { useMediaQuery } from "react-responsive";
import { usePrompt } from "src/hooks/useBlocker";
import { useDispatch } from "react-redux";
import { setAppLoading } from "src/store/State";
import ProductService from "src/services/API/Product";
import UserService from "src/services/API/User";
import useScrollTop from "src/hooks/useScrollTop";
import { useNavigate } from "react-router-dom";
import { getTimeZone } from "src/helper";
import AddMediaScreen from "./AddMedia/index";
import AddInfoScreen from "./AddInfo";
import AddConditionScreen from "./AddCondition/index";
import AddConfirmScreen from "./AddConfirm";

export enum SellingState {
  ADD_MEDIA = 1,
  ADD_INFO = 2,
  ADD_CONDITION = 3,
  CONFIRM = 4,
  DONE = 5,
}

export default function SellingPage() {
  useScrollTop();
  const navigate = useNavigate();
  const bottomBarRef = useRef<any>();
  const outletRef = useRef<any>();

  const currentState = useRef<any>();

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const [productBody, setProductBody] = useState<ProductBody>({});

  const [allowBackStep, setAllowBackStep] = useState<boolean>(true);

  const [isHasData, setIsHasData] = useState<boolean>(false);

  const [modalWarningVisible, setModalWarningVisible] =
    useState<boolean>(false);

  const [commissionAmountOnTax, setCommissionAmountOnTax] = useState();

  const listMedia = useRef([]);

  const routerTx = useRef();

  const [productId, setProductId] = useState<number>(-1);
  // useBlo
  // usePrompt(
  //   "Hello from usePrompt -- Are you sure you want to leave?",
  //   isHasData.current
  // );

  const [screenState, setScreenState] = useState(SellingState.ADD_MEDIA);

  const blocker = useCallback((tx: any) => {
    routerTx.current = tx;
    if (screenState !== SellingState.DONE) {
      setModalWarningVisible(true);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleOnBlock();
    }
  }, [screenState]);

  usePrompt(blocker, isHasData);

  const steps = useMemo(
    () => [
      { label: translate("public.product.step1"), order: 1 },
      { label: translate("public.product.step2"), order: 2 },
      { label: translate("public.product.step3"), order: 3 },
      { label: translate("public.product.step4"), order: 4 },
      { label: translate("public.product.step5"), order: 5 },
    ],
    []
  );

  function handleChangeState() {
    if (screenState === SellingState.DONE) {
      setScreenState(1);
      setProductBody({});
      setAllowBackStep(true);
      return;
    }

    if (screenState <= 1) {
      return;
    }

    setScreenState(screenState - 1);
  }
  function setValueInfoForm(data: any) {
    if (!data) {
      return;
    }
    switch (screenState) {
      case SellingState.ADD_MEDIA:
        productBody.medias = data.medias;
        productBody.isPostVideoToYoutube = data.isSaveYoutube;
        listMedia.current = data.listMedia;
        break;

      case SellingState.ADD_INFO:
        productBody.categoryId = data.category;
        productBody.comment = data.comment;
        productBody.district = data.district;
        productBody.keyword = data.keyword;
        productBody.city = data.province;
        productBody.zipCode = data.zipCode;
        productBody.maker = data.maker;
        productBody.title = data.title;
        productBody.yearOfManufacture = data.year;
        productBody.street = data.street;
        break;

      case SellingState.ADD_CONDITION:
        productBody.price = data.price;
        productBody.isAllowOnlineTours = !isEmpty(data.isAllowOnlineTours);
        productBody.isNeedTakeBackQuote = false;
        // productBody.endDate = moment(data.endDate).toISOString();
        productBody.settingDaysEndDate = data.settingDaysEndDate;
        // productBody.estimationDate = data.estimationDate
        //   ? moment(data.estimationDate).startOf("day").add("hour", getTimeZone()).toISOString()
        //   : "";
        productBody.estimationDate = "";
        productBody.carryingCost = data.caryCost;
        break;

      case SellingState.CONFIRM:
        setAllowBackStep(false);
        setProductId(data);
        break;

      case SellingState.DONE:
        setProductBody({});
        setAllowBackStep(true);
        setIsHasData(false);
        break;

      default:
    }
    setProductBody(productBody);
  }

  function nextState(data: any) {
    setValueInfoForm(data);
    setScreenState((screenState) =>
      screenState === SellingState.DONE
        ? SellingState.ADD_MEDIA
        : screenState + 1
    );
  }

  function handleOnBlock() {
    if ((routerTx.current as any).location.pathname === "/shop") {
      setAllowBackStep(false);
      setTimeout(() => {
        setScreenState(SellingState.ADD_MEDIA);
        setProductBody({});
        setAllowBackStep(true);
        setIsHasData(false);
      }, 100);

      return;
    }
    (routerTx.current as any).retry();
  }

  const handleSettingBankAcc = () => {
    navigate("/my-page/bank-account");
    (routerTx.current as any)?.retry();
  };

  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });

  const appScroll = useRef<HTMLElement | any>(null);
  useEffect(() => {
    (appScroll.current as any)?.scrollTo({ top: 0 });

    if (!bottomBarRef.current) {
      bottomBarRef.current = document.querySelector(
        ".bottom-bar-container"
      ) as HTMLDivElement;
    }
    if (!outletRef.current) {
      outletRef.current = document.querySelector(
        ".outlet-container"
      ) as HTMLDivElement;
    }

    if (bottomBarRef.current) {
      if (screenState !== SellingState.ADD_MEDIA) {
        bottomBarRef.current.style.display = "none";
        outletRef.current.style.paddingBottom = "0px";
      } else {
        bottomBarRef.current.style.display = "";
        outletRef.current.style.paddingBottom = '60px';
      }
    }
  }, [screenState]);

  useEffect(() => {
    if (isDesktop) {
      const scroll = document.getElementById("appLayout") || null;
      appScroll.current = scroll;
    }
  }, []);

  const getCommissionAmountOnTax = async () => {
    dispatch(setAppLoading(true));
    try {
      const response = await ProductService.getCalculateProductFees(
        Number(productBody?.price)
      );
      setCommissionAmountOnTax(response.data.result.commissionAmountOnTax);
      dispatch(setAppLoading(false));
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setAppLoading(false));
    }
  };

  useEffect(() => {
    if (screenState === SellingState.CONFIRM) {
      getCommissionAmountOnTax();
    }
  }, [screenState]);

  return (
    <>
      <AppModal
        visible={modalWarningVisible}
        icon={<Warning />}
        title="登録中の商品は破棄されます。よろしいですか？"
        content=""
        okText="はい"
        cancelText="いいえ"
        onCancel={() => setModalWarningVisible(false)}
        onOk={() => {
          setModalWarningVisible(false);
          handleOnBlock();
        }}
      />
      <div
        className={classNames("selling-page-container", {
          "step-media": screenState === SellingState.ADD_MEDIA,
        })}
      >
        <AppNavigation
          title={translate("public.title")}
          onClick={
            screenState !== SellingState.ADD_MEDIA
              ? handleChangeState
              : undefined
          }
        />

        <div
          ref={appScroll}
          id='sellingContainer'
          className={classNames("selling-container", {
            "done-container": screenState === SellingState.DONE,
          })}
        >
          <Desktop>
            <div className="text-screen-title text-title-1 bold">
              {screenState !== SellingState.ADD_MEDIA && (
                <div className="icon-back">
                  <ArrowBack onClick={handleChangeState} />
                </div>
              )}
              <span>{translate("public.title")}</span>
            </div>
          </Desktop>
          <AppSteps
            steps={steps}
            currentStep={screenState}
            onChange={() => {}}
          />
          {allowBackStep && (
            <>
              <div
                className="wrap-step"
                style={{
                  display:
                    screenState === SellingState.ADD_MEDIA ? "flex" : "none",
                }}
              >
                <AddMediaScreen
                  onHasData={(hasData) => {
                    setIsHasData(hasData);
                  }}
                  onConfirm={nextState}
                />
              </div>

              <div
                className="wrap-step"
                style={{
                  display:
                    screenState === SellingState.ADD_INFO ? "flex" : "none",
                }}
              >
                <AddInfoScreen screenStatus={screenState} onConfirm={nextState} />
              </div>

              <div
                className="wrap-step"
                style={{
                  display:
                    screenState === SellingState.ADD_CONDITION
                      ? "flex"
                      : "none",
                }}
              >
                <AddConditionScreen onConfirm={nextState} />
              </div>
              <div
                className="wrap-step"
                style={{
                  display:
                    screenState === SellingState.CONFIRM ? "flex" : "none",
                }}
              >
                <AddConfirmScreen
                  listMedia={listMedia.current}
                  productBody={productBody}
                  onConfirm={nextState}
                  onSettingBankAcc={handleSettingBankAcc}
                  commissionAmountOnTax={commissionAmountOnTax}
                />
              </div>
            </>
          )}

          {screenState === SellingState.DONE && (
            <div className="wrap-step">
              <div className="step-done">
                <Success />
                <div className="bold text-body-2">
                  {translate("public.product.done.text1")}
                </div>
              </div>
              <PublicForm id={productId} onConfirm={nextState} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
