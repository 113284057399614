import AppHttpClient from "@core/http/index";
import { useSelector } from "react-redux";
import { RootState } from "src/store/configureStore";

const getListNotification = (skipCount?: number, maxResultCount?: number) =>
  AppHttpClient.get(
    `/api/services/app/UserNotificationService/GetAllNotifications?SkipCount=${skipCount}&MaxResultCount=${maxResultCount}`
  );

const getNotification = (id: number) =>
  AppHttpClient.get(`/api/services/app/UserNotificationService/Get?Id=${id}`);

const NotificationService = {
  getListNotification,
  getNotification,
};

export default NotificationService;
