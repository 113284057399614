import AxiosConfig from "./AxiosConfig";

class HttpClient {
  constructor(private axiosConfig: typeof AxiosConfig) {
    this.axiosConfig = AxiosConfig;
  }

  get<T>(endpoint: string, options: any = {}): Promise<any> {
    return this.axiosConfig.get<T>(endpoint, options);
  }

  post<T>(endpoint: string, body: any | null, options: any = {}): Promise<any> {
    return this.axiosConfig.post<T>(endpoint, body, options);
  }

  put<T>(endpoint: string, body: any | null, options: any = {}): Promise<any> {
    return this.axiosConfig.put<T>(endpoint, body, options);
  }

  patch<T>(endpoint: string, body: any | null, options: any = {}): Promise<any> {
    return this.axiosConfig.patch<T>(endpoint, body, options);
  }

  delete<T>(endpoint: string, options: any = {}): Promise<any> {
    return this.axiosConfig.delete<T>(endpoint, options);
  }

  postFormData<T>(endpoint: string, body: any | null, baseUrl?: string): Promise<any> {
    return this.axiosConfig.post<T>(endpoint, body, { headers: { "Content-Type": '' }, baseURL: baseUrl || process.env.REACT_APP_API_URL });
  }
}

export default new HttpClient(AxiosConfig);
