import { Button, Col, Form, Row } from "antd";
import "./styles.scss";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LabeledValue } from "antd/es/select";
import { setFilter } from "src/store/Product-Filter";
import { AppDispatch } from "@store/configureStore";
import ProductService from "src/services/API/Product";
import { isEmpty, orderBy, remove, sortBy, xor } from "lodash";
import AppSelectYear from "src/components/Form/AppYearSelect";
import AppTextField from "@components/Form/AppTextField";
import AppBottomSheet from "@components/AppBottomSheet";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/configureStore";
import AppSelect from "src/components/Form/AppSelect";
import { getPriceConfig } from "src/store/Global";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppTagSelection from "../../AppTagSelection/index";
import PrefectureSelectForm from "../prefecture-select";
import CategorySelectForm from "../category-select/index";

interface FilterProp {
  onCancel: () => void;
  onOk: () => void;
}
const SearchFilterForm: FC<FilterProp> = ({ ...props }) => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t: translate } = useTranslation();

  const productFilter = useSelector(
    (state: RootState) => state.ProductFilterReducer.filter
  );

  const listCategory = useSelector(
    (state: RootState) => state.ProductReducer.listCategory
  );

  const stateListCity = useSelector(
    (state: RootState) => state.GlobalReducer.provinces
  );

  const [listCity, setListCity] = useState<LabeledValue[]>([]);

  const [filterCategory, setFilterCategory] = useState([] as LabeledValue[]);

  const [showCategory, setShowCategory] = useState(false);

  const [showPrefecture, setShowPrefecture] = useState(false);

  const [filterPrefecture, setFilterPrefecture] = useState(
    [] as LabeledValue[]
  );

  const [form] = Form.useForm();

  const priceConfig = useSelector(
    (state: RootState) => state.GlobalReducer.priceConfig
  );

  const listAll = useMemo(
    () => ProductService.convertCategoryWithChildren(listCategory),
    []
  );

  useEffect(() => {
    if (!priceConfig || priceConfig.length <= 0) {
      dispatch(getPriceConfig());
    }
  }, [priceConfig]);

  useEffect(() => {
    const cites = stateListCity.map((x: string) => ({
      value: x,
      label: x,
    }));
    setListCity(cites);
  }, [stateListCity]);

  useEffect(() => {
    const listSelectCategory = listCategory.filter(
      (f) => (productFilter?.categories || []).indexOf(f.id) > -1
    );
    const listCategoryTag = listSelectCategory.map((x) => ({
      value: x.id,
      label: x.name,
    }));
    setFilterCategory(listCategoryTag);
  }, [listCategory, productFilter]);

  useEffect(() => {
    const startPrice = productFilter?.priceFrom;
    const endPrice = productFilter?.priceTo;
    const startPriceDetect = priceConfig?.find(
      (item: any) => Number(item.value) === Number(startPrice)
    );
    const endPriceDetect = priceConfig?.find(
      (item: any) => Number(item.value) === Number(endPrice)
    );
    form.setFieldsValue({
      maker: productFilter?.maker,
      isRecently: [productFilter?.isRecently],
      isPickup: [productFilter?.isPickup],
      endPrice: endPriceDetect?.key,
      startPrice: startPriceDetect?.key,
      startDate: productFilter?.yearOfManufactureFrom
        ? productFilter?.yearOfManufactureFrom
        : "",
      endDate: productFilter?.yearOfManufactureTo
        ? productFilter?.yearOfManufactureTo
        : "",
      prefecture: productFilter?.citySearch,
      category: productFilter?.categories,
    });

    const listCityTag = (productFilter?.citySearch || []).map((x) => ({
      value: x,
      label: x || "全国",
    }));

    setFilterPrefecture(listCityTag);
  }, [productFilter, priceConfig]);

  const isFormValid = () =>
    form.getFieldsError().filter(({ errors }) => errors.length > 0).length ===
    0;

  function onAddCategoryTag() {
    setShowCategory(true);
  }
  function onPrefectureTag() {
    setShowPrefecture(true);
  }

  function setProductFilter() {
    const startPrice = form.getFieldValue("startPrice");
    const endPrice = form.getFieldValue("endPrice");
    const endPriceValue = priceConfig.find((item) => item.key === endPrice);
    const startPriceValue = priceConfig.find((item) => item.key === startPrice);
    const filter = {
      maker: form.getFieldValue("maker"),
      isRecently: true,
      isPickup: true,
      isFilterByType: false,
      priceTo: endPriceValue?.value,
      priceFrom: startPriceValue?.value,
      yearOfManufactureFrom: form.getFieldValue("startDate")
        ? form.getFieldValue("startDate")
        : undefined,
      yearOfManufactureTo: form.getFieldValue("endDate")
        ? form.getFieldValue("endDate")
        : undefined,
      citySearch: form.getFieldValue("prefecture"),
      categories: form.getFieldValue("category"),
    };
    dispatch(setFilter(filter));
    if (location.pathname.includes(`/category/${id}`)) {
      navigate("/dashboard");
    }
  }

  function clearData() {
    form.setFieldsValue({
      category: [],
      maker: undefined,
      startDate: "",
      endDate: "",
      startPrice: undefined,
      endPrice: undefined,
      prefecture: undefined,
    });
    setFilterPrefecture([]);
    setFilterCategory([]);
  }

  function handleWhenDeleteCategory(listSelected: number[]) {
    let result = [...listSelected];
    result = orderBy(result, [], ["desc"]);

    result.forEach((categoryId) => {
      const category = listAll.find((child) => child.id === categoryId);
      if (!isEmpty(category?.childrenIds)) {
        if (
          xor(result, category?.childrenIds).length !==
          result.length - (category?.childrenIds || [[]]).length
        ) {
          remove(result, (obj) => obj === categoryId);
        }
      }
    });
    if (filterCategory.length !== result.length) {
      setFilterCategory(
        listCategory
          .filter((f) => result.includes(f.id))
          .map((x) => ({ label: x.name, value: x.id }))
      );
    }
  }

  return (
    <>
      {showPrefecture && (
        <AppBottomSheet
          title={translate("filter.city")}
          visible={showPrefecture}
          onClose={() => {
            setShowPrefecture(false);
          }}
          body={
            <PrefectureSelectForm
              value={form.getFieldValue("prefecture")}
              list={listCity}
              onSubmit={(listFilter: any[]) => {
                setFilterPrefecture(listFilter);
                setShowPrefecture(false);
              }}
            />
          }
          height="550"
        ></AppBottomSheet>
      )}

      {showCategory && (
        <AppBottomSheet
          title={translate("product.detail.category")}
          visible={showCategory}
          onClose={() => {
            setShowCategory(false);
          }}
          body={
            <CategorySelectForm
              value={form.getFieldValue("category")}
              onSubmit={(listFilter: any[]) => {
                setFilterCategory(listFilter);
                setShowCategory(false);
              }}
            />
          }
          height="550"
        ></AppBottomSheet>
      )}

      <div className="search-filter-form">
        <Form form={form} className="form-filter">
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <AppTagSelection
                isFilterByCategory
                label={translate("product.detail.category")}
                name="category"
                options={filterCategory}
                onAddTag={onAddCategoryTag}
                // onChange={(value: any) => {
                //   handleWhenDeleteCategory(value);
                // }}
              />
            </Col>
          </Row>

          <div className="line-break-f"></div>

          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <AppTextField
                name="maker"
                label={translate("product.detail.maker")}
                placeholder=""
                formgroup={form}
              />
            </Col>
          </Row>

          <div className="line-break-f"></div>

          <Row gutter={[16, 16]} className="form-multi">
            <Col xs={11}>
              <AppSelectYear
                label={translate("editProduct.form.modelYear")}
                name="startDate"
                rules={[
                  {
                    validator(_: any, value: any) {
                      const endDate = form.getFieldValue("endDate");
                      if (!endDate || !value || value <= endDate) {
                        form.setFields([
                          {
                            name: "endDate",
                            errors: [],
                          },
                        ]);
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "左側の年式は右側の年式以下で指定して下さい。"
                      );
                    },
                  },
                ]}
              ></AppSelectYear>
            </Col>

            <Col xs={2}>
              <div className="text-break">
                <div className="br">&nbsp;</div>
              </div>
            </Col>

            <Col xs={11}>
              <AppSelectYear
                label="&nbsp;"
                name="endDate"
                rules={[
                  {
                    validator(_: any, value: any) {
                      const startDate = form.getFieldValue("startDate");
                      if (!startDate || !value || startDate <= value) {
                        form.setFields([
                          {
                            name: "startDate",
                            errors: [],
                          },
                        ]);
                        return Promise.resolve();
                      }
                      form.validateFields(["startDate"]);
                      return Promise.reject("");
                    },
                  },
                ]}
              ></AppSelectYear>
            </Col>
          </Row>
          <div className="line-break-f"></div>

          <Row gutter={[16, 16]} className="row-filter form-multi">
            <Col xs={11}>
              <AppSelect
                label="希望価格"
                name="startPrice"
                options={priceConfig.map((x) => ({
                  label: x.key,
                  value: x.key,
                }))}
                rules={[
                  {
                    validator(_: any, value: any) {
                      const endPrice = form.getFieldValue("endPrice");
                      const endPriceValue = priceConfig.find(
                        (item) => item.key === endPrice
                      );
                      const startPriceValue = priceConfig.find(
                        (item) => item.key === value
                      );
                      if (
                        !endPriceValue ||
                        !value ||
                        Number(startPriceValue?.value) <=
                          Number(endPriceValue?.value)
                      ) {
                        form.setFields([
                          {
                            name: "endPrice",
                            errors: [],
                          },
                        ]);
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "左側の価格は右側の価格以下で指定して下さい。"
                      );
                    },
                  },
                ]}
              ></AppSelect>
            </Col>
            <Col xs={2}>
              <div className="text-break">
                <div className="br">&nbsp;</div>
              </div>
            </Col>
            <Col xs={11}>
              <AppSelect
                name="endPrice"
                label="&nbsp;"
                options={priceConfig.map((x) => ({
                  label: x.key,
                  value: x.key,
                }))}
                rules={[
                  {
                    validator(_: any, value: any) {
                      const startPrice = form.getFieldValue("startPrice");
                      const startPriceValue = priceConfig.find(
                        (item) => item.key === startPrice
                      );
                      const endPriceValue = priceConfig.find(
                        (item) => item.key === value
                      );
                      if (
                        !startPriceValue ||
                        !value ||
                        Number(endPriceValue?.value) >=
                          Number(startPriceValue?.value)
                      ) {
                        form.setFields([
                          {
                            name: "startPrice",
                            errors: [],
                          },
                        ]);
                        return Promise.resolve();
                      }
                      form.validateFields(["startPrice"]);
                      return Promise.reject("");
                    },
                  },
                ]}
              ></AppSelect>
            </Col>
          </Row>
          <div className="line-break-f"></div>

          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <AppTagSelection
                label={translate("signup.form.city")}
                name="prefecture"
                options={filterPrefecture}
                onAddTag={onPrefectureTag}
              />
            </Col>
          </Row>
        </Form>
        <div className="button-container">
          <Row gutter={[16, 16]}>
            <Col xs={12}>
              <Button
                className="button-primary text"
                onClick={() => {
                  clearData();
                }}
              >
                {translate("filter.cancel")}
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                className="button-secondary"
                onClick={() => {
                  if (isFormValid()) {
                    setProductFilter();
                    props.onOk();
                  } else {
                    form.validateFields();
                  }
                }}
              >
                {translate("filter.search")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default SearchFilterForm;
