import React, { FC, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@store/configureStore";
import { getProduct } from "@store/Product";
import { ReactComponent as Success } from "@components/Icons/success.svg";
import { cloneDeep, get } from "lodash";
import ProductService from "@service/API/Product";
import { ReactComponent as ArrowLeft } from "@components/Icons/arrow-left-3.svg";
import { useTranslation } from "react-i18next";

import "./index.scss";
import ChatService from "@service/API/Chat";
import { Collection, Message, Room } from "@models/chat.model";
import { FileType } from "@models/common.model";

import AppSteps from "@components/AppSteps";
import AppModal from "@components/AppModal";
import AppSpinner from "@components/AppSpinner";
import { Desktop, Mobile } from "src/layouts/break-point";
import ContactConfirm from "@pages/contact/components/Confirm";
import { doc, getDoc, increment } from "firebase/firestore";
import { db } from "src/firebase";
import moment from "moment";
import { DATE_TIME_FORMAT } from "src/configs";
import { setAppLoading, setError } from "@store/State";
import { injectHandleOk } from "src/App";
import StorageService from '@service/Storage';
import InfoScreen from "../product-confirm-buy/info";
import AppNavigation from "../../components/AppNavigation";
import ContactMethod from "./components/Method";
import ContactTime from "./components/Time";
import ContactMessage from "./components/Message";
import ContactComplete from "./components/Complete";
import {
  ContactMethodEnum,
  ContactPayloadValue,
  ContactStatus,
  ContactValue,
} from "./model";

const Contact: FC = () => {
  const location = useLocation();

  const initialValues: ContactValue = {
    method: "",
    candidateDates: [{ datetime: "" }, { datetime: "" }, { datetime: "" }],
    messageContent: "",
    notSendMessage: false,
  };

  const [value, setValue] = useState<ContactValue>(initialValues);
  const [isConfirm, setConfirm] = useState(false);
  const [currentStep, setCurrentStep] = useState<ContactStatus>(
    ContactStatus.SELECT_METHOD
  );

  const [successModalVisible, setSuccessModalVisible] =
    useState<boolean>(false);
  const [warningModalVisible, setWarningModalVisible] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams<{ id: string; type?: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const product = useSelector(
    (state: RootState) => state.ProductReducer.productDetail
  );
  const currentUser = useSelector(
    (state: RootState) => state.UserReducer.userInfo
  );
  const { t } = useTranslation();
  const [notSendMessage, setNotSendMessage] = useState<boolean>(false);
  const [orderToken, setOrderToken] = useState<string>();
  const [contactToken, setContactToken] = useState<string>();
  const [purchaseRequestTime, setPurchaseRequestTime] = useState<string>();
  const [orderID, setOrderID] = useState<number>();
  const timeClickBuy = useMemo(() => moment(purchaseRequestTime).format(DATE_TIME_FORMAT) || moment().format(DATE_TIME_FORMAT), [purchaseRequestTime]);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const { contactId, orderId } = Object.fromEntries(urlSearchParams.entries());

  const steps = useMemo(() => {
    if (value.method === ContactMethodEnum.MESSAGE) {
      return [
        { label: t("contact.step1.title"), order: 1 },
        { label: t("contact.step3.title"), order: 2 },
        { label: t("contact.step4.title"), order: 3 },
        { label: t("contact.step5.title"), order: 4 },
      ];
    }
    return [
      { label: t("contact.step1.title"), order: 1 },
      { label: t("contact.step2.title"), order: 2 },
      { label: t("contact.step3.title"), order: 3 },
      { label: t("contact.step4.title"), order: 4 },
      { label: t("contact.step5.title"), order: 5 },
    ];
  }, [value.method]);

  useEffect(() => {
    if (params.id) {
      dispatch(getProduct(params.id));
    }
  }, [params.id]);

  useEffect(() => {
    if (!location.pathname.includes("edit")) {
      setValue({
        ...initialValues,
        method: value.method,
      });
    }
  }, [value.method]);

  function getOrderDetail(id: number) {
    ProductService.getProductHistoryDetail(id)
      .then((res) => {
        const { orderToken } = res.data.result;
        setPurchaseRequestTime(res.data.result?.purchaseRequestTime);
        if (!location.pathname.includes("edit")) {
          setOrderToken(orderToken);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (
      location.pathname.includes("create") ||
      location.pathname.includes("re-contact")
    ) {
      setConfirm(true);
      getOrderDetail(Number(orderId));
    } else if (location.pathname.includes("edit")) {
      setConfirm(true);
      dispatch(setAppLoading(true));
      ProductService.getContactDetail(Number(contactId))
        .then((res) => {
          dispatch(setAppLoading(false));
          const resCandidateDates = [
            {
              datetime: res.data.result?.time1
                ? moment(
                    moment(res.data.result?.time1).format(DATE_TIME_FORMAT)
                  )
                : "",
            },
            {
              datetime: res.data.result?.time2
                ? moment(
                    moment(res.data.result?.time2).format(DATE_TIME_FORMAT)
                  )
                : "",
            },
            {
              datetime: res.data.result?.time3
                ? moment(
                    moment(res.data.result?.time3).format(DATE_TIME_FORMAT)
                  )
                : "",
            },
          ];
          setValue({
            ...initialValues,
            method: res.data.result?.type,
            candidateDates: resCandidateDates,
            messageContent: res.data.result?.content,
            notSendMessage: !res.data.result?.isSendMessage
              ? [!res.data.result?.isSendMessage]
              : [],
          });
          setOrderToken(res.data.result?.orderToken);
          setContactToken(res.data.result?.contactToken);
          setOrderID(res.data.result?.orderId);
          getOrderDetail(res.data.result?.orderId);
        })
        .catch((err) => {
          dispatch(setAppLoading(false));
          const idError = get(
            err,
            "response.data.error.validationErrors[0].message"
          );
          if (idError === "M0131") {
            injectHandleOk(() => navigate("/dashboard"));
          }
        });
    }
  }, []);

  useEffect(() => {
    if (currentUser?.id === product.userId) {
      navigate(-1);
      return;
    }

    if (
      !product.id &&
      (location.pathname.includes("create") ||
        location.pathname.includes("re-contact"))
    ) {
      dispatch(setAppLoading(true));
    } else if (
      product.id &&
      (location.pathname.includes("create") ||
        location.pathname.includes("re-contact"))
    ) {
      dispatch(setAppLoading(false));
      setValue({
        ...value,
        method: product.isAllowOnlineTours ? 1 : 2,
      });
    } else if (!location.pathname.includes("edit")) {
      setValue({
        ...value,
        method: product.isAllowOnlineTours ? 1 : 2,
      });
    }
  }, [product]);

  useEffect(() => {
    setNotSendMessage(!!(value?.notSendMessage as any).length);
  }, [value]);

  const requestToBuy = async () => {
    dispatch(setAppLoading(true));
    try {
      const res = await ProductService.requestBuyProduct(params.id as string);
      setConfirm(true);
      setOrderID(res.data.result);
      getOrderDetail(res.data.result);
    } catch (e) {
      console.log(e);
      navigate(-1);
    } finally {
      dispatch(setAppLoading(false));
    }
  };

  const setContactStatus = (status: ContactStatus) => setCurrentStep(status);
  const setContactValue = (contactValue: ContactValue) =>
    setValue({ ...value, ...contactValue });
  const onSubmit = async () => {
    const clonedValue = cloneDeep(value);
    clonedValue.candidateDates = clonedValue.candidateDates.filter(
      (candidateDate) => Object.values(candidateDate).every((item) => item)
    );
    if (
      clonedValue.candidateDates.some((candidateDate) =>
        candidateDate.datetime.isBefore(moment())
      ) && clonedValue.method !== ContactMethodEnum.MESSAGE
    ) {
      return dispatch(
        setError({ message: "M0129", title: "common.modal.userError" })
      );
    }
    setLoading(true);
    const formData: ContactPayloadValue = {
      productId: Number(params.id),
      type: Number(clonedValue.method),
      content: clonedValue.messageContent,
      isSendMessage: !(clonedValue?.notSendMessage as any)?.length,
      id: Number(contactId) || "",
      token: location.pathname.includes("edit") ? contactToken : null,
      orderToken: orderToken || null,
    } as ContactPayloadValue;
    clonedValue.candidateDates.forEach((candidateDate, index) => {
      // @ts-ignore
      formData[`time${index + 1}`] = candidateDate.datetime.toISOString();
    });
    try {
      const response = location.pathname.includes("edit")
        ? await ProductService.editContact(formData)
        : await ProductService.createContact(formData);
      const shouldSendMessage =
        response.data.result.isSendMessage ||
        response.data.result.type === ContactMethodEnum.MESSAGE;
      setSuccessModalVisible(true);
      if (shouldSendMessage) {
        const roomId: string = `${product.id}${currentUser?.id}${product.userId}`;
        const isRoomExisted = await ChatService.isRoomExisted(roomId);
        const message: Message = {
          messageId: new Date().getTime(),
          token: StorageService.getUserToken(),
          content: response.data.result.content,
          senderId: currentUser?.id as number,
          receiverId: product?.userId,
          roomId,
          file: {
            medias: [],
            documents: [],
          },
          createdAt: new Date().getTime(),
          isMyProduct: false,
          productTitle: product.title,
          shouldSendNotice: true,
        };
        if (isRoomExisted) {
          const roomIndex = await ChatService.getDocumentIndex(
            Collection.ROOMS,
            "roomId",
            roomId
          );

          const roomRef = doc(db, Collection.ROOMS, roomIndex as string);
          const room = await getDoc(roomRef);
          const isReceiverOnline = (room.data() as Room).onlineMembers.includes(
            product.userId
          );

          message.shouldSendNotice = !isReceiverOnline;

          if (!isReceiverOnline) {
            await ChatService.increaseUnreadMessage(
              roomIndex as string,
              product.userId
            );
          }
          await Promise.all([
            await ChatService.createMessage(message),
            await ChatService.updateRoom(roomIndex as string),
          ]);
        } else {
          const room: Room = {
            roomId: `${product.id}${currentUser?.id}${product.userId}`,
            productId: product.id as number,
            productCreatedBy: product.userId,
            roomTitle: product?.title,
            roomImage: product?.productMedias?.filter(
              (media) => media.type === FileType.IMAGE
            )?.[0]?.url || "",
            members: [
              {
                id: currentUser?.id as number,
                name: currentUser?.name as string,
                companyName: currentUser?.companyName as string,
              },
              {
                id: product?.userId,
                name: product?.sellerName,
                companyName: product?.sellerCompany,
              },
            ],
            memberIds: [currentUser?.id as number, product?.userId],
            onlineMembers: [currentUser?.id as number],
            createdAt: new Date().getTime(),
            createdBy: currentUser?.id as number,
            unreadMessage: {
              [currentUser?.id as number]: 0,
              [product?.userId as number]: increment(1),
            },
            totalMessage: 1,
            lastUpdatedAt: new Date().getTime(),
          };
          await ChatService.createRoom(room);
          await ChatService.createMessage(message);
        }
      }
    } catch (e) {
      console.log(e);
      const idError = get(e, "response.data.error.validationErrors[0].message");
      if (idError === "M0127") {
        injectHandleOk(() =>
          navigate(`/my-page/purchase-history/${orderID || orderId}`)
        );
      }
    } finally {
      setLoading(false);
    }
  };
  const handleBack = () => {
    if (!isConfirm || currentStep === ContactStatus.COMPLETE) {
      navigate(-1);
    } else {
      if (currentStep === ContactStatus.SELECT_METHOD) {
        return setWarningModalVisible(true);
      }
      setCurrentStep(currentStep - 1);
    }
  };

  const setVisibleWarningModal = () => setWarningModalVisible(true);

  const render = (status: ContactStatus) => {
    switch (status) {
      case ContactStatus.SELECT_METHOD:
        return (
          <ContactMethod
            value={value}
            setStatus={setContactStatus}
            setValue={setContactValue}
            setVisibleWarningModal={setVisibleWarningModal}
          />
        );

      case ContactStatus.SETTING_TIME:
        return value.method !== ContactMethodEnum.MESSAGE ? (
          <ContactTime
            timeClickBuy={timeClickBuy}
            value={value}
            setValue={setContactValue}
            setStatus={setContactStatus}
            setVisibleWarningModal={setVisibleWarningModal}
          />
        ) : (
          <ContactMessage
            value={value}
            setValue={setContactValue}
            setStatus={setContactStatus}
            setVisibleWarningModal={setVisibleWarningModal}
            onSubmit={onSubmit}
            loading={loading}
          />
        );
      case ContactStatus.LEAVE_MESSAGE:
        return value.method !== ContactMethodEnum.MESSAGE ? (
          <ContactMessage
            value={value}
            setValue={setContactValue}
            setStatus={setContactStatus}
            setVisibleWarningModal={setVisibleWarningModal}
            onSubmit={onSubmit}
            loading={loading}
          />
        ) : (
          <ContactConfirm
            type="confirm"
            value={value}
            notSendMessage={notSendMessage}
            showSellerInformation
            setVisibleWarningModal={setVisibleWarningModal}
            onSubmit={onSubmit}
            loading={loading}
          />
        );
      case ContactStatus.CONFIRM:
        return value.method !== ContactMethodEnum.MESSAGE ? (
          <ContactConfirm
            type="confirm"
            value={value}
            notSendMessage={notSendMessage}
            showSellerInformation
            setVisibleWarningModal={setVisibleWarningModal}
            onSubmit={onSubmit}
            loading={loading}
          />
        ) : (
          <ContactComplete value={value} />
        );
      case ContactStatus.COMPLETE:
        return <ContactComplete value={value} />;
      default:
        return <ContactMethod />;
    }
  };

  return (
    <div className="ui-contact">
      <AppSpinner loading={loading} />

      <AppModal
        icon={<Success />}
        visible={successModalVisible}
        title={t("contact.modal.success.title")}
        content={t("contact.modal.success.content")}
        okText={t("contact.modal.success.close")}
        cancelText=""
        onOk={() => {
          setSuccessModalVisible(false);
          setCurrentStep(
            value.method !== ContactMethodEnum.MESSAGE
              ? ContactStatus.COMPLETE
              : ContactStatus.CONFIRM
          );
        }}
      />

      <AppModal
        visible={warningModalVisible && isConfirm}
        title={
          !location.pathname.includes("edit")
            ? t("contact.modal.warning.title")
            : t("contact.modal.warning.edit.title")
        }
        content={
          !location.pathname.includes("edit")
            ? t(
                `contact.modal.${
                  value?.method === ContactMethodEnum.ONLINE
                    ? "warning1"
                    : "warning2"
                }.content`
              )
            : t("contact.modal.warning.edit.content")
        }
        okText={t("contact.modal.warning.confirm")}
        cancelText={t("contact.modal.warning.cancel")}
        onOk={() => navigate(-1)}
        onCancel={() => setWarningModalVisible(false)}
      />
      <Mobile>
        <AppNavigation
          onClick={() => handleBack()}
          title={t(isConfirm ? "contact.pageTitle" : "product.delivery.step1")}
        />
      </Mobile>

      {isConfirm && (
        <Desktop>
          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} md={14}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "48px 0 16px 16px",
                }}
              >
                {currentStep !== ContactStatus.COMPLETE && (
                  <ArrowLeft
                    style={{ cursor: "pointer", marginRight: "32px" }}
                    onClick={() => handleBack()}
                  />
                )}
                <div
                  style={{
                    fontSize: "24px",
                    fontWeight: 700,
                    color: "#333333",
                  }}
                >
                  {t("contact.pageTitle")}
                </div>
              </div>
            </Col>
          </Row>
        </Desktop>
      )}

      {!isConfirm && (
        <InfoScreen productInfo={product} onConfirm={requestToBuy}></InfoScreen>
      )}

      {isConfirm && (
        <div className="ui-contact__wrap">
          <div className="ui-contact__steps">
            <Row gutter={[32, 32]} justify="center">
              <Col xs={24} md={12}>
                <AppSteps steps={steps} currentStep={currentStep} isContact />
              </Col>
            </Row>
          </div>
          <div className="ui-contact__render">{render(currentStep)}</div>
        </div>
      )}
    </div>
  );
};
export default Contact;
