import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import AppSpinner from "@components/AppSpinner";
import AppStore, { AppDispatch, RootState } from "src/store/configureStore";
import {
  getListCategory as getListCategoryAction,
  getAllCategoryOnDashboard,
} from "@store/Product/index";
import {
  clearCurrentPathname,
  getListProvinces as getProvincesAction,
  getSystemConfig,
  getTotalUnreadNotice,
  setConvertVideoImage,
  setDeviceToken,
  setIsQuitMembership,
  setNoticeSystem,
  setSasCode,
  setTotalUnreadMyNotice,
  setTotalUnreadNotice,
  setVideoCallStatus,
} from "@store/Global";
import { ConfigProvider } from "antd";
import { injectStore } from "@core/http/AxiosConfig";
import { clearError, setConnectionSystem, setError, setShowTranslate } from "@store/State";
import AppModal from "@components/AppModal";
import "moment/locale/ja";
import "moment/locale/en-au";
import jpLocale from "antd/lib/locale/ja_JP";
import enLocale from "antd/lib/locale-provider/en_US";
import { ReactComponent as Error } from "@components/Icons/fail.svg";
import AppChat from "@components/AppChat";
import { getUserInfo as getUserInfoAction } from "@store/User";
import {
  HttpTransportType,
  HubConnectionBuilder,
  IHttpConnectionOptions,
} from "@microsoft/signalr";
import { clearRoomIds, setRoomId } from "@store/Chat";
import useBeforeUnload from "src/hooks/useBeforeUnload";
import ChatService from "@service/API/Chat";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "src/firebase";
import { Collection, Room } from "@models/chat.model";
import { ReactComponent as ArrowUp } from '@components/Icons/arrow-up-2.svg';
import { MessageType } from "@models/common.model";
import { changeLanguage, LanguageMode } from "@store/Config";
import AppHelmet from "@components/AppHelmet";
import useOnMessage from 'src/hooks/useOnMessage';
import Auth from "./container/Auth";
import AppRoutes from "./routes/router";
import themeService from "./theme/theme.service";
import "./App.css";
import "./App.scss";
import "./theme.less";
import "./firebase";
import StorageService from "./services/Storage";
import UserService from "./services/API/User";
import { handleConnectionNotice } from "./helper";
import { URL_WEB_CHAT_HUB } from "./configs";

injectStore(AppStore);
let handleOk: any;

export const injectHandleOk = (injectedHandleOk: any) => {
  handleOk = injectedHandleOk;
};

let navigate: any;

export const injectNavigate1 = (_navigate: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  navigate = _navigate;
};

// import LoginScreen from "./pages/login";
// import { AppDispatch } from '@store/configureStore';

function App() {
  const { t } = useTranslation();
  const [locale, setLocale] = useState(jpLocale);
  const dispatch = useDispatch<AppDispatch>();
  const { i18n } = useTranslation();
  const language = useSelector(
    (state: RootState) => state.ConfigReducer.language
  );

  const connectionSystem = useSelector(
    (state: RootState) => state.AppStateReducer.connectionSystem
  );
  const deviceToken = useSelector(
    (state: RootState) => state.GlobalReducer.deviceToken
  );

  const userInfo = useSelector(
    (state: RootState) => state.UserReducer.userInfo
  );

  const roomIds = useSelector((state: RootState) => state.ChatReducer.roomIds);

  const currentUser = useSelector(
    (state: RootState) => state.UserReducer.userInfo
  );

  const showTranslate = useSelector(
    (state: RootState) => state.AppStateReducer.showTranslate
  );

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    i18n.changeLanguage(language);
    if (language === "jp") {
      setLocale(jpLocale);
    }
    if (language === "en") {
      setLocale(enLocale);
    }
  }, [language, i18n]);

  const theme = useSelector((state: RootState) => state.ConfigReducer.theme);

  const loading = useSelector(
    (state: RootState) => state.AppStateReducer.loading
  );

  const systemConfig = useSelector(
    (state: RootState) => state.GlobalReducer.systemConfig
  );

  const error: { icon?: any; message: string; title: string; action?: () => void } =
    useSelector((state: RootState) => state.AppStateReducer.error);
  // const hasError = useMemo(() => !!Object.keys(error).length, [error]);
  const hasError = useMemo(() => !!error.message, [error]);

  // useEffect(() => {
  //   if (!provinces || isEmpty(provinces)) {
  //     dispatch(getProvincesAction());
  //   }
  // }, [provinces]);

  // useEffect(() => {
  //   if (!zipCodes || isEmpty(zipCodes)) {
  //     dispatch(getZipCodesAction());
  //   }
  // }, [zipCodes]);

  AppStore.dispatch(changeLanguage(LanguageMode.JP));

  useEffect(() => {
    themeService.setTheme(theme);
  }, [theme]);

  useEffect(() => {
    if (systemConfig) {
      AppStore.dispatch(
        setConvertVideoImage(
          systemConfig.find((item) => item.key === "DefaultThumbnail")?.value
        )
      );
      AppStore.dispatch(
        setSasCode(systemConfig.find((item) => item.key === "SasToken")?.value || '')
      );
    }
  }, [systemConfig]);

  useEffect(() => {
    AppStore.dispatch(changeLanguage(LanguageMode.JP));
    AppStore.dispatch(getListCategoryAction());
    AppStore.dispatch(getProvincesAction());
    AppStore.dispatch(getSystemConfig());
    AppStore.dispatch(getAllCategoryOnDashboard());
    if (UserService.isAuthenticated()) {
      AppStore.dispatch(getUserInfoAction());
    }
  }, []);

  useEffect(() => {
    if (UserService.isAuthenticated()) {
      AppStore.dispatch(getTotalUnreadNotice());
    }
  }, [UserService.isAuthenticated()]);

  // const handleOk = async () => {
  //   if (
  //     error.message &&
  //     (error.message === "M0119" || error.message === "M0078")
  //   ) {
  //     await dispatch(clearError({}));
  //     await window.location.reload();
  //   } else dispatch(clearError({}));
  // };

  async function handleMessageFromApp(event: any) {
    if (!event || !event.data) {
      return;
    }
    try {
      const roomId = roomIds[0];
      const message = JSON.parse(event.data);
      switch (message.type) {
        case MessageType.DEVICE_TOKEN:
          dispatch(setDeviceToken(message.data));
          break;
        // case MessageType.NOTICE_CHAT:
        //   if (message.data && currentUser?.id) {
        //     dispatch(clearRoomIds([]));
        //     const rId = message.data;
        //     const roomIndex = (await ChatService.getDocumentIndex(
        //       Collection.ROOMS,
        //       "roomId",
        //       rId
        //     )) as string;
        //     const isJoinAble = await ChatService.isJoinAbleRoom(
        //       roomIndex as string,
        //       currentUser?.id as number
        //     );
        //     if (!isJoinAble) {
        //       dispatch(
        //         setError({ title: "エラーが発生しました。", message: "C010" })
        //       );
        //       return;
        //     }
        //     dispatch(setRoomId(rId));
        //   }
        //   break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    try {
      const message = {
        type: MessageType.CHECK_NOTIFICATION,
      };
      (window as any).ReactNativeWebView?.postMessage(JSON.stringify(message));
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    // StorageService.saveDevice("MOBILE");
    window.removeEventListener("message", handleMessageFromApp, false); // Fails
    window.addEventListener("message", (ev) => handleMessageFromApp(ev), false);
    document.addEventListener(
      "message",
      (ev) => handleMessageFromApp(ev),
      false
    );
    return () => {
      window.removeEventListener("message", handleMessageFromApp, false);
      document.removeEventListener(
        "message",
        (ev) => handleMessageFromApp(ev),
        false
      );
    };
  }, [roomIds, currentUser]);

  useEffect(() => {
    if (UserService.isAuthenticated()) {
      const jwtToken = StorageService.getUserToken();
      if (jwtToken != null && URL_WEB_CHAT_HUB.URL) {
        const optionsSignalR: IHttpConnectionOptions = {
          accessTokenFactory: () => jwtToken,
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
        };
        const connect = new HubConnectionBuilder()
          .withUrl(`${URL_WEB_CHAT_HUB.URL}hrskhub/`, optionsSignalR)
          .withAutomaticReconnect()
          .build();
        AppStore.dispatch(setConnectionSystem(connect));
      }
    }
  }, [UserService.isAuthenticated()]);

  const onConnectionIdReceived = useCallback((data: any) => {
    const message = { type: "CONNECTION_ID", data: { connectionId: data } };
    (window as any).ReactNativeWebView?.postMessage(JSON.stringify(message));
  }, []);

  const onMessageReceived = useCallback((data: any) => {
    dispatch(setTotalUnreadNotice(JSON.parse(data).totalUnread));
    dispatch(setNoticeSystem(JSON.parse(data).noti));
  }, []);

  const onCallStatusReceived = useCallback((data: any) => {
    dispatch(setVideoCallStatus(data));
  }, []);

  const onLogoutAccount = useCallback(async (data: any) => {
    dispatch(clearCurrentPathname());
    dispatch(setIsQuitMembership(true));
    UserService.logout();
    navigate?.("/dashboard");
    dispatch(clearRoomIds([]));
    dispatch(setTotalUnreadNotice(0));
    dispatch(setTotalUnreadMyNotice(0));
    dispatch(setVideoCallStatus(undefined));
    const message = {
      type: "LOGOUT",
    };
    (window as any)?.ReactNativeWebView?.postMessage(JSON.stringify(message));
    if (connectionSystem) {
      connectionSystem.stop();
    }
  }, []);

  useEffect(() => {
    handleConnectionNotice(
      connectionSystem,
      [
        "Connected",
        "SendTransactionNoti",
        "SendAdminNoti",
        "SendJoinCallStatus",
        "SendDeactivate",
      ],
      [
        onConnectionIdReceived,
        onMessageReceived,
        onCallStatusReceived,
        onLogoutAccount,
      ]
    );
    return () => {
      if (connectionSystem) {
        connectionSystem.stop();
      }
      dispatch(setTotalUnreadNotice(0));
      dispatch(setVideoCallStatus(undefined));
    };
  }, [connectionSystem, onMessageReceived]);

  useEffect(() => {
    if (currentUser?.id) {
      const q = query(
        collection(db, Collection.ROOMS),
        where("memberIds", "array-contains", currentUser?.id),
        orderBy("lastUpdatedAt", "desc")
      );
      const unSubscribe = onSnapshot(q, (querySnapshot) => {
        let unreadMyNotice: number = 0;
        const rooms = querySnapshot.docs.map((doc) =>
          doc.data()
        ) as Array<Room>;
        rooms.forEach((room) => {
          if (!!(room.unreadMessage as any)?.[`${currentUser?.id}`]) {
            unreadMyNotice += 1;
          }
        });
        dispatch(setTotalUnreadMyNotice(unreadMyNotice));
        return () => unSubscribe();
      });
    }
  }, [currentUser]);

  useBeforeUnload(async () => {
    if (!StorageService.rememberMe() && deviceToken) {
      const userIndex = await ChatService.getDocumentIndex(
        Collection.USERS,
        "userId",
        userInfo?.id as number
      );
      await ChatService.deleteUserToken(
        userIndex as string,
        deviceToken as string
      );
    }
  });

  useOnMessage(async (event: any) => {
    if (!event || !event.data) {
      return;
    }
    try {
      const roomId = roomIds[0];
//      const message = JSON.parse(event.data);
      const message = JSON.parse('{{event.data nofilter}}');
      switch (message.type) {
        case MessageType.APP_BACKGROUND:
          if (roomId) {
            const roomIndex = (await ChatService.getDocumentIndex(
              Collection.ROOMS,
              "roomId",
              roomId
            )) as string;
            ChatService.leaveRoom(
              roomIndex as string,
              currentUser?.id as number
            );
          }
          break;
        case MessageType.APP_FOREGROUND:
          if (roomId) {
            const roomIndex = (await ChatService.getDocumentIndex(
              Collection.ROOMS,
              "roomId",
              roomId
            )) as string;
            Promise.all([
              ChatService.joinRoom(
                roomIndex as string,
                currentUser?.id as number
              ),
              ChatService.resetUnreadMessage(
                roomIndex as string,
                currentUser?.id as number
              ),
            ]);
          }
          break;
        case MessageType.NOTICE_CHAT:
          if (message.data && currentUser?.id) {
            dispatch(clearRoomIds([]));
            const rId = message.data;
            const roomIndex = (await ChatService.getDocumentIndex(
              Collection.ROOMS,
              "roomId",
              rId
            )) as string;
            const isJoinAble = await ChatService.isJoinAbleRoom(
              roomIndex as string,
              currentUser?.id as number
            );
            if (!isJoinAble) {
              dispatch(
                setError({ title: "エラーが発生しました。", message: "C010" })
              );
              return;
            }
            dispatch(setRoomId(rId));
          }
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e);
    }
  });

  function setCookie(key: string, value: string, expiry: number) {
    const expires = new Date();
    expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
    document.cookie = `${ key  }=${  value  };expires=${  expires.toUTCString()}`;
  }

  const googleTranslateElementInit = () => {
    const currentLanguage = StorageService.getLanguage();
    setCookie('googtrans', `/${currentLanguage}/${currentLanguage}`, 1);
    // @ts-ignore
    // eslint-disable-next-line no-new
    new window.google.translate.TranslateElement(
      {
        // pageLanguage: "jp",
        autoDisplay: false,
        // @ts-ignore
        layout: google.translate.TranslateElement.InlineLayout.VERTICAL
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    if (!(window as any).ReactNativeWebView) {
      const addScript = document.createElement("script");
      addScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      document.body.appendChild(addScript);
      // @ts-ignore
      window.googleTranslateElementInit = googleTranslateElementInit;
    }
  }, []);

  useEffect(() => {
    if (showTranslate && !(window as any).ReactNativeWebView) {
      const selectedLanguage = document.querySelector('html')?.getAttribute("lang");
      const selector = document.querySelector(`.goog-te-combo`) as HTMLSelectElement;
      selector?.addEventListener('change', (event) => {
        const isOnline = window.navigator.onLine;
        if (!isOnline) {
          dispatch(setShowTranslate(false));
          navigate('/network-error');
        } else {
          const selectedLang = (event.target as any).value;
          if (selectedLang === "ja") {
            StorageService.deleteLanguage();
          } else {
            StorageService.setLanguage(selectedLang);
          }
        }
      });
      if (selector) {
        selector.value = selectedLanguage === "auto" ? "ja" : selectedLanguage as string;
        // selectedOption.setAttribute("selected", "selected");
      }
    }
  }, [showTranslate]);

  return (
    <ConfigProvider locale={locale}>
      <Router>
        <Auth>
          <div id="google_translate_element" style={{ display: showTranslate ? "flex" : "none" }}>
            <div className="hide-widget" onClick={() => dispatch(setShowTranslate(false))}>
              <ArrowUp />
            </div>
          </div>
          <AppChat />
          <AppHelmet />
          <AppModal
            icon={error.icon || <Error />}
            visible={hasError}
            title={t(error.title)}
            content={t(`serverErrorMessage.${error.message || "M0000"}`)}
            okText={t("common.ok")}
            onOk={() => {
              if (handleOk) {
                handleOk();
                handleOk = null;
              }
              if (error.action) {
                error.action();
              }
              dispatch(clearError({}));
            }}
            onCancel={() => dispatch(clearError({}))}
          />
          <AppSpinner loading={loading}></AppSpinner>
          <AppRoutes />
        </Auth>
      </Router>
    </ConfigProvider>
  );
}

export default App;
//
