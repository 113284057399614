import React, { forwardRef } from "react";

type FacebookProps = {
  url? : string;
} & Omit<React.ComponentProps<"a">, "herf" | "target" | "rel">;

export const FacebookItem = forwardRef<HTMLAnchorElement, FacebookProps>(
    (
        { url, ...intrinsicProps },
        forwardedRef,
    ) => {
        const facebookUrl = new URL(`https://www.facebook.com/share.php?u= + ${url}`);

        return (
            <a
              ref={forwardedRef}
              href={facebookUrl.toString()}
              target="_blank"
              rel="noopener noreferrer"
              {...intrinsicProps}
            ><img alt="" src="/assets/images/sns_fasebook.png" className="sns-img" /></a>
          );
    },
);
export default FacebookItem;