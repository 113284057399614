import React, { FC, memo, useMemo } from "react";
import { ContactMethodEnum, ContactValue } from "@pages/contact/model";
import AppFieldset from "@components/AppFieldset";
import AppRow from "@components/AppRow";
import moment from "moment";
import { DATE_FORMAT, TIME_FORMAT } from "src/configs";
import { cloneDeep } from "lodash";
import { transformStringDate } from "src/helper";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { RootState } from '@store/configureStore';
import { Desktop, Mobile } from "src/layouts/break-point";
import { Col, Row } from "antd";
import AppButton from "@components/AppButton";

interface Props {
  value: ContactValue;
  notSendMessage?: boolean;
  showSellerInformation?: boolean;
  setVisibleWarningModal?: () => void;
  onSubmit?: () => void;
  loading?: boolean;
  type?: "confirm" | "complete";
}

const ContactConfirm: FC<Props> = memo(({ ...props }) => {
  const { value, notSendMessage, showSellerInformation, onSubmit, loading, type } = props;
  const { t } = useTranslation();
  const { productDetail } = useSelector((state: RootState) => state.ProductReducer);

  const transformedValue = useMemo(() => {
    const contactMethod = {
      1: t("contact.step1.method1.title"),
      2: t("contact.step1.method2.title"),
      3: t("contact.step1.method3.title")
    };
    const clonedValue = cloneDeep(value);
    // @ts-ignore
    clonedValue.method = contactMethod[clonedValue.method];
    clonedValue.candidateDates = clonedValue.candidateDates.filter(candidateDate => Object.values(candidateDate).every(item => item));
    return clonedValue;
  }, [value]);
  
  return (
    <div className="ui-contact-confirm">
      <Row gutter={[32, 32]} justify="center">
        <Col xs={24} md={14}>
          {
            showSellerInformation && (
              <div className="ui-contact-confirm__warning">{ t("contact.step4.warning") }</div>
            )
          }
          {
            showSellerInformation && (
              <div className="ui-contact-time__seller-information">
                <div className="ui-contact-time__seller-title">{ t("contact.step2.sellerTitle") }</div>
                <div>
                { productDetail?.sellerCompany &&
                  <div
                    className="ui-contact-time__seller-company">{ productDetail?.sellerCompany }</div>
                }
                  <div
                    className="ui-contact-time__seller-name">{ `${ productDetail?.sellerName }${ t("contact.step2.sellerName") }` }
                  </div>
                </div>
              </div>
            )
          }
          <AppFieldset>
            <AppRow
              label={t("contact.step3.confirm.contactMethod")}
              value={transformedValue.method as string} hasBorderBottom={true}
              labelWidth="40%"
              valueWidth="60%"
            />
            {(!notSendMessage || value.method === ContactMethodEnum.MESSAGE) && (
              <AppRow
                label={t("contact.step3.confirm.message")}
                value={value.messageContent} hasBorderBottom={value.method !== ContactMethodEnum.MESSAGE}
                labelWidth="40%"
                valueWidth="60%"
              />
            )}
            {
              !! (transformedValue.candidateDates && transformedValue.candidateDates.length && value.method !== ContactMethodEnum.MESSAGE) && transformedValue.candidateDates.map((item, index) => (
                <AppRow
                  key={index}
                  label={t("contact.step3.confirm.choice", { number: index + 1 })}
                  value={`${transformStringDate(moment(item.datetime).format(DATE_FORMAT))} - ${moment(item.datetime).format(TIME_FORMAT)}`}
                  hasBorderBottom={index !== transformedValue.candidateDates.length - 1}
                  labelWidth="40%"
                  valueWidth="60%"
                />
              ))
            }
          </AppFieldset>

          {
            type === "confirm" && (
              <>
                <Mobile>
                  <div className="ui-contact-confirm__action">
                    <Row gutter={[32, 32]} justify="center">
                      <Col xs={24} md={12} xl={8}>
                        <AppButton buttontype="primary" onClick={onSubmit}>{ t("contact.action.register") }</AppButton>
                      </Col>
                    </Row>
                  </div>
                </Mobile>

                <Desktop>
                  <Row gutter={[32, 32]} justify="center">
                    <Col xs={12}>
                      <AppButton buttontype="primary" onClick={onSubmit}>{ t("contact.action.register") }</AppButton>
                    </Col>
                  </Row>
                </Desktop>
              </>
            )
          }
        </Col>
      </Row>
    </div>
  );
});

export default ContactConfirm;

ContactConfirm.defaultProps = { type: "confirm" };
