import React, { FC, memo, useEffect, useState } from "react";
import classnames from "classnames";
import "./index.scss";
import { Desktop, Mobile } from "src/layouts/break-point";
import { ProductTransactionStatus } from "src/models/product.model";
import { cloneDeep } from "lodash";
import { TRANSACTION_TIMELINE } from "../../pages/my-page/model";
import { ReactComponent as TransactionNotHappened } from "../Icons/transaction-not-happened.svg";
import { ReactComponent as TransactionProcessing } from "../Icons/transaction-current.svg";
import { ReactComponent as TransactionCompleted } from "../Icons/transaction-checked.svg";

interface Props {
  title?: string;
  dataSource: TRANSACTION_TIMELINE[];
  currentStatus: ProductTransactionStatus;
  actionView: any;
}

const AppTimeline: FC<Props> = memo(({ ...props }) => {
  const { title, dataSource, currentStatus, actionView } = props;
  const [listView, setListView] = useState<TRANSACTION_TIMELINE[]>();

  useEffect(()=> {
    setListView(cloneDeep(dataSource).reverse());
  }, [dataSource]);
  return (
    <>
      <Mobile>
        <div className="ui-timeline">
          <div className="ui-timeline__title">{title}</div>
          <div className="ui-timeline__content">
            {listView &&
              listView.length > 0 &&
              listView.map((item, index) => (
                <div
                  key={index}
                  className={classnames("ui-timeline__item", {
                    active: item.status === currentStatus,
                    'active-action': item.status === currentStatus && actionView,
                    last: index === dataSource.length - 1
                  })}
                >
                  <div
                    className={classnames(
                      "ui-timeline__status",
                      index === dataSource.length - 1 && "last"
                    )}
                  >
                    {((item.status || 0) > currentStatus &&
                      currentStatus !== ProductTransactionStatus.DONE) ||
                    (item.status === ProductTransactionStatus.DONE &&
                      currentStatus ===
                        ProductTransactionStatus.CONFIRM_RECEIVED) ? (
                      <TransactionNotHappened />
                    ) : (item.status || 0) === currentStatus ? (
                      <TransactionProcessing />
                    ) : (
                      <TransactionCompleted />
                    )}
                  </div>
                  {index < (dataSource.length - 1) && <div className="line-dot">&nbsp;</div>}

                  <div className="parent-right">
                    <div className="info-container">
                      <div className="info-child">
                        <div className="ui-timeline__icon">
                          {item.icon?.(currentStatus)}
                        </div>
                        <div>
                          <div className={classnames("ui-timeline__label")}>
                            {item.label}
                          </div>
                          <div
                            className={classnames("ui-timeline__description")}
                          >
                            {item.note}
                          </div>
                        </div>
                      </div>
                      <div
                        className={classnames(
                          "ui-timeline__time",
                          item.status === currentStatus &&
                            item.status !== ProductTransactionStatus.CANCEL &&
                            "active",
                          item.status === currentStatus && "active-delete"
                        )}
                      >
                        {item.time !== "Invalid date" ? item.time : ""}
                      </div>
                    </div>
                    {item.status === currentStatus && (
                      <div className="button-group">
                          {actionView}
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Mobile>

      <Desktop>
        <div className="ui-timeline">
          <div className="ui-timeline__title">{title}</div>
          <div className="ui-timeline__content">
            {listView &&
              listView.length > 0 &&
              listView.map((item, index) => (
                <div key={index} className="ui-timeline__item">
                  <div
                    className={classnames(
                      "ui-timeline__status",
                      index === dataSource.length - 1 && "last",
                      index === 0 && "first"
                    )}
                  >
                    {((item.status || 0) > currentStatus &&
                      currentStatus !== ProductTransactionStatus.DONE) ||
                    (item.status === ProductTransactionStatus.DONE &&
                      currentStatus ===
                        ProductTransactionStatus.CONFIRM_RECEIVED) ? (
                      <TransactionNotHappened />
                    ) : (item.status || 0) === currentStatus ? (
                      <TransactionProcessing />
                    ) : (
                      <TransactionCompleted />
                    )}
                  </div>
                  <div>
                    <div className="ui-timeline__icon">
                      {item.icon?.(currentStatus)}
                    </div>
                    <div>
                      <div
                        className={classnames(
                          "ui-timeline__label",
                          item.status === currentStatus && "active"
                        )}
                      >
                        {item.label}
                      </div>
                      <div
                        className={classnames(
                          "ui-timeline__description",
                          item.status === currentStatus && "active"
                        )}
                      >
                        {item.note}
                      </div>
                    </div>
                  </div>
                  <div
                    className={classnames(
                      "ui-timeline__time",
                      item.status === currentStatus &&
                        item.status !== ProductTransactionStatus.CANCEL &&
                        "active",
                      item.status === currentStatus && "active-delete"
                    )}
                  >
                    {item.time !== "Invalid date" ? item.time : ""}
                  </div>
                  {item.status === currentStatus && (
                      <div className="button-group">
                          {actionView}
                      </div>
                    )}
                </div>
              ))}
          </div>
        </div>
      </Desktop>
    </>
  );
});

export default AppTimeline;

AppTimeline.defaultProps = { title: "取引状況" };
