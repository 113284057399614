import React from "react";

type ICommon = {
  exact?: boolean;
  path?: string;
  guard?: React.FunctionComponent;
  layout?: React.FunctionComponent;
  component?: any;
  requireRoles?: string[] | [];
  routes?: ICommon[];
};

export type IRoutes = ICommon & {
  routes?: ICommon[];
};

export type IParams = {
  id?: string;
};

export enum BottomBarType {
  NORMAL = "NORMAL",
  NOTIFICATION = "NOTIFICATION",
}
export enum BottomBarKey {
  HOME = "HOME",
  NOTIFICATION = "NOTIFICATION",
  SHOP = "SHOP",
  SETTING = "SETTING",
  MY_PAGE = "MY_PAGE",
}

export interface BottomBarItem {
  type: BottomBarType;
  title: string;
  icon: string;
  routeName: string;
  badge?: number;
  key: BottomBarKey;
  requireAuth: boolean;
}

export enum RoleType {
  BUYER,
  SELLER,
}

export enum FileType {
  IMAGE = 1,
  VIDEO,
}

export enum MessageType {
  MEDIA = "MEDIA",
  ERROR = "ERROR",
  LOADING = "LOADING",
  JOIN_CALL = "JOIN_CALL",
  UPLOAD_MEDIA = "UPLOAD_MEDIA",
  OPEN_URL = "OPEN_URL",
  DEVICE_TOKEN = "DEVICE_TOKEN",
  SETTING_NOTIFICATION_DEVICE = "SETTING_NOTIFICATION_DEVICE",
  CHECK_NOTIFICATION = "CHECK_NOTIFICATION",
  OPEN_SETTING = "OPEN_SETTING",
  NOTICE_CHAT = "NOTICE_CHAT",
  LOAD_CHAT = "LOAD_CHAT",
  APP_BACKGROUND = "APP_BACKGROUND",
  APP_FOREGROUND = "APP_FOREGROUND",
  RE_OPEN_APP = "RE_OPEN_APP",
  MODAL = "MODAL",
  BADGE = "BADGE",
  ROOM_ID = "ROOM_ID",
  PERMISSION = "PERMISSION"
}

export enum BREAK_POINT {
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1024,
  xxl = 1400,
}

export enum DeviceType {
  WEB,
  APP,
  BOTH,
}
