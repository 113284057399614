import React, { FC, memo, ReactNode, useEffect } from "react";
import { Checkbox, Col, Form, FormInstance, Row } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import classNames from "classnames";
import { CheckboxGroupProps } from "antd/lib/checkbox";

interface Props extends CheckboxGroupProps {
  name: string;
  formgroup?: FormInstance<Object>;
  label?: ReactNode;
  rules?: object[];
  value?: CheckboxValueType[];
  options: Array<{
    label: ReactNode;
    value: CheckboxValueType;
    hidden?: boolean;
  }>;
  type: "default" | "reverse";
}

const AppCheckbox: FC<Props> = memo(({ ...props }) => {
  const { name, label, rules, type, options, value, formgroup, className, onChange, disabled } =
    props;

  useEffect(() => {
    formgroup?.setFields([
      {
        name,
        value,
      },
    ]);
  }, [value]);

  return (
    <Form.Item name={name} label={label} rules={rules} initialValue={value}>
      <Checkbox.Group style={{ width: "100%" }} onChange={onChange} disabled={disabled}>
        { (options && options.length) &&
          options.map(({ label, value, hidden }, index) => (
            <Row key={index} className={classNames({ "hidden-view": hidden })}>
              <Col xs={24}>
                <Checkbox
                  className={classNames(
                    "app-checkbox",
                    type === "reverse" ? "ant-custom-checkbox" : ""
                  )}
                  value={value}
                >
                  {label}
                </Checkbox>
              </Col>
            </Row>
          ))}
      </Checkbox.Group>
    </Form.Item>
  );
});

export default AppCheckbox;

AppCheckbox.defaultProps = { rules: [], type: "default" };
