import "./styles.scss";

import { Checkbox } from "antd";
import React, {
  FC,
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import _, { cloneDeep, get, remove } from "lodash";
import { ReactComponent as ArrowRight } from "@components/Icons/arrow-right.svg";
import { ReactComponent as ArrowDown } from "@components/Icons/arrow-down-2.svg";
import { Category } from "@models/product.model";
import { useMediaQuery } from "react-responsive";
import { useSelector } from 'react-redux';
import AppStore, { RootState } from '@store/configureStore';

interface TreeProps {
  options: Category[];
  onChange: (value: any[]) => void;
  type?: "multi" | "normal";
}

interface TreeChildProp {
  treeNote: Category;
  onChangeCheckbox: (value: boolean, treeNote: Category) => void;
  isExpand?: boolean;
}
interface TreeChildState {
  value: boolean;
  isExpand?: boolean;
}

class TreeChildCheckbox extends React.Component<TreeChildProp, TreeChildState> {
  constructor(props: TreeChildProp) {
    super(props);
    this.state = {
      value: this.props.treeNote.value,
      isExpand: this.props.isExpand || false,
    };
  }

  componentDidUpdate(props: TreeChildProp) {
    if (this.state.value !== props.treeNote.value) {
      this.setState({ value: props.treeNote.value });
    }
  }

  render() {
    const selectedCategories = AppStore.getState().ProductFilterReducer?.filter?.categories || [];
    return (
      <div className="tree-container">
        <div
          className={classNames(
            {
              "tree-parent": get(this.props.treeNote, "children.length") > 0,
            },
            {
              "tree-normal": get(this.props.treeNote, "children.length") <= 0,
            }
          )}
        >
          {get(this.props.treeNote, "children.length") > 0 && (
            <div
              className="expand-icon"
              onClick={() => {
                this.setState((prevState) => ({
                  isExpand: !prevState.isExpand,
                }));
              }}
            >
              {!this.state.isExpand ? <ArrowRight /> : <ArrowDown />}
            </div>
          )}
          <Checkbox
            className="ant-custom-checkbox"
            checked={this.state.value}
            value={this.state.value}
            onChange={($event) => {
              this.setState({ value: $event.target.checked });
              this.props.treeNote.value = $event.target.checked;
              this.props.onChangeCheckbox(
                $event.target.checked,
                this.props.treeNote
              );
            }}
          >
            &nbsp;
          </Checkbox>

          <div className="checkbox-group-view">
            <div
              className={classNames("text-checkbox", {
                selected: this.state.value,
                "color-body": this.props.treeNote.lv === 4,
              })}
              onClick={() => {
                this.setState({ value: true });
                this.props.treeNote.value = !this.state.value;
                this.props.onChangeCheckbox(!this.state.value, this.props.treeNote);
              }}
            >
              {this.props.treeNote.name}
            </div>
          </div>
        </div>

        {get(this.props.treeNote, "children.length") > 0 &&
          this.state.isExpand && (
            <div className="tree-child">
              {this.props.treeNote.children?.map((treeChild: Category) => {
                let isExpand = false;
                const handleExpand = (category: Category, id: number) => {

                  if (category.id === id) {
                    if (!category.children?.length) {
                      isExpand = true;
                      return;
                    }
                  }

                  if (category.children?.length) {
                    category.children.forEach(item => {
                      handleExpand(item, id);
                    });
                  }
                };
                selectedCategories.forEach(category => handleExpand(treeChild, category));
                return (
                  <TreeChildCheckbox
                    isExpand={isExpand}
                    treeNote={treeChild}
                    key={treeChild.id}
                    onChangeCheckbox={this.props.onChangeCheckbox}
                    // isExpand={false}
                  ></TreeChildCheckbox>
                );
              })}
            </div>
          )}
      </div>
    );
  }
}

const CustomTreeCheckbox: FC<TreeProps> = memo(({ ...props }) => {
  const [listOption, setListOption] = useState(props.options);
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });
  const categories = useSelector(
    (state: RootState) => state.ProductFilterReducer.filter?.categories || []
  );
  const listCategory = useSelector(
    (state: RootState) => state.ProductReducer.listCategory
  );

  const result = useRef<Category[]>([]);

  useLayoutEffect(() => {
    if (props.options.length >= 0) {
      setListOption(cloneDeep(props.options));
    }
  }, [props.options]);

  useLayoutEffect(() => {
    if (isDesktop) {
      setListOption(props.options);
    }
  }, [listOption]);

  function checkIsChecked(listData?: Category[]) {
    if (!listData) {
      return;
    }
    listData.forEach((f) => {
      if (f.value) {
        result.current.push(f);
      }
      checkIsChecked(f.children);
    });
  }

  function getListSelected() {
    result.current = [];
    checkIsChecked(listOption);
  }

  return (
    <>
      {listOption.map((treeNote: Category, index) => {
        let isExpand = false;
        const handleExpand = (category: Category, id: number) => {

          const categoryInfo = (listCategory || []).find(item => item.id === id);
          if ([2, 4].includes(categoryInfo?.parentId as number)) {
            return;
          }

          if (category.id === id) {
            isExpand = true;
            return;
          }

          if (category.children?.length) {
            category.children.forEach(item => {
              handleExpand(item, id);
            });
          }
        };
        categories.forEach(category => handleExpand(treeNote, category));

        return (
          <TreeChildCheckbox
            isExpand={isExpand}
            onChangeCheckbox={(value: boolean, currentNode: Category) => {
              // treeNote.value = value;
              const clone = cloneDeep(listOption);
              setListOption(clone);
              getListSelected();
              props.onChange(result.current);
            }}
            treeNote={treeNote}
            key={treeNote.id}
          ></TreeChildCheckbox>
        );
      })}
    </>
  );
});

export default CustomTreeCheckbox;
