import React, { Fragment, lazy, Suspense, useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AuthGuard from "src/core/guard/AuthGuard";
import DashboardLayout from "src/layouts/mobile-dashboard-layout/mobile-dashboard-layout";
import MyPageLayout from "src/layouts/my-page-layout/my-page-layout";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@store/configureStore";
import { setPathname } from "src/store/Global";
import Demo from "src/pages/demo";
import NetworkError from "src/pages/network-error";
import LoginScreen from "src/pages/login";
import SignUpScreen from "src/pages/sign-up";
import ResetPassword from "src/pages/reset-password";
import PasswordForm from "src/pages/reset-password/components/PasswordForm";
import Complete from "src/pages/sign-up/components/Complete";
import ProductDetailScreen from "src/pages/product-detail";
import ConfirmContact from "src/pages/confirm-contact/ConfirmContact";
import EditProductDetail from "src/pages/edit-product-detail";
import ProductConfirmScreen from "src/pages/product-confirm-buy";
import Preparation from "src/pages/visit-online/Preparation";
import HomeScreen from "src/pages/home";
import SettingScreen from "src/pages/setting";
import SellingPage from "src/pages/selling";
import MyPageFavorite from "src/pages/my-page/components/Favorite";
import MyPageProfile from "src/pages/my-page/components/Profile";
import Edit from "src/pages/my-page/components/Profile/Edit";
import MyBankAccount from "src/pages/my-page/components/BankAccount";
import SalesHistory from "src/pages/my-page/components/SalesHistory";
import ChangePassword from "src/pages/change-password";
import SaleHistoryDetail from "src/pages/my-page/components/SalesHistory/SaleHistoryDetail";
import PurchaseHistoryDetail from "src/pages/my-page/components/PurchaseHistory/PurchaseHistoryDetail";
import PurchaseHistory from "src/pages/my-page/components/PurchaseHistory";
import QuitMembership from "src/pages/my-page/components/QuitMembership";
import NotificationDetail from "src/pages/notification/components/NoticeSystemDetail";
import NotificationScreen from "src/pages/notification";
import MyPage from "src/pages/my-page";
import Contact from "src/pages/contact";
import MyBankAccountEdit from "src/pages/my-page/components/BankAccount/Edit";
import DesktopLayout from "../layouts/desktop-layout/index";
import { IRoutes } from "../models/common.model";
import { PATH_NAME } from "../configs";

// modules
// const Demo = lazy(() => import("../pages/demo"));
// const LoginScreen = lazy(() => import("../pages/login"));
// const HomeScreen = lazy(() => import("../pages/home"));
// const Notification = lazy(() => import("../pages/notification"));
// const SettingScreen = lazy(() => import("../pages/setting"));
// const NotificationDetail = lazy(
//   () => import("../pages/notification/components/NoticeSystemDetail")
// );
// const SignUpScreen = lazy(() => import("../pages/sign-up"));
// const SignUpSuccessScreen = lazy(
//   () => import("../pages/sign-up/components/Complete")
// );
// const ProductDetailScreen = lazy(() => import("../pages/product-detail"));
// const ContactScreen = lazy(() => import("../pages/contact"));
// const CreateContactScreen = lazy(() => import("../pages/contact"));
// const ReContactScreen = lazy(() => import("../pages/contact"));
// const EditContactScreen = lazy(() => import("../pages/contact"));
// const ProductConfirmScreen = lazy(() => import("../pages/product-confirm-buy"));
// const ResetPassword = lazy(() => import("../pages/reset-password"));
// const ChangePassword = lazy(() => import("../pages/change-password"));
// const ResetPasswordSuccessScreen = lazy(
//   () => import("../pages/reset-password/components/PasswordForm")
// );
// const EditProductScreen = lazy(() => import("../pages/edit-product-detail"));
// const SellingPage = lazy(() => import("../pages/selling"));
// const MyPageScreen = lazy(() => import("../pages/my-page"));
// const MyPageProfileScreen = lazy(
//   () => import("../pages/my-page/components/Profile")
// );
// const MyPageProfileEditScreen = lazy(
//   () => import("../pages/my-page/components/Profile/Edit")
// );
// const MyPageFavoriteScreen = lazy(
//   () => import("../pages/my-page/components/Favorite")
// );
// const MyPageSaleHistoryScreen = lazy(
//   () => import("../pages/my-page/components/SalesHistory")
// );
// const MyPagePurchaseHistoryScreen = lazy(
//   () => import("../pages/my-page/components/PurchaseHistory")
// );
// const MyPagePurchaseHistoryDetailScreen = lazy(
//   () =>
//     import("../pages/my-page/components/PurchaseHistory/PurchaseHistoryDetail")
// );
// const MyPageSaleHistoryDetailScreen = lazy(
//   () => import("../pages/my-page/components/SalesHistory/SaleHistoryDetail")
// );
// const ConfirmContactScreen = lazy(
//   () => import("../pages/confirm-contact/ConfirmContact")
// );

// const VisitOnlinePreparation = lazy(
//   () => import("../pages/visit-online/Preparation")
// );

// const NetworkError = lazy(() => import("../pages/network-error"));

const routesConfig: IRoutes[] = [
  {
    path: PATH_NAME.HOME,
    layout: DesktopLayout,
    routes: [
      {
        path: "/demo",
        component: Demo,
      },
      {
        path: "/network-error",
        component: NetworkError,
      },
      {
        path: "/",
        component: () => <Navigate to={PATH_NAME.DEFAULT} />,
      },
      {
        path: PATH_NAME.LOGIN,
        component: LoginScreen,
      },
      {
        path: PATH_NAME.SIGN_UP,
        component: SignUpScreen,
      },
      {
        path: PATH_NAME.RESET_PASSWORD,
        component: ResetPassword,
      },
      {
        path: PATH_NAME.RESET_PASSWORD_SUCCESS,
        component: PasswordForm,
      },

      {
        path: PATH_NAME.SIGN_UP_SUCCESS,
        component: Complete,
      },
      {
        path: PATH_NAME.PRODUCT_DETAIL,
        // guard: AuthGuard,
        component: ProductDetailScreen,
      },
      {
        path: PATH_NAME.CONTACT,
        guard: AuthGuard,
        component: Contact,
      },
      {
        path: PATH_NAME.CREATE_CONTACT,
        guard: AuthGuard,
        component: Contact,
      },
      {
        path: PATH_NAME.RE_CONTACT,
        guard: AuthGuard,
        component: Contact,
      },
      {
        path: PATH_NAME.EDIT_CONTACT,
        guard: AuthGuard,
        component: Contact,
      },
      {
        path: PATH_NAME.EDIT_PRODUCT,
        guard: AuthGuard,
        component: EditProductDetail,
      },
      {
        path: PATH_NAME.PRODUCT_CONFIRM,
        guard: AuthGuard,
        component: ProductConfirmScreen,
      },
      {
        path: PATH_NAME.CONFIRM_CONTACT,
        guard: AuthGuard,
        component: ConfirmContact,
      },
      {
        path: PATH_NAME.VISIT_ONLINE_PREPRATION,
        guard: AuthGuard,
        component: Preparation,
      },

      {
        path: PATH_NAME.HOME,
        layout: DashboardLayout,
        routes: [
          {
            path: PATH_NAME.DASHBOARD,
            component: HomeScreen,
          },
          {
            path: PATH_NAME.CATEGORY,
            component: HomeScreen,
          },
          {
            path: PATH_NAME.NOTIFICATION,
            guard: AuthGuard,
            component: NotificationScreen,
          },
          {
            path: PATH_NAME.NOTIFICATION_SYSTEM_DETAIL,
            guard: AuthGuard,
            component: NotificationDetail,
          },
          {
            path: PATH_NAME.SETTING,
            component: SettingScreen,
            guard: AuthGuard,
          },
          {
            path: PATH_NAME.SHOP,
            guard: AuthGuard,
            component: SellingPage,
          },
          {
            path: PATH_NAME.MY_PAGE,
            guard: AuthGuard,
            layout: MyPageLayout,
            routes: [
              {
                path: "",
                component: MyPage,
              },
              {
                path: PATH_NAME.MY_PAGE_FAVORITE,
                component: MyPageFavorite,
              },
              {
                path: PATH_NAME.MY_PAGE_PROFILE,
                component: MyPageProfile,
              },
              {
                path: PATH_NAME.MY_PAGE_PROFILE_EDIT,
                component: Edit,
              },
              {
                path: PATH_NAME.MY_PAGE_BANK_ACCOUNT,
                component: MyBankAccount,
              },
              {
                path: PATH_NAME.MY_PAGE_BANK_ACCOUNT_EDIT,
                component: MyBankAccountEdit,
              },
              {
                path: PATH_NAME.MY_PAGE_SALE_HISTORY,
                component: SalesHistory,
              },
              {
                path: PATH_NAME.MY_PAGE_PURCHASE_HISTORY,
                component: PurchaseHistory,
              },
              {
                path: PATH_NAME.MY_PAGE_PURCHASE_HISTORY_DETAIL,
                component: PurchaseHistoryDetail,
              },
              {
                path: PATH_NAME.MY_PAGE_SALE_HISTORY_DETAIL,
                component: SaleHistoryDetail,
              },
              {
                path: PATH_NAME.CHANGE_PASSWORD,
                component: ChangePassword,
              },
              {
                path: PATH_NAME.MY_PAGE_QUIT_MEMBERSHIP,
                component: QuitMembership,
              },
              {
                path: PATH_NAME.CONFIRM_CONTACT_LG,
                component: ConfirmContact,
              },
            ],
          },
          {
            path: "",
            component: () => <Navigate to={PATH_NAME.DEFAULT} />,
          },
        ],
      },
      {
        path: "*",
        component: () => <Navigate to={PATH_NAME.DEFAULT} />,
      },
    ],
  },
];

const renderChild = (routes: IRoutes[]) => (
  <>
    {routes.map((route: IRoutes, idx: number) => {
      const Guard = route.guard || Fragment;
      const Component = route.component;
      const Layout = route.layout || Fragment;
      return (
        <Route
          key={`routes-${route.path}-${idx}`}
          path={route.path || ""}
          element={<Guard>{route.routes ? <Layout /> : <Component />}</Guard>}
        >
          {route.routes ? renderChild(route.routes) : null}
        </Route>
      );
    })}
  </>
);

// eslint-disable-next-line arrow-body-style
const renderRoutes = (routes: IRoutes[]) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {routes ? (
        <Suspense fallback={<div />}>
          <Routes>
            {routes.map((route: IRoutes, idx: number) => {
              const Guard = route.guard || Fragment;
              const Component = route.component;
              const Layout = route.layout || Fragment;
              return (
                <Route
                  key={`routes-${route.path}-${idx}`}
                  path={route.path || ""}
                  element={
                    <Guard>{route.routes ? <Layout /> : <Component />}</Guard>
                  }
                >
                  {route.routes ? renderChild(route.routes) : null}
                </Route>
              );
            })}
          </Routes>
        </Suspense>
      ) : null}
    </>
  );
};

function AppRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  const isOffline = useSelector(
    (state: RootState) => state.AppStateReducer.isOffline
  );

  // const currentUrl = location.pathname + location.search;

  useEffect(() => {
    if (isOffline) {
      // navigate(`/network-error?returnUrl=${currentUrl}`);
      navigate(`/network-error`);
    }
  }, [isOffline, location.pathname]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPathname({ pathname: location.pathname + location.search
    }));
  }, [location]);

  return renderRoutes(routesConfig);
}

export default AppRoutes;
