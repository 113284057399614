import React, { FC, memo } from "react";
import { ReactComponent as Empty } from "@components/Icons/empty.svg";
import "./styles.scss";

interface Props {
  title?: string;
  route?: string;
  onClick?: () => void;
}

const AppEmptyList: FC<Props> = memo(({ ...props }) => (
  <div className="ui-empty">
    <Empty></Empty>
    <div className="bold text-body-3">該当する商品がありません。</div>
  </div>
));

export default AppEmptyList;

AppEmptyList.defaultProps = {};
