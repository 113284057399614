import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { BREAK_POINT } from '@models/common.model';

interface Props {
  children: any
}

const Desktop: FC<Props> = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: BREAK_POINT.lg });
  return isDesktop && children;
};

// const Tablet: FC<Props> = ({ children }) => {
//   const isTablet = useMediaQuery({ minWidth: BREAK_POINT.md, maxWidth: BREAK_POINT.lg - 1 });
//   return isTablet && children;
// };

const Mobile: FC<Props> = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: BREAK_POINT.lg - 1 });
  return isMobile && children;
};

export { Desktop, Mobile };