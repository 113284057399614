import React, { useEffect, useState } from 'react';
import './index.scss';
import { useSelector } from 'react-redux';
import { RootState } from '@store/configureStore';
import BoxChat from '@components/AppChat/components/BoxChat';
import ChatShower from '@components/AppChat/components/Shower';
import { MessageType } from '@models/common.model';

const AppChat = () => {
  const { roomIds } = useSelector((state: RootState) => state.ChatReducer);
  const [showerVisible, setShowerVisible] = useState<boolean>(false);
  const [initialSlide, setInitialSlide] = useState<number>(0);
  const [medias, setMedias] = useState<Array<{ id: string, url: string, type: string }>>([]);

  useEffect(() => {
    try {
      const message = {
        type: MessageType.ROOM_ID,
        data: roomIds[0] || ''
      };
      (window as any).ReactNativeWebView?.postMessage(JSON.stringify(message));
    } catch (e) {
      console.log(e);
    }
  }, [roomIds]);

  const handleClose = () => {
    setShowerVisible(false);
  };

  return (
    <div className="ui-chat">
      <ChatShower visible={showerVisible} medias={medias} onClose={handleClose} initialSlide={initialSlide} />
      {
        !!roomIds.length && roomIds.map((roomId: string, index: number) => (
          <BoxChat
            setVisibleShower={setShowerVisible}
            setMedias={setMedias}
            setInitialSlide={setInitialSlide}
            key={roomId}
            roomId={roomId}
            index={index}
          />
        ))
      }
    </div>
  );
};

export default AppChat;
