// eslint-disable-next-line react/no-unstable-nested-components

import { Col, Form, Input, Row } from "antd";
import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";
import AppButton from "@components/AppButton";
import { SearchOutlined } from "@ant-design/icons";
import AppCheckbox from "@components/Form/AppCheckbox";
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { Mobile } from "src/layouts/break-point";
import { useMediaQuery } from "react-responsive";

interface PrefectureProp {
  value: any[];
  list: any[];
  onSubmit: (listFilter: any[]) => void;
}

const PrefectureSelectForm: FC<PrefectureProp> = ({ ...props }) => {
  const { t: translate } = useTranslation();
  const [formPrefecture] = Form.useForm();
  const [formAll] = Form.useForm();
  const [options, setOptions] = useState(props.list);
  const optionsAll = [
    {
      label: translate("dashboard.filter.city.all"),
      value: true,
    },
  ];

  function clearAll() {
    // eslint-disable-next-line no-return-assign
    options.forEach((f) => (f.hidden = false));
    setOptions(cloneDeep(options));
  }

  function filter(value: string) {
    if (!value || value === "") {
      clearAll();
    }
    options.forEach((f) => {
      if ((f.label?.toString() || "").indexOf(value) === -1) {
        f.hidden = true;
      }
    });
    setOptions(cloneDeep(options));
  }

  useEffect(() => {

    if (props.value[0] === null) {
      formPrefecture.setFieldsValue({
        checkbox1: props.list.map(x => x.value),
      });
      formAll.setFieldsValue({ checkboxAll: [true] });
    } else {
      formPrefecture.setFieldsValue({
        checkbox1: props.value,
      });

      formAll.setFieldsValue(
        props.value.length === props.list.length
          ? { checkboxAll: [true] }
          : { checkboxAll: [] }
      );
    }

    setOptions(options);
    return () => {
      clearAll();
    };
  }, [props]);

  const onSubmit = () => {
    if (formAll.getFieldValue("checkboxAll").length) {
      props.onSubmit([{ label: "全国", value: null }]);
    } else {
      const listValue = formPrefecture.getFieldValue("checkbox1");
      const listFilter = props.list.filter(
        (f) => listValue.indexOf(f.value) > -1
      );
      props.onSubmit(listFilter);
    }
  };

  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <div className="prefecture-form">
      {/* <div className="input-city-container">
        <Input
          placeholder={translate("dashboard.filter.city")}
          suffix={<SearchOutlined />}
          onChange={(value) => {
            filter(value.target.value);
          }}
        />
      </div> */}

      <Form form={formAll} className="sort-all">
        <AppCheckbox
          options={optionsAll}
          type="reverse"
          name="checkboxAll"
          onChange={(value) => {
            formPrefecture.setFieldsValue(
              value.length
                ? { checkbox1: props.list.map((city) => city.value) }
                : { checkbox1: [] }
            );
            if (isDesktop) {
              onSubmit();
            }
          }}
          label=""
        />
      </Form>

      <Form form={formPrefecture} className="sort-body city-form">
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <AppCheckbox
              options={options}
              onChange={(value) => {
                formAll.setFieldsValue(
                  value.length === props.list.length
                    ? { checkboxAll: [true] }
                    : { checkboxAll: [] }
                );
                if (isDesktop) {
                  onSubmit();
                }
              }}
              type="reverse"
              name="checkbox1"
              label=""
            />
          </Col>
        </Row>
      </Form>
      <Mobile>
        <div className={classNames("sort-button-container")}>
          <AppButton buttontype="primary" onClick={() => onSubmit()}>
            {translate("public.product.step5")}
          </AppButton>
        </div>
      </Mobile>
    </div>
  );
};

export default PrefectureSelectForm;
