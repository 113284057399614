import React, { FC, useMemo, useState } from "react";
import { useNavigate, useLocation  } from "react-router-dom";
import "./index.scss";
import {
    ProductItem,
    ProductTransactionStatus,
  } from "src/models/product.model";
import TwitterItem from "./twitter";
import LineItem from "./line";
import InstagramItem from "./instagram";
import MailItem from "./mail";
import FacebookItem from "./facebook";


interface Props {
    product?: ProductItem;
    onClick?: () => void;
    onClickLink?: ()=> void;
    isSnsHidden?: boolean;
    isYoutubeHidden?: boolean;
    isMobile?:boolean;
}

const LinkIcon: FC<Props> = ({ ...props }) => {
    const location = useLocation();
    const snsIconView = useMemo(
        () => [
            {
             label: "twitter",
             value: "/assets/images/sns_twitter.png",
             tag: <TwitterItem
                text={props.product?.title}
                url={window.location.origin + location.pathname }
                hashtags={["マシプラ", `${props?.product?.maker}` ?? '' ]}
                ></TwitterItem>,
             isHidden: false },
            {
                label: "fasebook",
                value: "/assets/images/sns_fasebook.png",
                tag: <FacebookItem 
                    url={window.location.origin + location.pathname }
                    ></FacebookItem>,
                isHidden: false },
            {
                label: "line",
                value: "/assets/images/sns_line.png",
                tag: <LineItem 
                    url={window.location.origin + location.pathname }
                 ></LineItem>,
                isHidden: false },
        ], 
        []
    );
    
    const ImageBox = () => {
        const imgGroup = snsIconView.map(({ tag, isHidden }, index ) => 
            <React.Fragment key={index}>
                {!isHidden && (
                    <div>
                        {tag}
                    </div>
                )}
            </React.Fragment>
        );
        return <div className="ui-sns-icon-block">{imgGroup}</div>;  
    };

    const ImageLink = ({ item } : { item?: ProductItem }) => 
        <div className="ui-sns-link-click">
            <React.Fragment key={item?.id}>
            <div className="ui-img-block">
                <a 
                 rel="noopener noreferrer"
                 href="https://www.youtube.com/channel/UC6fHgvvW-XvxTZ9EyAFPAcA"
                 // href={item?.youtubeUrl}
                 target="_blank"
                >
                <img alt="" src="/assets/images/mashipla_youtube_banner.png" className="sns-img" />
                </a>
            </div>
            </React.Fragment>
        </div>;

    return (
        <div className="ui-sns-icon-containar">
            { !props.isSnsHidden && (
                <ImageBox />
            )}
            { !props.isYoutubeHidden && (
                <ImageLink item={props?.product} />
            )}
        </div>
    );
};

export default LinkIcon;