import React, { FC, memo, ReactNode } from "react";
import classnames from "classnames";
import { Modal, ModalProps, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { ReactComponent as Warning } from "../Icons/warning.svg";
import AppButton from "../AppButton";
import "./index.scss";

export interface AppModalProps extends ModalProps {
  icon?: ReactNode;
  title?: string | ReactNode;
  content?: ReactNode;
  okText?: string | ReactNode;
  cancelText?: string;
  reason?: React.ReactNode;
  form?: FormInstance;
  validateFields?: string[];
}

const AppModal: FC<AppModalProps> = memo(({ ...props }) => {
  const {
    visible,
    icon,
    title,
    content,
    okText,
    cancelText,
    onOk,
    onCancel,
    className,
    reason,
    form,
    validateFields,
  } = props;
  return (
    <Modal
      className={classnames("app-modal", className)}
      visible={visible}
      centered
      footer={null}
      closable={false}
      onOk={onOk}
      destroyOnClose
    >
      <div className="ui-modal ">
        <div className="ui-modal__icon">{icon}</div>
        <div className="ui-modal__title">{title}</div>
        {content && <div className="ui-modal__content">{content}</div>}
        <div className="ui-modal__action">
          {reason && <div>{reason}</div>}
          {okText && (
            <div className="ui-modal__ok-btn" onClick={onOk}>
              <AppButton
                buttontype="primary"
                onClick={() => form?.validateFields(validateFields)}
              >
                {okText}
              </AppButton>
            </div>
          )}
          {cancelText && (
            <div className="ui-modal__cancel-btn" onClick={onCancel}>
              <AppButton buttontype="secondary">{cancelText}</AppButton>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
});

export default AppModal;

AppModal.defaultProps = {
  icon: <Warning />,
  title: "This is title",
  content: undefined,
  okText: "Confirm",
  cancelText: undefined,
};
