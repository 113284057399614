import React, { FC, useEffect, useState } from "react";
import { ProductItem } from "src/models/product.model";
import "./style.scss";

interface Props {
    src?: string;
    src2?: string;
    onClick?: (item: ProductItem) => void;
}

const CommentIconItem: FC<Props> = ({ ...props }) => {
    function randomImage() {
        const images = ['/assets/images/type1.png', '/assets/images/type2.png'];
        const imageNo = Math.floor( Math.random() * images.length);
        return images[imageNo];
    }
    const src = randomImage() ?? '';
    const src2 = props.src2 ?? '/assets/images/type3.png';
    return (
        <div className="icon_type">
            <img className="left_typ" id="imageArea" alt="" src={src} />
            <img className="right_typ" alt="" src={src2} />
        </div>
    );
};

export default CommentIconItem;