import React from "react";
import { useMediaQuery } from "react-responsive";
import AppSettingMenu from "./components/AppSettingMenu";
import WebMobileSettingMenu from "./components/WebMobileSettingMenu";
import "./setting.scss";

export default function SettingScreen() {
  const isMobile = useMediaQuery({
    query: "(max-width: 991px)",
  });
  const isApp = (window as any).ReactNativeWebView;
  return (
    isMobile && (
      <div className="ui-setting">
        {!isApp && <WebMobileSettingMenu />}
        {isApp && <AppSettingMenu />}
      </div>
    )
  );
}
