// eslint-disable-next-line react/no-unstable-nested-components
import "./styles.scss";
import AppButton from "@components/AppButton";
import { Col, Form, Row } from "antd";
import { LabeledValue } from "antd/lib/select";
import classNames from "classnames";
import React, { FC, useEffect, useState, useRef, useMemo } from "react";
import AppRadio from "src/components/Form/AppRadio";
import { Category } from "@models/product.model";
import { RootState } from "src/store/configureStore";
import { useSelector } from "react-redux";
import ProductService from "src/services/API/Product";
import { cloneDeep, get } from "lodash";
import { useTranslation } from "react-i18next";
import { Mobile } from "src/layouts/break-point";
import { useMediaQuery } from "react-responsive";
import AppTreeRadio from "../../Form/AppTreeRadio/index";

interface CategoryProp {
  value: number[];
  list?: LabeledValue[];
  onSubmit: (listFilter: any[]) => void;
  type: "multi" | "normal";
}

const CategorySelectRadioForm: FC<CategoryProp> = ({ ...props }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });

  const [form] = Form.useForm();
  const { t: translate } = useTranslation();
  const options: LabeledValue[] = useMemo(
    () => [
      {
        label: "機械",
        value: 2,
      },
      {
        label: "工具",
        value: 4,
      },
    ],
    []
  );

  const [listChildCategory, setListChildCategory] = useState<Category[]>();
  const listMachine = useRef<Category>();
  const listTool = useRef<Category>();
  const listChecked = useRef<Category[]>([]);
  const listCategory = useSelector(
    (state: RootState) => state.ProductReducer.listCategory
  );

  useEffect(() => {
    if (listCategory.length) {
      const cloneListCategory = cloneDeep(listCategory);
      cloneListCategory.forEach((f) => {
        if (props.value && props.value.indexOf(f.id) > -1) {
          f.value = true;
        }
      });
      const listTree = ProductService.convertCategoryToTree(cloneListCategory);
      // eslint-disable-next-line prefer-destructuring
      listMachine.current = listTree.filter((f) => f.id === 2)[0];
      // eslint-disable-next-line prefer-destructuring
      listTool.current = listTree.filter((f) => f.id === 4)[0];

      const listToolId = ProductService.convertToListId(
        listTool.current.children || []
      );

      const listSelect =
        props.value &&
        props.value.length > 0 &&
        listToolId.includes(props.value[0])
          ? listTool.current.children
          : listMachine.current.children;

      const id =
        props.value &&
        props.value.length > 0 &&
        listToolId.includes(props.value[0])
          ? 4
          : 2;

      setListChildCategory(listSelect || []);
      form.setFieldsValue({
        primaryCategory: id,
      });
    }
  }, [listCategory, props.value]);

  return (
    <div className="prefecture-form category-form">
      <Form form={form} className="sort-body">
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <AppRadio
              name="primaryCategory"
              options={options}
              onChange={() => {
                if (form.getFieldValue("primaryCategory") === 4) {
                  setListChildCategory(listTool.current?.children || []);
                } else {
                  setListChildCategory(listMachine.current?.children || []);
                }
              }}
            ></AppRadio>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <AppTreeRadio
              value={props.value[0]}
              options={listChildCategory || []}
              onChange={(listSelect) => {
                listChecked.current = [listSelect.target.value];
                if (isDesktop) {
                  const listFilter = listChecked.current.map((x) => ({
                    label: x,
                    value: x,
                  }));
                  props.onSubmit(listFilter);
                }
              }}
            ></AppTreeRadio>
          </Col>
        </Row>
      </Form>
      <Mobile>
        <div className={classNames("sort-button-container")}>
          <AppButton
            buttontype="primary"
            onClick={() => {
              const listFilter = listChecked.current.map((x) => ({
                label: x,
                value: x,
              }));
              props.onSubmit(listFilter);
            }}
          >
            {translate("public.product.step5")}
          </AppButton>
        </div>
      </Mobile>
    </div>
  );
};

export default CategorySelectRadioForm;
