import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppNavigation from "@components/AppNavigation";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@store/configureStore";
import { ProductItem } from "src/models/product.model";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getProduct as getProductAction } from "src/store/Product";
import { setAppLoading } from "src/store/State";
import { ReactComponent as ArrowBack } from "@components/Icons/arrow-left-3.svg";
import DeliveryScreen from "./delivery";
import TermScreen from "./term";
import ConfirmScreen from "./confirm";

enum ConfirmState {
  // DELIVERY = 1,
  TERM = 1,
  CONFIRM = 2,
}
export default function ProductConfirmScreen() {
  const [searchParams] = useSearchParams();

  const transactionId = searchParams.get("id");
  const productId = searchParams.get("productId");

  const dispatch = useDispatch<AppDispatch>();

  const { t: translate } = useTranslation();

  const [screenState, setScreenState] = useState(ConfirmState.TERM);

  const navigate = useNavigate();

  const [isAccept, setAccept] = useState<boolean>(false);

  const [shippingInfo, setShippingInfo] = useState<{
    shippingMethod?: number;
    shippingNote?: string;
  }>({});

  const product: ProductItem = useSelector(
    (state: RootState) => state.ProductReducer.productDetail
  );

  function nextState(data?: any) {
    // if (data && screenState === ConfirmState.DELIVERY) {
    //   setShippingInfo({
    //     shippingMethod: data.delivery,
    //     shippingNote: data.deliveryNote,
    //   });
    // }
    if (screenState === ConfirmState.TERM) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getProduct();
    }
    setScreenState(screenState + 1);
  }

  const getProduct = async () => {
    if (productId) {
      dispatch(setAppLoading(true));
      try {
        await dispatch(getProductAction(productId));
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(setAppLoading(false));
      }
    }
  };
  useEffect(() => {
    getProduct();
  }, [productId]);

  function handleChangeState() {
    if (screenState === ConfirmState.TERM) {
      navigate(-1);
    }
    if (screenState <= 0) {
      return;
    }
    setScreenState(screenState - 1);
  }

  function getTitle() {
    switch (screenState) {
      // case ConfirmState.INFO:
      //   return translate("product.delivery.step1");

      // case ConfirmState.DELIVERY:
      //   return translate("product.delivery.step2");

      case ConfirmState.TERM:
        return translate("product.delivery.step3");

      case ConfirmState.CONFIRM:
        return translate("product.delivery.step4");

      default:
        return translate("product.delivery.step3");
    }
  }
  return (
    <div className="product-confirm-container">
      <AppNavigation title={getTitle()} onClick={handleChangeState} />

      {product && (
        <div className="content">
          <div className="desktop-title text-title-1 bold">
            <div className="icon-back">
              <ArrowBack onClick={handleChangeState} />
            </div>
            <span>{getTitle()}</span>
          </div>
          {/* <div */}
          {/*  className="wrap-step" */}
          {/*  style={{ */}
          {/*    display: screenState === ConfirmState.DELIVERY ? "flex" : "none", */}
          {/*  }} */}
          {/* > */}
          {/*  <DeliveryScreen onConfirm={nextState} /> */}
          {/* </div> */}

          <div
            className="wrap-step"
            style={{
              display: screenState === ConfirmState.TERM ? "flex" : "none",
            }}
          >
            <TermScreen
              onConfirm={nextState}
              isAccept={isAccept}
              setAccept={setAccept}
              // onCancel={() => {
              //   setScreenState(ConfirmState.DELIVERY);
              // }}
            />
          </div>

          <div
            className="wrap-step"
            style={{
              display: screenState === ConfirmState.CONFIRM ? "flex" : "none",
            }}
          >
            <ConfirmScreen
              transactionId={Number(transactionId)}
              product={product}
              shippingInfo={shippingInfo}
              // onConfirm={() => {
              //   navigate("/my-page/purchase-history?tab=0");
              // }}
              isAccept={isAccept}
            />
          </div>
        </div>
      )}
    </div>
  );
}
