import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { createBrowserHistory } from "history";
import StorageService from "@service/Storage";
import UserService from "src/services/API/User";
import { Store } from "@reduxjs/toolkit";
import { setError, setOffline } from "@store/State";
import { get } from "lodash";

const history = createBrowserHistory();

let store: Store;
let navigate: any;

const instance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = StorageService.getUserToken();
    config.headers = config.headers ? config.headers : {
      'Content-Type': 'application/json'
    };
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

function handleError(errMess: any) {
  // console.log(34, errMess);
  const currentPathnames = store.getState().GlobalReducer.currentPathname;
  // eslint-disable-next-line @typescript-eslint/no-use-before-define

  if (errMess === 'M0035' && currentPathnames[currentPathnames.length - 2] === '/my-page/favorite') {
    navigate("/my-page/favorite");
  } else if (errMess === 'M0035' && currentPathnames[currentPathnames.length - 2] !== '/my-page/favorite') {
    navigate("/dashboard");
  }
  if (errMess === 'M0121') {
    navigate(-1);
  }

  if (errMess === 'M0052' || errMess === 'M0083') {
    navigate("/dashboard");
  }
}

function checkIsSystemError(mess: string) {
  const regex = /^M\d\d\d\d/gm;
  return !regex.test(mess);
}

instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (!StorageService.getDevice()) {
      if (error.code === "ERR_NETWORK") {
        store.dispatch(setOffline(true));
      } else {
        store.dispatch(setOffline(false));
      }
    }

    if (error.response?.status === 401) {
      UserService.logout();
      navigate("/login");

    }

    const idError = get(error, 'response.data.error.validationErrors[0].message');

    if (error.response?.status === 400 && (idError === 'M0003' || idError === 'M0006' || idError === 'M0043')) {
      store.dispatch(setError({ message: idError, title: "title.modal.error.login" } || {}));
    } else if (error.response?.status === 400 && idError === 'M0102') {
      store.dispatch(setError({ message: idError, title: "選択されたファイルのタイプは現在サポートされていません。" } || {}));
    } else if (error.response?.status === 400 && idError === 'M0156') {
      store.dispatch(setError({ message: idError, title: "情報が変更されました。" } || {}));
    } else if (error.response?.status === 400 && idError === 'M0127') {
      store.dispatch(setError({ message: idError, title: "取引状況が変更されました。" } || {}));
    } else if (error.response?.status === 400 && idError === 'M0197') {
      store.dispatch(setError({ message: idError, title: "コンタクト日程が編集されました" } || {}));  
    } else if (error.response?.status === 400) {
      // @ts-ignore
      // if (checkIsSystemError(get(error, 'response.data.error.validationErrors[0].message')))
      const mess = get(error, 'response.data.error.validationErrors[0].message');
      // console.log(80, mess);
      // console.log(80, checkIsSystemError(mess));
      if (mess) {
        store.dispatch(setError({ message: checkIsSystemError(mess) ? 'C009' : mess, title: checkIsSystemError(mess) ? "ページが存在しません。" : "common.modal.userError", action: checkIsSystemError(mess) ? () => { navigate("/dashboard"); } : () => { } } || {}));
        handleError(mess);
      }

    } else if (error.response?.status === 500) {
      store.dispatch(setError({ message: "M0000", title: "common.modal.serverError" }));
    } else if (error.response?.status === 403) {
      const idError = (error.response.data as any)?.error?.message;
      if (idError === "M0052") {
        store.dispatch(setError({ message: "M0052", title: "common.modal.userError" }));
        handleError(idError);
      } else {
        store.dispatch(setError({ message: "M0000", title: "common.modal.userError" }));
      }
    }
    return Promise.reject(error);
  }
);




export const injectStore = (_store: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  store = _store;
};
export const injectNavigate = (_navigate: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  navigate = _navigate;
};

export default instance;
