import { Form, FormInstance, Input, InputProps } from "antd";
import React, { memo, FC, FocusEvent, useRef } from "react";
import classNames from "classnames";
import {
  ALLOW_ONLY_CHARACTER,
  ALLOW_ONLY_NUMBER,
  ALLOW_ONLY_SPECIAL_CHARACTER,
} from "src/configs";
import { get } from "lodash";

interface Props extends InputProps {
  formgroup?: FormInstance<Object>;
  label?: string;
  ispostingenddate?: string;
  extra?: React.ReactNode;
  rules?: object[];
  validatetrigger?: string[];
  allowonlyalphabet?: string | boolean;
  onlyInputNumber?: string | boolean;
}

const AppTextField: FC<Props> = memo(({ ...props }) => {
  const {
    formgroup,
    name,
    onBlur,
    label,
    rules,
    className,
    validatetrigger,
    extra,
    maxLength,
    allowonlyalphabet,
    pattern,
    onChange,
    ispostingenddate,
  } = props;

  function onBlurInput(e: FocusEvent<HTMLInputElement>) {
    formgroup?.setFields([
      {
        name: name || "",
        value: e.target.value.trim(),
      },
    ]);
    if (onBlur) {
      onBlur(e);
    }
  }

  const previousValue = useRef("");

  return (
    <Form.Item
      labelCol={{ span: 24 }}
      name={name}
      label={label}
      rules={rules}
      extra={extra}
      validateTrigger={validatetrigger}
      className={classNames(ispostingenddate && "ant-form-item-control-custom")}
    >
      <Input
        className={classNames("app-text-field", className)}
        onBlur={(e) => onBlurInput(e)}
        autoCapitalize="none"
        onKeyDown={(event) => {
          // if (
          //   props.onlyInputNumber &&
          //   event.keyCode === 229 &&
          //   (formgroup?.getFieldValue(`${name}`) === "" ||
          //   !formgroup?.getFieldValue(`${name}`))
          // ) {
          //   formgroup?.setFieldsValue({
          //     [name as string]: '1',
          //   });
          // }
          if (allowonlyalphabet) {
            if (
              !ALLOW_ONLY_CHARACTER.test(event.key) ||
              ALLOW_ONLY_SPECIAL_CHARACTER.test(event.key)
            ) {
              event.preventDefault();
            }
          }
        }}
        onPaste={(event) => {
          if (allowonlyalphabet) {
            const text = event.clipboardData.getData("text");
            if (
              ALLOW_ONLY_SPECIAL_CHARACTER.test(text) ||
              ALLOW_ONLY_NUMBER.test(text)
            ) {
              event.preventDefault();
            }
          }
        }}
        autoComplete="off"
        maxLength={maxLength}
        pattern={pattern}
        {...props}
        onChange={(e) => {
          if (props.onlyInputNumber) {
            if (
              get(e, "nativeEvent.data") &&
              !ALLOW_ONLY_NUMBER.test(get(e, "nativeEvent.data"))
            ) {
              // eslint-disable-next-line no-self-assign
              previousValue.current = previousValue.current;
              formgroup?.setFieldsValue({
                [name as string]: previousValue.current
                  ? previousValue.current
                  : `${previousValue.current} 1`,
              });
            } else {
              previousValue.current = e.target.value;
            }
          }

          if (allowonlyalphabet) {
            formgroup?.setFieldsValue({
              [name as string]: e.target.value.replace(
                /[0-9`~!@#$%^&*()_|+\-=?;:'",.;×÷€£¥₫<>{}[\]\\/]/gi,
                ""
              ),
            });
          }
          if (onChange) {
            onChange(e);
          }
        }}
      />
    </Form.Item>
  );
});

export default AppTextField;

AppTextField.defaultProps = {
  rules: [],
  validatetrigger: ["onBlur"],
  maxLength: 255,
};
