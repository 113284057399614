import { Skeleton } from "antd";
import "./styles.scss";
import React from "react";

const AppVSkeleton = () => (
  <div className="v-skeleton-container">
    <Skeleton.Avatar active shape="square" className="h-image-skeleton"
    />
    <Skeleton  active paragraph={{ rows: 3 }} />
  </div>
);

export default AppVSkeleton;
