// eslint-disable-next-line react/no-unstable-nested-components
import "./styles.scss";
import AppButton from "@components/AppButton";
import { Col, Form, Row } from "antd";
import { LabeledValue } from "antd/lib/select";
import classNames from "classnames";
import React, { FC, useEffect, useState, useRef, useMemo } from "react";
import AppRadio from "src/components/Form/AppRadio";
import { Category } from "@models/product.model";
import { RootState } from "src/store/configureStore";
import { useSelector } from "react-redux";
import ProductService from "src/services/API/Product";
import { cloneDeep, get } from "lodash";
import AppTreeCheckbox from "src/components/Form/AppTreeCheckbox";
import { useTranslation } from "react-i18next";
import { Mobile } from "src/layouts/break-point";
import { useMediaQuery } from "react-responsive";

interface CategoryProp {
  value: number[];
  list?: LabeledValue[];
  onSubmit: (listFilter: any[]) => void;
}

const CategorySelectForm: FC<CategoryProp> = ({ ...props }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });
  const { t: translate } = useTranslation();
  const [form] = Form.useForm();
  const options: LabeledValue[] = useMemo(() => (
    [
      { label: "すべて", value: 0 },
      { label: "機械", value: 2 },
      { label: "工具", value: 4 }
    ]
  ), []);

  const [listChildCategory, setListChildCategory] = useState<Category[]>();
  const listMachine = useRef<Category>();
  const listTool = useRef<Category>();
  const listChecked = useRef<Category[]>([]);
  const listCategory = useSelector(
    (state: RootState) => state.ProductReducer.listCategory
  );

  function getParentNode(listOption: Category[], parentId: number) {
    const parentNode: Category | undefined = listOption.filter(
      (f) => f.id === parentId
    )[0];
    if (parentNode) {
      return parentNode;
    }
    // eslint-disable-next-line consistent-return

    for (let index = 0; index < listOption.length; index++) {
      const category = listOption[index];
      if (get(category, "children.length")) {
        const node: any = getParentNode(category.children || [], parentId);
        if (node) {
          return node;
        }
      }
    }

    return undefined;
  }

  function checkIsChecked(listData: Category[], listResult: Category[]) {
    if (!listData) {
      return;
    }
    listData.forEach((f) => {
      if (f.value) {
        listResult.push(f);
      }
      checkIsChecked(f.children || [], listResult);
    });
  }

  function getListSelected(listOption: Category[]) {
    const listResult: Category[] = [];
    checkIsChecked(listOption, listResult);
    return listResult;
  }

  function checkParentChecked(parentNode: Category) {
    let isChecked = true;
    parentNode.children?.forEach((node) => {
      isChecked = isChecked && (node.value || false);
    });
    return isChecked;
  }

  function handelParentNode(listSelect: Category[], nodeId: number) {
    const node = listCategory.filter((category) => category.id === nodeId)[0];
    const parentNode = getParentNode(listSelect || [], node.parentId);
    if (!parentNode) {
      return;
    }
    const isParentChecked = checkParentChecked(parentNode);
    if (isParentChecked !== parentNode.value) {
      parentNode.value = isParentChecked;
      if (parentNode.value) {
        handelParentNode(listSelect || [], parentNode.id);
      }
    }
  }

  useEffect(() => {
    const ROOT_MACHINE_ID = 2;
    const ROOT_TOOL_ID = 4;
    if (listCategory.length) {
      console.log(listCategory);
      const cloneListCategory = [...listCategory];
      const list = cloneListCategory.map((f) => ({
        ...f,
        value: props?.value && props?.value.indexOf(f.id) > -1,
      }));
      const listTree = ProductService.convertCategoryToTree(list);
      // eslint-disable-next-line prefer-destructuring
      listMachine.current = listTree.filter((f) => f.id === 2)[0];
      // eslint-disable-next-line prefer-destructuring
      listTool.current = listTree.filter((f) => f.id === 4)[0];

      const listToolId = ProductService.convertToListId(
        listTool.current.children || []
      );
      const listMachineId = ProductService.convertToListId(
        listMachine.current.children || []
      );
      listToolId.push(ROOT_TOOL_ID);
      listMachineId.push(ROOT_MACHINE_ID);
      const id =
        props.value && props.value.length > 0 &&
        listToolId.includes(props.value[0])
          ? 4
          : props.value && props.value.length > 0
          && listMachineId.includes(props.value[0])
            ? 2
            : 0;

      form.setFieldsValue({
        primaryCategory: !((props.value || []).length) ? 0 : id,
      });

      const listSelect =
        (props.value &&
          props.value.length > 0 &&
          listToolId.includes(props.value[0])) ||
        form.getFieldValue("primaryCategory") === 4
          ? listTool.current.children
          : form.getFieldValue("primaryCategory") === 2
          ? listMachine.current.children
          : [];

      setListChildCategory([...(listSelect || [])]);

      listChecked.current = getListSelected(cloneDeep(listSelect || []));
    }
  }, [listCategory, props.value]);

  const onChangeData = (listSelect: any[]) => {
    listChecked.current = listSelect;
    if (isDesktop) {
      const primaryCategory = form.getFieldValue("primaryCategory");
      const selectedCategory = primaryCategory !== 0 ? options.filter(option => option.value === primaryCategory).map(item => ({ label: item.label, value: item.value })) : [];
      const listFilter = listChecked.current.map((x) => ({
        label: x.name,
        value: x.id,
      }));
      props.onSubmit(listFilter.length ? listFilter : selectedCategory);
    }
  };

  return (
    <div className="prefecture-form multi-select">
      <Form form={form} className="sort-body">
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <AppRadio
              name="primaryCategory"
              options={options}
              onChange={() => {
                const primaryCategory = form.getFieldValue("primaryCategory");
                if (primaryCategory === 4) {
                  setListChildCategory(listTool.current?.children || []);
                } else if (primaryCategory === 2) {
                  setListChildCategory(listMachine.current?.children || []);
                } else {
                  setListChildCategory([]);
                }
                if (isDesktop) {
                  const selectedCategory = primaryCategory !== 0 ? options.filter(option => option.value === primaryCategory).map(item => ({ label: item.label, value: item.value })) : [];
                  props.onSubmit(selectedCategory);
                }
              }}
              defaultValue={0}
              hasNotDefaultValue={true}
            ></AppRadio>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <AppTreeCheckbox
              options={listChildCategory || []}
              onChange={onChangeData}
            ></AppTreeCheckbox>
          </Col>
        </Row>
      </Form>
      <Mobile>
        <div className={classNames("sort-button-container")}>
          <AppButton
            buttontype="primary"
            onClick={() => {
              if (listChecked.current && listChecked.current.length) {
                const listFilter = listChecked.current.map((x) => ({
                  label: x.name,
                  value: x.id,
                }));
                props.onSubmit(listFilter);
              } else {
                const primaryCategory = form.getFieldValue("primaryCategory");
                const selectedCategory = primaryCategory !== 0 ? options.filter(option => option.value === primaryCategory).map(item => ({ label: item.label, value: item.value })) : [];
                props.onSubmit(selectedCategory);
              }
            }}
          >
            {translate("public.product.step5")}
          </AppButton>
        </div>
      </Mobile>
    </div>
  );
};

export default CategorySelectForm;
