import React, { FC, useEffect, useRef, useState } from "react";
import "./styles.scss";
import { ReactComponent as Sort } from "@components/Icons/sort.svg";
import { ProductItem } from "src/models/product.model";
import { cloneDeep } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { RootState } from "src/store/configureStore";
import ProductService from "@service/API/Product/index";
import AppVSkeleton from "src/components/skeleton/AppVSkeleton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Col, Pagination, Row } from "antd";
import { ReactComponent as ArrowDown } from "@components/Icons/arrow-down.svg";
import Select from "antd/es/select";
import { ProductSort, setPage, setSort } from "src/store/Product-Filter";
import AppEmptyList from "src/components/AppEmpty";
import { ItemDesktop } from "../item-desktop";

interface SearchProp {
  results: ProductItem[];
}
const SearchResultDashboard: FC<SearchProp> = ({ ...props }) => {
  const { Option } = Select;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const numberOfPage = 12;
  // const page = useRef(0);

  const [totalCount, setTotalCount] = useState(0);

  const [listProduct, setListProduct] = useState(props.results);

  const productFilter = useSelector(
    (state: RootState) => state.ProductFilterReducer.filter
  );

  const page = useSelector(
    (state: RootState) => state.ProductFilterReducer.page
  );

  const productSort = useSelector(
    (state: RootState) => state.ProductFilterReducer.sort
  );

  const keyword = useSelector(
    (state: RootState) => state.ProductFilterReducer.keyword
  );

  const [selectSort, setSelectSort] = useState<number | undefined>();

  const [loading, setLoading] = useState(false);
  const appScroll = useRef<HTMLElement | undefined>(undefined);
  
  function getListFilter() {
    appScroll.current?.scrollTo({ top: 0, behavior: "smooth" });
    const cloneFilter = cloneDeep(productFilter) || {};
    cloneFilter.categories = productFilter?.categories;
    setLoading(true);

    ProductService.getListProductByFilter(
      cloneFilter,
      productSort,
      ((page || 1) - 1) * numberOfPage,
      numberOfPage,
      keyword
    )
      .then((res) => {
        setListProduct(res.data.result.items);
        setLoading(false);
        setTotalCount(res.data.result.totalCount);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    setListProduct([]);
    if (!loading) {
      getListFilter();
    }
  }, [productFilter, productSort, keyword, page]);

  useEffect(() => {
    const formValue = productSort?.sortByPrice
      ? productSort.sortByPrice
      : productSort?.sortByRegisterDate
      ? productSort.sortByRegisterDate + 2
      : productSort?.sortByYearOfManufacture
      ? productSort.sortByYearOfManufacture + 4
      : undefined;
    setSelectSort(formValue);
  }, [productSort]);

  useEffect(() => {
    const scroll = document.getElementById("appLayout") || undefined;
    appScroll.current = scroll;
    appScroll.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleSortChange = (value: any) => {
    const sort = {} as ProductSort;
    const sortValue = value;
    switch (Number(sortValue)) {
      case 1:
      case 2:
        sort.sortByPrice = sortValue;
        break;

      case 3:
      case 4:
        sort.sortByRegisterDate = sortValue - 2;
        break;

      case 5:
      case 6:
        sort.sortByYearOfManufacture = sortValue - 4;
        break;

      default:
        break;
    }

    dispatch(setSort(sort));
  };
  function getTitle() {
    if (productFilter?.isFilterByType) {
      return productFilter.isPickup ? "ピックアップ商品" : "最近登録された商品";
    }

    return translate("home.dashboard.search.title");
  }

  return (
    <div className="search-result-container-lg" id="scrollableDiv">
      <div className="search-header">
        <div className="search-header__title">
          <div className="text-title-1 bold">{getTitle()}</div>
          <div className="text-body-2">{totalCount}項目</div>
        </div>

        <div className="search-header__icon">
          <div className="text-body-1 bold">並び替え</div>
          <div id="sorter">
            <Select
              getPopupContainer={() => document.getElementById("sorter") as HTMLElement}
              dropdownClassName="ant-select-custom-dropdown"
              value={selectSort}
              allowClear
              onChange={handleSortChange}
              disabled={loading || listProduct.length <= 0}
              suffixIcon={<ArrowDown/>}
            >
              <Option value={1}>価格の安い順</Option>
              <Option value={2}>価格の高い順</Option>
              <Option value={3}>登録日時の古い順</Option>
              <Option value={4}>登録日時の新しい順</Option>
              <Option value={5}>年式の古い順</Option>
              <Option value={6}>年式の新しい順</Option>
            </Select>
          </div>
        </div>
      </div>

      <div className="search-body">
        {loading ? (
          <Row className="result-list">
            {[...Array(10)].map((x, i) => (
              <Col span={12} key={i}>
                <AppVSkeleton />
              </Col>
            ))}
          </Row>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {listProduct.length > 0 ? (
              <Row className="result-list">
                {listProduct.map((value: ProductItem) => (
                  <Col span={8} key={value.id}>
                    <ItemDesktop product={value} type="SEARCH"></ItemDesktop>
                  </Col>
                ))}
              </Row>
            ) : (
              <AppEmptyList />
            )}
          </>
        )}
      </div>

      <div
        className={classNames("pagination-container", {
          hidden: loading || listProduct.length <= 0,
        })}
      >
        <Pagination
          pageSize={12}
          defaultCurrent={1}
          current={page}
          total={totalCount}
          onChange={(value) => {
            dispatch(setPage(value));
          }}
        />
      </div>
    </div>
  );
};

export default SearchResultDashboard;
