import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductTransactionStatus } from "src/models/product.model";

export enum ChatFile {
  DOCUMENT = "document",
  MEDIA = "media"
}

interface StatusRoom {
  roomId?: string;
  status?: ProductTransactionStatus;
}

interface ChatState {
  roomIds: Array<string>;
  currentStatusRooms?: StatusRoom[];
}

const initialState: ChatState = { roomIds: [], currentStatusRooms: [] };
const slice = createSlice({
  name: "Chat",
  initialState,
  reducers: {
    setRoomId(state, action: PayloadAction<string>) {
      if (!state.roomIds.some((roomId: string) => roomId === action.payload)) {
        state.roomIds = [action.payload, ...state.roomIds];
      }

      if (state.roomIds.length > 3) {
        state.roomIds = [...state.roomIds.slice(0, 3)];
      }
    },
    clearRoomId(state, action: PayloadAction<string>) {
      state.roomIds = state.roomIds.filter((roomId: string) => roomId !== action.payload);
      state.currentStatusRooms = state.currentStatusRooms?.filter((f: StatusRoom) => f.roomId !== action.payload);
    },
    clearRoomIds(state, action: PayloadAction<Array<any>>) {
      state.roomIds = action.payload;
      state.currentStatusRooms = [];
    },
    setCurrentStatusRooms(state, action: PayloadAction<StatusRoom>) {
      state.currentStatusRooms = [...(state?.currentStatusRooms?.filter((f: StatusRoom) => f.roomId !== action.payload.roomId) || []), action.payload];
    },
  }
});

export const { setRoomId, clearRoomId, clearRoomIds, setCurrentStatusRooms } = slice.actions;

export default slice.reducer;
