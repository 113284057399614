import React, { FC } from "react";

interface Props {
  active?: boolean;
}

const Translate: FC<Props> = ({ ...props }) => {
  const { active } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke={ active ? "#FF671E" : "#FCFCFC" } strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2 12H22" stroke={ active ? "#FF671E" : "#FCFCFC" } strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z" stroke={ active ? "#FF671E" : "#FCFCFC" } strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default Translate;
