/* eslint-disable eqeqeq */
import classNames from "classnames";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BottomBarItem,
  BottomBarKey,
  BottomBarType,
  MessageType,
} from "src/models/common.model";
import "./bottom-bar.scss";
import { useDispatch, useSelector } from "react-redux";
import { setStatusFilter } from "src/store/Product-Filter";
import { RootState } from "src/store/configureStore";
import StorageService from '@service/Storage';
import AppModal from '@components/AppModal';
import { ReactComponent as Error } from '@components/Icons/fail.svg';
import { clearError } from '@store/State';
import { PATH_NAME } from 'src/configs';

function BottomBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [confirmLogin, setConfirmLogin] = useState<boolean>(false);

  const totalUnReadNotice = useSelector(
    (state: RootState) => state.GlobalReducer.totalUnreadNotice
  );

  const totalUnreadMyNotice = useSelector(
    (state: RootState) => state.GlobalReducer.totalUnreadMyNotice
  );

  const appTotalUnreadNotice = useMemo(() => (totalUnReadNotice || 0) + (totalUnreadMyNotice || 0), [totalUnReadNotice, totalUnreadMyNotice]);

  const listItem: BottomBarItem[] = [
    {
      title: "ホーム",
      type: BottomBarType.NORMAL,
      routeName: "/dashboard",
      icon: "home",
      key: BottomBarKey.HOME,
      requireAuth: false,
    },
    {
      title: "お知らせ",
      type: BottomBarType.NOTIFICATION,
      routeName: "/notification",
      icon: "notification",
      key: BottomBarKey.NOTIFICATION,
      requireAuth: true,
    },
    {
      title: "商品登録",
      type: BottomBarType.NORMAL,
      routeName: "/shop",
      icon: "shop",
      key: BottomBarKey.SHOP,
      requireAuth: true,
    },
    {
      title: "マイページ",
      type: BottomBarType.NORMAL,
      routeName: "/my-page",
      icon: "user",
      key: BottomBarKey.MY_PAGE,
      requireAuth: true,
    },    
    {
      title: "設定",
      type: BottomBarType.NORMAL,
      routeName: "/setting",
      icon: "setting",
      key: BottomBarKey.SETTING,
      requireAuth: true,
    },
  ];

  const [selectedBar, setSelectedBar] = useState<BottomBarKey | string>(
    listItem[0].routeName
  );

  useEffect(() => {
    setSelectedBar(location.pathname);
  }, [location]);

   useEffect(() => {
    try {
      const message = {
        type: MessageType.BADGE,
        data: appTotalUnreadNotice || 0
      };
      (window as any).ReactNativeWebView?.postMessage(JSON.stringify(message));
    } catch (e) {
      console.log(e);
    }
  }, [appTotalUnreadNotice]);

  const isLogin = useSelector(
    (state: RootState) => state.UserReducer.isLogin
  );

  function renderListItem() {
    return listItem.map((item) => (
      <div
        key={item.title}
        className={classNames("item-bar", {
          active: selectedBar.includes(item.routeName),
        })}
        onClick={() => {
          const shouldShowPopup = item.requireAuth && !isLogin;
          setSelectedBar(item.routeName);
          if (item.key === BottomBarKey.HOME) {
            dispatch(setStatusFilter({ status: false }));
          }
          if (!shouldShowPopup) {
            return navigate(item.routeName);
          }
          setConfirmLogin(true);
        }}
      >
        <div
          className={classNames({
            relative:
              item.key === BottomBarKey.NOTIFICATION &&
              !!(appTotalUnreadNotice && appTotalUnreadNotice >= 1),
          })}
        >
          <img
            src={`/assets/icons/${item.icon}${
              selectedBar.includes(item.routeName) ? "-active" : ""
            }.svg`}
            alt=""
          />
          {item.key === BottomBarKey.NOTIFICATION &&
            !!(appTotalUnreadNotice && appTotalUnreadNotice > 0) && (
              <div className="item-bar__notice">
                <span
                  className={classNames(appTotalUnreadNotice > 99 && "font-9")}
                >
                  {appTotalUnreadNotice > 99 ? "99+" : appTotalUnreadNotice}
                </span>
              </div>
            )}
        </div>
        <div className="title text-caption-2">{(item.key === BottomBarKey.MY_PAGE && !isLogin) ? 'ログイン' : item.title }</div>
      </div>
    ));
  }
  return (
    <div className="bottom-bar-container">
      <AppModal
        visible={confirmLogin}
        title="この先の機能はログインが必要となります。"
        content=""
        okText="ログイン"
        cancelText="閉じる"
        onOk={() => navigate(`${ PATH_NAME.LOGIN }?returnUrl=${ selectedBar }`)}
        onCancel={() => {
          setConfirmLogin(false);
          setSelectedBar(listItem[0].routeName);
        }}
      />
      {renderListItem()}
    </div>
  );
}

export default memo(BottomBar);
