import React, { FC, memo, useEffect, useMemo } from "react";
import { Col, Row } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import AppNavigation from "@components/AppNavigation";
import AppFieldset from "@components/AppFieldset";
import AppRow from "@components/AppRow";
import AppButton from "@components/AppButton";

import { DATE_FORMAT } from "@configs/index";
import "./index.scss";
import { useDispatch } from "react-redux";
import { setTitle } from "src/store/State";
import { AppDispatch } from "src/store/configureStore";
import {
  SignUpFormLabel,
  SignUpFormValue,
  SignUpStatus,
  SignUpStatusName,
} from "../../model";

interface Props {
  value: SignUpFormValue;
  setStatus?: (status: SignUpStatus) => void;
  onSignUp?: () => void;
  loading?: boolean;
}

const Confirmation: FC<Props> = memo(({ ...props }) => {
  const { value, setStatus, onSignUp, loading } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const fieldLabelPairs = useMemo(
    () => [
      { field: "emailAddress", label: SignUpFormLabel.EMAIL, value: "" },
      { field: "fullName", label: SignUpFormLabel.USER_NAME, value: "" },
      { field: "dateOfBirth", label: SignUpFormLabel.DATE_OF_BIRTH, value: "" },
      { field: "phoneNumber", label: SignUpFormLabel.PHONE_NUMBER, value: "" },
      { field: "companyName", label: SignUpFormLabel.COMPANY_NAME, value: "" },
      { field: "department", label: SignUpFormLabel.DEPARTMENT, value: "" },
      { field: "position", label: SignUpFormLabel.POSITION, value: "" },
      { field: "zipCode", label: SignUpFormLabel.POSTAL_CODE, value: "" },
      { field: "city", label: SignUpFormLabel.MUNICIPALITIES, value: "" },
      { field: "district", label: SignUpFormLabel.PREFECTURES, value: "" },
      { field: "street", label: SignUpFormLabel.STREET, value: "" },
      { field: "buildingName", label: SignUpFormLabel.BUILD, value: "" },
    ],
    []
  );

  const transformDate = (date = "") => {
    const dateArray = date.split("/");
    dateArray[0] = `${dateArray[0]}年`;
    dateArray[1] = `${dateArray[1]}月`;
    dateArray[2] = `${dateArray[2]}日`;
    return dateArray.join("");
  };

  const transformedValue = useMemo(() => {
    const clonedValue = { ...value };
    if (clonedValue.dateOfBirth) {
      clonedValue.dateOfBirth = moment(clonedValue.dateOfBirth).format(
        DATE_FORMAT
      );
    }

    if (clonedValue.zipCode1 && clonedValue.zipCode2) {
      clonedValue.zipCode = `${clonedValue.zipCode1}-${clonedValue.zipCode2}`;
    }

    const fieldValuePairs = Object.entries(clonedValue).map(
      ([field, value]) => ({ field, value })
    );
    fieldLabelPairs.forEach((fieldLabelPair) => {
      fieldValuePairs.forEach((fieldValuePair) => {
        if (fieldLabelPair.field === fieldValuePair.field) {
          fieldLabelPair.value =
            fieldLabelPair.field === "dateOfBirth" && fieldValuePair.value
              ? transformDate(fieldValuePair.value)
              : fieldValuePair.value;
        }
      });
    });
    return fieldLabelPairs;
  }, [value]);
  const goBack = () => {
    setStatus?.(SignUpStatus.INPUT);
  };
  const nextStep = () => {
    onSignUp?.();
  };

  useEffect(() => {
    dispatch(setTitle(SignUpStatusName.SIGNUP_CONFIRM));
    const scroll = document.getElementById("appLayout") as HTMLDivElement;
    if (scroll) {
      scroll.scrollTo({ top: 0, behavior: "smooth" });
    }
    return () => {
      dispatch(setTitle(""));
    };
  }, []);

  return (
    <div className="ui-sign-up-confirmation">
      <div className="ui-sign-up-confirmation__pageTitle">
        <Row gutter={[32, 32]} justify="center">
          <Col xs={24}>
            <AppNavigation
              title={t("signup.confirm.pageTitle")}
              onClick={goBack}
            />
          </Col>
        </Row>
      </div>
      <div className="ui-sign-up-confirmation__fieldset">
        <Row gutter={[32, 32]} justify="center">
          <Col xs={24} md={12} xl={12}>
            <div className="ui-sign-up-confirmation__fieldset-form">
              <AppFieldset>
                {transformedValue.map(({ field, label, value }, index) => (
                  <AppRow
                    key={index}
                    label={t(label)}
                    value={value}
                    hasBorderBottom={index !== transformedValue.length - 1}
                    valueColor={index === 0 ? "#FF671E" : "#333333"}
                  />
                ))}
              </AppFieldset>
            </div>
          </Col>
        </Row>
      </div>
      <div className="ui-sign-up-confirmation__action">
        <Row gutter={[32, 32]} justify="center">
          <Col xs={12} md={6} xl={3}>
            <AppButton buttontype="secondary" onClick={goBack}>
              {t("signup.confirm.edit")}
            </AppButton>
          </Col>
          <Col xs={12} md={6} xl={3}>
            <AppButton
              buttontype="primary"
              onClick={nextStep}
              loading={loading}
            >
              {t("signup.confirm.next")}
            </AppButton>
          </Col>
        </Row>
      </div>
    </div>
  );
});

export default Confirmation;

Confirmation.defaultProps = {};
