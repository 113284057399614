import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, FormInstance, Modal, Row, Select, SelectProps } from 'antd';
import moment from 'moment';
import { ReactComponent as ArrowDown } from '@components/Icons/arrow-down.svg';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT, TIME_FORMAT } from "src/configs";
import { ReactComponent as Close } from '@components/Icons/close-1.svg';
import { useMediaQuery } from "react-responsive";
import classnames from "classnames";

interface Props extends SelectProps {
  name: any;
  rules?: object[];
  label?: string;
  form: FormInstance;
  values: moment.Moment;
  checkDuplicate?: () => void;
}

const DateTimePicker: FC<Props> = (({ ...props }) => {
  const { form, name, rules, label, values, checkDuplicate } = props;
  const { Option } = Select;
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<string>();
  const [selectedTime, setSelectedTime] = useState<string>();
  const [dateVisible, setDateVisible] = useState<boolean>(false);
  const [timeVisible, setTimeVisible] = useState<boolean>(false);
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  });

  const desiredDays = useMemo(() => {
    const generatedDates: Array<string> = [];
    const DAYS_IN_A_WEEK = 7;

    for (let i = 1; i < DAYS_IN_A_WEEK + 1; i++) {
      generatedDates.push(moment().add(i, 'days').format(DATE_FORMAT));
    }
    return generatedDates;
  }, []);

  const desiredTimes = useMemo(() => {
    const START_TIME = 8;
    const END_TIME = 20;
    const generatedTime: Array<string> = [];
    for (let i = START_TIME; i <= END_TIME; i += 1 / 6) {
      let time: string = '';
      let isNextHour: boolean = false;
      if (i % 1) {
        const minute = (Math.ceil((i % 1) * 60));
        const hour = Math.floor(i);
        isNextHour = minute === 60;
        time = `${isNextHour ? (`${hour + 1 < 10 ? '0' : ''}${hour + 1}`) : (`${hour < 10 ? '0' : ''}${hour}`)}:${isNextHour ? '00' : minute}`;
      } else {
        time = `${i < 10 ? '0' : ''}${i}:00`;
      }
      generatedTime.push(time);
    }
    return generatedTime;
  }, []);

  const isValidField = useMemo(() => !!(selectedTime && selectedDate), [selectedTime, selectedDate]);

  const handleChange = () => {
    form?.setFields([
      {
        name: ['candidateDates', name[0], name[1]],
        value: isValidField ? moment(`${selectedDate} ${selectedTime}`) : undefined,
      }
    ]);
    if (selectedDate && selectedTime) {
      form?.validateFields([['candidateDates', name[0], name[1]]]);
    }
    checkDuplicate?.();
  };

  const handleClear = () => {
    form?.setFields([
      {
        name: ['candidateDates', name[0], name[1]],
        value: undefined
      }
    ]);
    form?.validateFields([['candidateDates', name[0], name[1]]]);
    checkDuplicate?.();
  };

  useEffect(() => {
    handleChange();
    if (!selectedDate && !selectedTime) {
      form?.setFields([
        {
          name: ['candidateDates', name[0], name[1]],
          value: ''
        }
      ]);
    }
  }, [isValidField, selectedDate, selectedTime]);

  useEffect(() => {
    if (values) {
      setSelectedDate(values.format(DATE_FORMAT));
      setSelectedTime(values.format('HH:mm'));
    }
  }, [values]);

  const disabledTime = useCallback((time: string) => {
    if (selectedDate === moment().format(DATE_FORMAT)) {
      const currentTime = moment().format(TIME_FORMAT);
      return moment(time, TIME_FORMAT).isBefore(moment(currentTime, TIME_FORMAT).add(10, "minute"));
    }
    return false;
  }, [selectedDate]);

  return (
    <Form.Item name={name} rules={rules} label={label}>
      <Row>
        <Col xs={11} id="contact-date-picker">
          <Select
            {...props}
            onChange={(date) => {
              setSelectedDate(date);
              setDateVisible(false);
            }}
            value={selectedDate}
            className="app-select contact-select"
            // dropdownClassName="ant-select-custom-dropdown"
            dropdownClassName={classnames("p-0 br-8")}
            suffixIcon={<ArrowDown />}
            placeholder=""
            allowClear
            onClear={() => handleClear()}
            clearIcon={<Close />}
            getPopupContainer={() =>
              document.getElementById("contact-date-picker") as HTMLElement
            }
            listItemHeight={isMobile ? 48 : 32}
            listHeight={isMobile ? 480 : 256}
            dropdownRender={menu => (
              isMobile ? (
                <Modal visible={dateVisible} className="datepicker-dropdown-modal" closable={false} centered destroyOnClose>
                  <div className={ classnames("ant-select-custom-dropdown") }>
                    {menu}
                  </div>
                </Modal>
              ) : (
                <div className="ant-select-custom-dropdown">{menu}</div>
              )
            )}
            onDropdownVisibleChange={(visible) => {
              setDateVisible(visible);
            }}
          >
            {desiredDays &&
              desiredDays.length &&
              desiredDays.map((desiredDay, index) => (
                <Option key={index} value={desiredDay}>
                  {desiredDay}
                </Option>
              ))}
          </Select>
        </Col>
        <Col xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>ー</Col>
        <Col xs={11} id="contact-time-picker">
          <Select
            {...props}
            onChange={(time) => {
              setSelectedTime(time);
              setTimeVisible(false);
            }}
            value={selectedTime}
            className="app-select contact-select"
            // dropdownClassName="ant-select-custom-dropdown"
            dropdownClassName={classnames("p-0 br-8")}
            suffixIcon={<ArrowDown />}
            placeholder=""
            allowClear
            onClear={() => handleClear()}
            clearIcon={<Close />}
            getPopupContainer={() =>
              document.getElementById("contact-time-picker") as HTMLElement
            }
            listItemHeight={isMobile ? 48 : 32}
            listHeight={isMobile ? 480 : 256}
            dropdownRender={menu => (
              isMobile ? (
                <Modal visible={timeVisible} className="datepicker-dropdown-modal" closable={false} centered destroyOnClose>
                  <div className={ classnames("ant-select-custom-dropdown") }>
                    {menu}
                  </div>
                </Modal>
              ) : (
                <div className="ant-select-custom-dropdown">{menu}</div>
              )
            )}
            onDropdownVisibleChange={(visible) => {
              setTimeVisible(visible);
            }}
          >
            {desiredTimes &&
              desiredTimes.length &&
              desiredTimes.map((desiredTime, index) => (
                <Option disabled={disabledTime(desiredTime)} key={index} value={desiredTime}>
                  {desiredTime}
                </Option>
              ))}
          </Select>
        </Col>
      </Row>
    </Form.Item>
  );
});

export default DateTimePicker;
